import { bindable } from "aurelia";

import { ILinkService, LinkService } from "../../../../common/linkService";
import { Link } from "../../../../common/interfaces/ILink";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";

export class EditLink {
  @bindable display: boolean;
  @bindable link: Link;

  public dialog: SlDialog;
  public form: HTMLFormElement
  public titlePlaceholder: string;
  public loading: boolean;

  constructor(@ILinkService private readonly linkService: LinkService) { }

  public displayChanged(): void {
    this.dialog.show();
  }

  public save(): void {
    if (this.form.reportValidity()) {
      this.loading = true;

      // Serialize the form data and extract the URL.
      const formData = serialize(this.form);
      const title = formData["title"] as string;
      this.link.givenTitle = title;

      this.linkService.update(this.link);
      this.loading = false;
      this.dialog.hide();
    }
  }

}
