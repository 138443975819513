<sl-icon-button name="link" label="Add Link" title="Add Link" click.trigger="open()"></sl-icon-button>

<sl-dialog label="Add Link" ref="dialog">
  <form ref="form" submit.trigger="submit()">
    <sl-input ref="urlinput" name="url" label="Link Url" autocomplete="off" placeholder="A Link to a Gallery" type="url" required></sl-input>

    <br />

    <sl-button type="submit" class="pull-right" loading.bind="loading">Submit</sl-button>
  </form>
</sl-dialog>
