import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";
import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';

import { IRouter, Router } from "@aurelia/router";

import { IGalleryService, GalleryService } from "../../../../common/galleryService";
import { ISubscriptionService, SubscriptionService } from "../../../../common/subscriptionService";


export class CreateGallery {
  public galleryName = "";
  public folderId = "";

  public uploadgallery: HTMLElement;
  public uploadform: HTMLFormElement;
  public nameinput: HTMLInputElement;
  public selectelement: HTMLSelectElement;
  public formValid = false;
  private clearing = false;

  public creategallery: HTMLElement;
  public createform: HTMLFormElement;
  public urlinput: HTMLInputElement;

  public noticesubscription: HTMLElement;

  constructor(
    @IGalleryService public readonly galleryService: GalleryService,
    @IRouter private readonly router: Router,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService,
  ) { }

  public bound() {
    this.nameinput.addEventListener("blur", this.handleEvent);
    this.selectelement.addEventListener("sl-change", this.handleEvent);
    this.uploadform.addEventListener("submit", this.handleEvent);
    this.createform.addEventListener("submit", (event) => event.preventDefault());
  }

  public unbinding() {
    this.nameinput.removeEventListener("blur", this.handleEvent);
    this.selectelement.removeEventListener("sl-change", this.handleEvent);
    this.uploadform.removeEventListener("submit", this.handleEvent);
    this.createform.removeEventListener("submit", (event) => event.preventDefault());
  }

  private handleEvent = (event: any) => {
    event.preventDefault();
    if (this.clearing) return;
    const formData = serialize(this.uploadform);
    this.galleryName = formData["galleryName"] as string;
    this.folderId = formData["folderId"] as string;
    this.formValid = this.uploadform.reportValidity();
  }

  public close(): void {
    (this.uploadgallery as SlDialog).hide();
  }

  public uploadGallery() {
    this.clearing = true;
    this.nameinput.setAttribute("value", "");
    this.selectelement.setAttribute("value", "");
    this.formValid = false;
    this.galleryName = "";
    this.folderId = "";
    this.clearing = false;

    (this.uploadgallery as SlDialog).show();
  }

  public createGallery() {
    this.urlinput.setAttribute("value", "");
    (this.creategallery as SlDialog).show();
  }

  public submitGalleryUrl() {
    // If the user hasn't subscribed, show a dialog and quit.
    if (!this.subscriptionService.canCreateGalleries) {
      (this.noticesubscription as SlDialog).show();
      return;
    }

    // If the form is invalid, quit.
    const formIsValid = this.createform.reportValidity();
    if (!formIsValid) return;

    // Serialize the form data and extract the URL.
    const formData = serialize(this.createform);
    const url = formData["url"] as string;

    // Create the gallery.
    this.galleryService.createGalleryFromURL(url);

    // Hide the dialog.
    (this.creategallery as SlDialog).hide();
  }

  public async loadPricePage() {
    await this.router.load("/price-page");
  }

}
