<h1>admin users</h1>

<table>
  <thead>
    <tr>
      <th>Email</th>
      <th>User Name</th>
      <th>Galleries</th>
      <th>Storage use</th>
      <th>Subscription</th>
      <th>Last Login</th>
      <th>Created</th>
      <th>User Id</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr repeat.for="user of users">
      <td click.trigger="viewUser($index)" class="elipsis clickable">${user.email}</td>
      <td>${user.name}</td>
      <td>${user.galleryCount}</td>
      <td class="no-wrap">${user.usedKB | memoryUnit}</td>
      <td>${user.stripeSubscriptionStatus}</td>
      <td class="no-wrap">${user.lastLoggedIn | displayDate}</td>
      <td class="no-wrap">${user.createDate | displayDate}</td>
      <td title.bind="user.id" class="no-wrap short">${user.id}</td>
      <td click.trigger="() => recalculateSystemUsage($index)"><sl-icon-button name="calculator"></sl-icon-butt></td>
    </tr>
  </tbody>
</table>