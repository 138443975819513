<!-- Upload Part -->
<input ref="fileInput" type="file" name="fileinput" multiple accept="image/*,video/*,audio/*" style="display: none" />

<template if.bind="selectedFiles.length>0">
  <h3>Selected Files</h3>

  <div class="thumbnail-grid">
    <div class="img-container" repeat.for="file of selectedFiles">
      <img src.bind="file.url" title.bind="file.size" />
      <div class="label">
        <span class="file-name">${file.name}</span>
      </div>
    </div>
  </div>

  <div class="info">Selected Files: <span>${fileCount}</span> Total Size: <span>${returnFileSize(totalSize)}</span></div>
</template>

<div class="button-row">
  <sl-button click.trigger="openFilePicker()">Select Files</sl-button>
  <sl-button click.trigger="upload()" disabled.bind="!submitEnabled">Ok</sl-button>
</div>
