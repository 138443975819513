<sl-dialog ref="dialog" label="Delete Images">
  <form ref="confirmForm">
    <h2>Really sure?</h2>
    <br />

    <p>Are you sure you want to delete the image<span if.bind="images.length>1">s</span> <strong>${images.join(',
        ')}</strong>?
    </p>
    <br />

    <sl-button class="pull-left" variant="primary" click.trigger="cancel()">Cancel</sl-button>
    <sl-button class="pull-right" variant="secondary" click.trigger="deleteConfirmed()">Delete</sl-button>
</sl-dialog>