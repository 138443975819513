<sl-dropdown ref="dropDown" sl-hide.trigger="clearUnread">
  <div title="Notifications" slot="trigger">
    <sl-badge variant="primary" placement="bottom-end" pill if.bind="unreadCount > 0">${unreadCount}</sl-badge>
    <sl-icon-button name="bell"></sl-icon-button>
  </div>

  <div class="fold-out" tabindex="0">
    <div class="notification-item" if.bind="notifications.length===0">
      <div class="notification-item-body">No notifications, yet 😀</div>
    </div>

    <div class="notification-item" repeat.for="notification of notifications" tabindex="0">
      <div class="notification-item-header">
        <div class="notification-item-title">
          <span if.bind="notification.type==='warning'">⚠️</span>
          ${notification.title}
        </div>
        <div class="notification-item-date">${notification.date | displayDate}</div>
      </div>
      <div class="notification-item-body" innerhtml.bind="notification.message"></div>
      <div class="notification-item-action" if.bind="notification.url!==''">
        <sl-button click.trigger="buttonClick(notification)">Go ➡️</sl-button>
      </div>
    </div>
  </div>
</sl-dropdown>
