import { valueConverter } from "aurelia";

@valueConverter("linkStatus")
export class LinkStatusValueConverter {
  public toView(status: number | string): string {
    if (typeof status !== "number") {
      return;
    }
    switch (status) {
      case 0:
        return "Saved";
      case 2:
        return "Processing";
      case 3:
        return "Downloading";
      case 6:
        return "No Media Found";
      case 7:
        // return "Finished Processing";
        return '<span class="signal-ok"><i class="far fa-images"></i></span>';
      default:
        break;
    }
  }
}
