import { EventAggregator, IEventAggregator, bindable } from "aurelia";

import { SlDialog } from "@shoelace-style/shoelace";

import { EventType } from "../../../../common/eventType";

export class DeleteImages {
  @bindable display: boolean;
  @bindable images: string[];

  public dialog: SlDialog;

  constructor(@IEventAggregator private readonly eventAggregator: EventAggregator) { }

  public displayChanged(): void {
    console.log("deleteImagesShowModal", this.images);
    this.dialog.show();
  }

  public async deleteConfirmed(): Promise<void> {
    this.eventAggregator.publish("deleteImagesConfirm" as EventType, this.images);
    this.dialog.hide();
  }

  public cancel() {
    this.dialog.hide();
  }
}