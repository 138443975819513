import { IGallery } from "./IGallery";
import { Link } from "./ILink";

export class Folder {
  id: string;
  createDate: Date;
  editDate: Date;
  name: string;
  links?: Array<Link>;
  galleries?: Array<IGallery>;
}
