import { AuthService, IAuthService } from "../../common/authService";

export class AdminPage {

  constructor(@IAuthService private readonly authService: AuthService) { }

  async canLoad() {
    if (!this.authService.isLoggedIn) {
      return "";
    }

    return true;
  }
}