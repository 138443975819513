import { IRouter } from "@aurelia/router";
import { IEventAggregator } from "aurelia";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";

import { UINotification } from "../../../../common/uiNotification";

export class UiNotifications {
  public dropDown: HTMLElement;
  public notifications: UINotification[] = [];
  public unreadCount = 0;

  constructor(
    @IEventAggregator private readonly eventAggregator: IEventAggregator,
    @IRouter private readonly Router: IRouter
  ) {
    this.eventAggregator.subscribe("uiNotificationError", (notification: UINotification) => {
      this.notifications.unshift(notification);
      this.unreadCount++;
    });

    this.eventAggregator.subscribe("uiNotification", (notification: UINotification) => {
      this.notifications.unshift(notification);
      this.unreadCount++;
    });
  }

  public clearUnread() {
    this.unreadCount = 0;
  }

  public buttonClick(notification: UINotification) {
    (this.dropDown as SlDialog).hide();
    if (notification.isExternalURL) {
      window.open(notification.url, "_blank").focus();
    }
    else {
      this.Router.load(notification.url);
    }
  }
}
