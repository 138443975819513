import { IRouteableComponent, IRouter, Navigation, Parameters, Router, RoutingInstruction } from '@aurelia/router';

import { AuthService, IAuthService } from "../../../../common/authService";
import { DataService, IDataService } from "../../../../common/network/dataService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";
import { UINotification } from "../../../../common/uiNotification";

export class TOUPage {
  private previousRoute = '';
  public modal: HTMLDialogElement;

  constructor(
    @IAuthService private readonly authService: AuthService,
    @IDataService private readonly dataService: DataService,
    @INotificationService private readonly notificationService: NotificationService,
    @IRouter private readonly router: Router
  ) { }

  loading(parameters: Parameters, instruction: RoutingInstruction): void | Promise<void> {
    this.previousRoute = instruction.endpoint.instance.getContent().instruction.component.name;
  }

  attached() {
    this.modal.showModal();
  }

  public async acceptClick() {
    const result = await this.dataService.acceptTOU();
    if (result.ok) {
      if (this.previousRoute && this.previousRoute !== '') {
        await this.router.load(this.previousRoute);
      }
      else {
        await this.router.load('home');
      }
    }
  }

  public async denyClick() {
    const result = await this.dataService.rejectTOU();
    if (result.ok) {
      const notification: UINotification = {
        title: 'TOU rejected',
        message: 'TOU rejected, you will be logged out. Please log back in and accept the TOU if you would like to use this application.',
        type: 'warning',
        date: new Date(),
        buttonPositiveCallback: () => {
          this.authService.logOut();
        },
        buttonPositiveText: 'OK',
        url: ''
      };
      this.notificationService.modal(notification);
    }
  }
}