import { DI } from "aurelia";
import { IAuthService, AuthService } from "./authService";
import { ConfigService, IConfigService } from "./configService";
import { DataService, IDataService } from "./network/dataService";

export class SubscriptionService {
  //  TODO: This link needs to change when GO LIVE!
  public get stripeSubscriptionManagementLink() {
    return `${this.configService.getProperty('stripe.managementLink')}?prefilled_email=${encodeURIComponent(this.authService.user.email)}`;
  }

  public get hasValidSubscription() {
    return this.authService.user.hasValidSubscription;
  }

  public get subscriptionLevel() {
    return this.authService.user.subscriptionName;
  }

  public get hasValidSubscriptionForDownloadAsArchive() {
    return this.authService.user.canDownloadArchives;
  }

  public get canCreateGalleries() {
    // user has an empty subscriptionstatus and has 10 or less galleries or user has a valid subscription
    return this.authService.user.canCreateGallery;
  }

  public get canCreateLinks() {
    // user has an empty subscriptionstatus and has 100 or less links or user has a valid subscription
    return this.authService.user.canCreateLink;
  }

  public async canViewGalleries() {
    return await this.dataService.hasValidSubscription();
  }

  constructor(@IAuthService private readonly authService: AuthService,
    @IConfigService private readonly configService: ConfigService,
    @IDataService private readonly dataService: DataService) { }
}

export const ISubscriptionService = DI.createInterface<SubscriptionService>("ISubscriptionService", (x) => x.singleton(SubscriptionService));