import { valueConverter } from "aurelia";

@valueConverter("urlToUI")
export class UrlToUIValueConverter {
  public toView(longURL: string): string {
    let url = longURL.replace("https://", "");
    url = url.replace("http://", "");
    url = url.replace("www.", "");
    url = url.lastIndexOf("/") === url.length - 1 ? url.substring(0, url.length - 1) : url;
    return url;
  }
}
