<dialog ref="modal">
  <section id="#tou">
    <h1>Terms of Use</h1>

    <p><strong>Last updated:</strong> February 25, 2024</p>

    <p><strong>AGREEMENT TO OUR LEGAL TERMS</strong></p>
    <p>We are PixHeap ("<strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>,"
      "<strong>our</strong>").
    </p>

    <p>We operate the website pixheap.com (the "<strong>Site</strong>"), as well as any other related products and
      services that refer or link to these legal terms (the "<strong>Legal Terms</strong>") (collectively, the
      "<strong>Services</strong>").</p>

    <p>You can contact us via the <a href="/#/feedback-page">Feedback page</a>.</p>

    <p>These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an
      entity ("<strong>you</strong>"), and PixHeap, concerning your access to and use of the Services. You agree
      that by accessing the Services, you have read, understood, and agreed to be bound by these Legal Terms. IF YOU DO
      NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
      DISCONTINUE USE IMMEDIATELY.</p>

    <p>User will be notified when logging in to the service. The Services are intended for users who are at least 18
      years old. Persons under 18 are not permitted to use or register for the Services.</p>

    <p>We recommend printing a copy of these Legal Terms for your records.</p>
  </section>

  <section id="toc">
    <strong>TABLE OF CONTENTS</strong>
    <br>
    <a href="#services">1. OUR SERVICES</a>
    <br>
    <a href="#ip">2. INTELLECTUAL PROPERTY RIGHTS</a>
    <br>
    <a href="#urep">3. USER REPRESENTATIONS</a>
    <br>
    <a href="#ureg">4. USER REGISTRATION</a>
    <br>
    <a href="#purchase">5. PURCHASES AND PAYMENT</a>
    <br>
    <a href="#subscriptions">6. SUBSCRIPTIONS</a>
    <br>
    <a href="#prohibited">7. PROHIBITED ACTIVITIES</a>
    <br>
    <a href="#ugc">8. USER GENERATED CONTRIBUTIONS</a>
    <br>
    <a href="#license">9. CONTRIBUTION LICENSE</a>
    <br>
    <a href="#thirdparty">10. THIRD-PARTY WEBSITES AND CONTENT</a>
    <br>
    <a href="#sitemanage">11. SERVICES MANAGEMENT</a>
    <br>
    <a href="#ppyes">12. PRIVACY POLICY</a>
    <br>
    <a href="#copyrightyes">13. COPYRIGHT INFRINGEMENTS</a>
    <br>
    <a href="#terms">14. TERM AND TERMINATION</a>
    <br>
    <a href="#modifications">15. MODIFICATIONS AND INTERRUPTIONS</a>
    <br>
    <a href="#law">16. GOVERNING LAW</a>
    <br>
    <a href="#disputes">17. DISPUTE RESOLUTION</a>
    <br>
    <a href="#corrections">18. CORRECTIONS</a>
    <br>
    <a href="#disclaimer">19. DISCLAIMER</a>
    <br>
    <a href="#liability">20. LIMITATIONS OF LIABILITY</a>
    <br>
    <a href="#indemnification">21. INDEMNIFICATION</a>
    <br>
    <a href="#userdata">22. USER DATA</a>
    <br>
    <a href="#electronic">23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</a>
    <br>
    <a href="#misc">24. MISCELLANEOUS</a>
    <br>
    <a href="#contact">25. CONTACT US</a>
  </section>

  <section id="services">
    <h2>1. OUR SERVICES</h2>

    <p>The information provided when using the Services is not intended for distribution to or use by any person or
      entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
      which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
      persons who choose to access the Services from other locations do so on their own initiative and are solely
      responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

    <p>The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
      Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions
      would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would
      violate the Gramm-Leach-Bliley Act (GLBA).</p>

    <p>Our Services allow users to upload and share content. By uploading content, you represent and warrant that you
      have the legal right to do so and that such content complies with all applicable laws and regulations. We reserve
      the right, at any time and without prior notice, to remove or disable access to any content that we, for any
      reason or no reason, consider to be objectionable, in violation of these terms or otherwise harmful to the
      Services.</p>

  </section>

  <section id="ip">
    <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>

    <p><strong>Our intellectual property</strong></p>

    <p>We are the owner or the licensee of all intellectual property rights in our Services, including all source code,
      databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
      (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the
      "Marks").
    </p>

    <p>Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property
      rights
      and unfair competition laws) and treaties in the United States and around the world.</p>

    <p>The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use only.
    </p>

    <p><strong>Your use of our Services</strong></p>

    <p>Subject to your compliance with these Legal Terms, including the "<a href="#prohibited">PROHIBITED
        ACTIVITIES</a>"
      section below, we grant you a non-exclusive, non-transferable, revocable license to:</p>
    <ul>
      <li>access the Services; and</li>
      <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
    </ul>
    <p>Solely for your personal, non-commercial use.</p>

    <p>Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or
      Marks
      may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
      transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
      our
      express prior written permission.</p>

    <p>If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere
      in our Legal Terms, please address your request to the <a href="https://pixheap.com/#/feedback-page">Feedback
        page</a>. If we ever grant you the permission to
      post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or
      licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is
      visible on posting, reproducing, or displaying our Content.</p>

    <p>We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</p>

    <p>Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your
      right to use our Services will terminate immediately.</p>

    <p><strong>Your submissions and contributions</strong></p>

    <p>Please review this section and the "<a href="#prohibited">PROHIBITED ACTIVITIES</a>"
      section
      carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when
      you
      post or upload any content through the Services.</p>

    <p><strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other
      information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such
      Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination
      for
      any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>

    <p><strong>Contributions:</strong> The Services may invite you to chat, contribute to, or participate in blogs,
      message
      boards, online forums, and other functionality during which you may create, submit, post, display, transmit,
      publish,
      distribute, or broadcast content and materials to us or through the Services, including but not limited to text,
      writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information,
      or
      other material ("Contributions"). Any Submission that is publicly posted shall also be treated as a Contribution.
    </p>

    <p>You understand that Contributions may be viewable by other users of the Services and possibly through third-party
      websites.</p>

    <p><strong>When you post Contributions, you grant us a license (including use of your name, trademarks, and
        logos):</strong> By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual,
      non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce,
      distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat,
      translate,
      excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name,
      and
      voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into
      other works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution
      may
      occur in any media formats and through any media channels.</p>

    <p>This license includes our use of your name, company name, and franchise name, as applicable, and any of the
      trademarks, service marks, trade names, logos, and personal and commercial images you provide.</p>

    <p><strong>You are responsible for what you post or upload:</strong> By sending us Submissions and/or posting
      Contributions through any part of the Services or making Contributions accessible through the Services by linking
      your
      account through the Services to any of your social networking accounts, you:</p>
    <ul>
      <li>confirm that you have read and agree with our "<a href="#prohibited">PROHIBITED ACTIVITIES</a>" and will not
        post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is
        illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any
        person or group, false, inaccurate, deceitful, or misleading;</li>
      <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or
        Contribution;</li>
      <li>warrant that any such Submission and/or Contributions are original to you or that you have the necessary
        rights and licenses to submit such Submissions and/or Contributions and that you have full authority to grant us
        the above-mentioned rights in relation to your Submissions and/or Contributions; and</li>
      <li>warrant and represent that your Submissions and/or Contributions do not constitute confidential information.
      </li>
    </ul>
    <p>You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for
      any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s
      intellectual property rights, or (c) applicable law.</p>

    <p><strong>We may remove or edit your Content:</strong> Although we have no obligation to monitor any Contributions,
      we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable
      opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such
      Contributions, we may also suspend or disable your account and report you to the authorities.</p>

    <p><strong>Copyright infringement</strong></p>
    <p>We respect the intellectual property rights of others. If you believe that any material available on or through
      the Services infringes upon any copyright you own or control, please immediately refer to the <a href="#dmca"></a>
    </p>
  </section>

  <section id="urep">
    <h2>3. User Representations</h2>

    <p>By using the Services, you represent and warrant that:</p>
    <ol>
      <li>All registration information you submit will be true, accurate, current, and complete.</li>
      <li>You will maintain the accuracy of such information and promptly update such registration information as
        necessary.</li>
      <li>You have the legal capacity and you agree to comply with these Legal Terms.</li>
      <li>You are not a minor in the jurisdiction in which you reside.</li>
      <li>You will not access the Services through automated or non-human means, whether through a bot, script, or
        otherwise.</li>
      <li>You will not use the Services for any illegal or unauthorized purpose.</li>
      <li>Your use of the Services will not violate any applicable law or regulation.</li>
    </ol>

    <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
      suspend or terminate your account and refuse all current or future use of the Services (or any portion thereof).
    </p>

  </section>

  <section id="ureg">
    <h2>4. User Registration</h2>

    <p>You are required to register to use the Services. You agree to keep your password confidential and will be
      responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
      username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or
      otherwise objectionable.</p>

  </section>

  <section id="purchase">
    <h2>5. Purchases and Payment</h2>

    <p>We accept the following forms of payment: Credit Card</p>

    <p>You agree to provide current, complete, and accurate purchase and account information for all purchases made via
      the Services. You further agree to promptly update account and payment information, including email address,
      payment method, and payment card expiration date, so that we can complete your transactions and contact you as
      needed.</p>

    <p>You agree to pay all charges at the prices then in effect for your purchases, and you authorize us to charge your
      chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors
      or mistakes in pricing, even if we have already requested or received payment.</p>

    <p>We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or
      cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed
      by or under the same customer account, the same payment method, and/or orders that use the same billing address.
      We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers,
      resellers, or distributors.</p>

  </section>

  <section id="subscriptions">
    <h2>6. Subscriptions</h2>

    <p><strong>Billing and Renewal</strong></p>

    <p>Your subscription will continue and automatically renew unless it is cancelled. You consent to us charging your
      payment method on a regular basis without requiring your prior approval for each recurring charge, until you
      cancel the applicable order. The length of your billing cycle is monthly.</p>

    <p><strong>Cancellation</strong></p>

    <p>All purchases are non-refundable. We use Stripe for handling payments, and cancellations will be processed
      through Stripe. Your cancellation will take effect at the end of the current paid term. Billing will be according
      to monthly maximum usage. Deleted assets will not be subtracted from billing until the billing period rolls over.
    </p>

    <p><strong>Fee Changes</strong></p>

    <p>We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in
      accordance with applicable law.</p>
  </section>

  <section id="prohibited">
    <h2>7. PROHIBITED ACTIVITIES</h2>

    <p>You may not access or use the Services for any purpose other than that for which we make the Services available.
      The Services may not be used in connection with any commercial endeavors except those that are specifically
      endorsed
      or approved by us.</p>

    <p>As a user of the Services, you agree not to:</p>

    <ul>
      <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly,
        a collection, compilation, database, or directory without written permission from us.</li>
      <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
        information such as user passwords.</li>
      <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features
        that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services
        and/or the Content contained therein.</li>
      <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
      <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
      <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
      <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
      <li>Engage in unauthorized framing of or linking to the Services.</li>
      <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
        excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any
        party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes
        with the use, features, functions, operation, or maintenance of the Services.</li>
      <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any
        data mining, robots, or similar data gathering and extraction tools.</li>
      <li>Delete the copyright or other proprietary rights notice from any Content.</li>
      <li>Attempt to impersonate another user or person or use the username of another user.</li>
      <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
        information collection or transmission mechanism, including without limitation, clear graphics interchange
        formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or
        "passive collection mechanisms" or "pcms").</li>
      <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to
        the Services.</li>
      <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
        Services to you.</li>
      <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any
        portion of the Services.</li>
      <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other
        code. </li>
      <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
        software comprising or in any way making up a part of the Services.</li>
      <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
        distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
        offline reader that accesses the Services, or use or launch any unauthorized script or other software.</li>
      <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
      <li>Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by
        electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated
        means or under false pretenses.</li>
      <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for
        any revenue-generating endeavor or commercial enterprise.</li>
      <li>Upload or scrape unlawful material.</li>
      <li>Upload or scrape copyrighted material which you do not own, including but not limited to images, videos, or
        text. We respect intellectual property rights and will promptly remove any infringing material upon
        notification. If you believe that your copyrighted work has been uploaded or used in a way that constitutes
        copyright infringement, please contact us at pixheap(at)hotmail[dot]com.com with the following information: (a)
        a description of the copyrighted work that you claim has been infringed; (b) the location on our platform of the
        material that you claim is infringing; (c) your contact information; (d) a statement that you have a good-faith
        belief that the use of the disputed material is not authorized by the copyright owner, its agent, or the law;
        and (e) a statement by you, made under penalty of perjury, that the above information in your notice is accurate
        and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
    </ul>
  </section>

  <section id="ugc">
    <h2>8. USER GENERATED CONTRIBUTIONS</h2>

    <p>The Services may offer features such as chat, blogs, message boards, online forums, and other interactive
      functionalities, allowing you to create, submit, post, display, transmit, perform, publish, distribute, or
      broadcast content and materials to us or on the Services. These contributions, collectively referred to as
      "Contributions," may include text, writings, video, audio, photographs, graphics, comments, suggestions, or
      personal information. Contributions may be visible to other users of the Services and on third-party websites.
      Therefore, any Contributions you provide may be considered non-confidential and non-proprietary. By creating or
      sharing any Contributions, you affirm and warrant that:</p>

    <ul>
      <li>Your Contributions, including their creation, distribution, transmission, public display, or performance, and
        the accessing, downloading, or copying of them, do not and will not infringe upon the proprietary rights of any
        third party.</li>
      <li>You are the rightful creator and owner of your Contributions or possess the necessary licenses, rights,
        consents, releases, and permissions to use and authorize us, the Services, and other users to use your
        Contributions as outlined in these Legal Terms.</li>
      <li>You have obtained written consent, release, and/or permission from each identifiable individual person
        featured in your Contributions to utilize their name or likeness as necessary for inclusion and use of your
        Contributions in accordance with these Legal Terms.</li>
      <li>Your Contributions are accurate, truthful, and not misleading.</li>
      <li>Your Contributions do not constitute unsolicited or unauthorized advertising, promotional materials, pyramid
        schemes, chain letters, spam, mass mailings, or any other form of solicitation.</li>
      <li>Your Contributions are free from obscenity, lewdness, lasciviousness, vulgarity, violence, harassment, libel,
        slander, or any other objectionable content, as determined by us.</li>
      <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse any individual or group.</li>
      <li>Your Contributions do not engage in harassment or threats (as defined by law) against any individual or group,
        nor do they promote violence against any specific person or class of people.</li>
      <li>Your Contributions comply with all applicable laws, regulations, and rules.</li>
      <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
      <li>Your Contributions do not violate any laws concerning child pornography or intended to protect the health or
        well-being of minors.</li>
      <li>Your Contributions do not contain offensive comments related to race, national origin, gender, sexual
        orientation, or physical handicap.</li>
      <li>Your Contributions do not violate these Legal Terms or any applicable law or regulation, nor do they link to
        material that violates these terms or laws.</li>
    </ul>

    <p>Any use of the Services that contravenes the above provisions constitutes a violation of these Legal Terms and
      may result in the termination or suspension of your rights to use the Services.</p>
  </section>

  <section id="license">
    <h2>9. CONTRIBUTION LICENSE</h2>

    <p>By posting your Contributions to any part of the Services, you automatically grant, and you represent and warrant
      that you have the right to grant to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
      transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose,
      sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat,
      translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without
      limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare
      derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of
      the foregoing. The use and distribution may occur in any media formats and through any media channels.</p>

    <p>This license includes our use of your name, company name, and franchise name, as applicable, and any of the
      trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral
      rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your
      Contributions.</p>

    <p>We do not assert any ownership over your Contributions. You retain full ownership of all your Contributions and
      any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable
      for any statements or representations in your Contributions provided by you in any area on the Services. You are
      solely responsible for your Contributions to the Services, and you expressly agree to exonerate us from any
      responsibility and to refrain from any legal action against us regarding your Contributions.</p>

    <p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
      Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Services;
      and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no
      obligation to monitor your Contributions.</p>
  </section>

  <section id="thirdparty">
    <h2>10. THIRD-PARTY WEBSITES AND CONTENT</h2>

    <p>The Services may include links to other websites ("Third-Party Websites") and may feature articles, photographs,
      text, graphics, designs, music, sound, video, information, applications, software, and other content or items
      originating from third parties ("Third-Party Content").</p>

    <p>These Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy,
      appropriateness, or completeness by us. We are not responsible for any Third-Party Websites accessed through the
      Services or any Third-Party Content posted on, available through, or installed from the Services, including the
      content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies contained therein.
    </p>

    <p>The inclusion of links to Third-Party Websites or the provision of Third-Party Content does not imply approval or
      endorsement by us.</p>

    <p>If you choose to leave the Services and access Third-Party Websites or use/install Third-Party Content, you do so
      at your own risk. Please note that these Legal Terms will no longer govern your activities. It is advisable to
      review the terms and policies, including privacy and data gathering practices, of any website you visit or any
      applications you use/install from the Services.</p>

    <p>Any purchases made through Third-Party Websites are conducted on separate websites and with other companies. We
      do not assume any responsibility for such transactions, which are solely between you and the respective third
      party.</p>

    <p>You acknowledge that we do not endorse the products or services offered on Third-Party Websites, and we shall not
      be held liable for any harm resulting from your purchase of such products or services.</p>

    <p>Furthermore, you agree that we shall not be held liable for any losses or harm suffered by you as a result of or
      in connection with any Third-Party Content or interaction with Third-Party Websites.</p>

  </section>

  <section id="sitemanage">
    <h2>11. SERVICES MANAGEMENT</h2>

    <p>We retain the right, though not the obligation, to:</p>

    <ol>
      <li>Monitor the Services for violations of these Legal Terms.</li>
      <li>Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal
        Terms, including, without limitation, reporting such users to law enforcement authorities.</li>
      <li>Refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any
        of your Contributions or any portion thereof, at our sole discretion and without limitation.</li>
      <li>Remove from the Services or disable, without notice or liability, all files and content that are excessive in
        size or in any way burdensome to our systems.</li>
      <li>Manage the Services in a manner aimed at protecting our rights and property and ensuring the proper
        functioning of the Services.</li>
    </ol>
  </section>

  <section id="ppyes">
    <h2>12. PRIVACY POLICY</h2>

    <p>We prioritize data privacy and security. Please take a moment to review our <a
        href="pixheap.com/#/privacy-policy">Privacy Policy</a>. By using the Services, you agree to adhere to our
      Privacy Policy, which is an integral part of these Legal Terms. It's important to note that the Services are
      hosted in the Netherlands. If you access the Services from any other region of the world with laws or regulations
      governing the collection, use, or disclosure of personal data that differ from those in the Netherlands, you are
      thereby consenting to the transfer of your data to the Netherlands and its processing in accordance with Dutch
      laws by continuing to use the Services.</p>
  </section>

  <section id="copyrightyes">
    <h2>13. COPYRIGHT INFRINGEMENTS</h2>

    <p>We value and respect the intellectual property rights of others. If you believe that any material available on or
      through the Services infringes upon a copyright you own or control, please notify us immediately using the contact
      information provided below (a "Notification"). A copy of your Notification will be forwarded to the individual who
      posted or stored the material mentioned in the Notification. It's important to note that under applicable law, you
      may be held liable for damages if you make material misrepresentations in a Notification. Therefore, if you're
      uncertain whether material on or linked to by the Services infringes your copyright, it's advisable to seek legal
      counsel before taking further action.</p>
  </section>

  <section>
    <h2>14. Term and Termination</h2>

    <p>These legal terms shall remain in full force and effect for the duration of your use of the services. Without
      limiting any other provision of these legal terms, we reserve the right, at our sole discretion and without notice
      or liability, to deny access to and use of the services (including blocking certain IP addresses) to any
      individual, for any reason or no reason at all. This includes, but is not limited to, breaches of any
      representation, warranty, or covenant outlined in these legal terms or any applicable law or regulation. We may
      terminate your use or participation in the services, delete your account, and remove any content or information
      you posted at any time, without prior notice, at our sole discretion.</p>

    <p>If we terminate or suspend your account for any reason, you are prohibited from re-registering and creating a new
      account under your name, a false identity, or the name of any third party, even if you are acting on behalf of
      that third party. In addition to terminating or suspending your account, we reserve the right to take appropriate
      legal action, including, but not limited to, pursuing civil, criminal, and injunctive remedies.</p>
  </section>

  <section>
    <h2>15. MODIFICATIONS AND INTERRUPTIONS</h2>

    <p>We reserve the right to change, modify, or remove the contents of the Services at our sole discretion, at any
      time, and for any reason, without prior notice. However, we are not obligated to update any information on our
      Services. You acknowledge that we will not be liable to you or any third party for any modifications, price
      changes, suspensions, or discontinuance of the Services.</p>

    <p>We cannot guarantee that the Services will be available at all times. We may encounter hardware, software, or
      other issues, or we may need to perform maintenance related to the Services, which could result in interruptions,
      delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the
      Services at any time and for any reason without notice to you. You agree that we shall not be liable for any loss,
      damage, or inconvenience caused by your inability to access or use the Services during any downtime or
      discontinuance of the Services. Nothing in these Legal Terms shall be construed to obligate us to maintain and
      support the Services or to provide any corrections, updates, or releases in connection therewith.</p>
  </section>

  <section>
    <h2>16. GOVERNING LAW</h2>

    <p>These Legal Terms are governed by and interpreted in accordance with the laws of Sweden, with the United Nations
      Convention on Contracts for the International Sale of Goods expressly excluded. If you are a consumer residing in
      the EU, you also benefit from the mandatory legal protections provided by the laws of your country of residence.
      Both PixHeap and you agree to submit to the non-exclusive jurisdiction of the courts of Sweden. This means that
      you have the right to assert your consumer protection rights concerning these Legal Terms in Sweden.</p>
  </section>

  <section>
    <h2>17. DISPUTE RESOLUTION</h2>

    <strong>Informal Negotiations</strong>
    <p>To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms
      (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and
      collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute informally for at least
      360 days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to
      the other Party.</p>

    <strong>Binding Arbitration</strong>
    <p>Any dispute arising from the relationships between the Parties to these Legal Terms shall be determined by one
      arbitrator who will be chosen in accordance with the Arbitration and Internal Rules of the European Court of
      Arbitration, which is part of the European Centre of Arbitration with its seat in Strasbourg, and which are in
      force at the time the application for arbitration is filed, and of which adoption of this clause constitutes
      acceptance. The seat of arbitration shall be Stockholm, Sweden, and the language of the proceedings shall be
      Swedish. Applicable rules of substantive law shall be the law of Sweden.</p>

    <strong>Restrictions</strong>
    <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the
      full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right
      or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and
      (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf
      of the general public or any other persons.</p>

    <strong>Exceptions to Informal Negotiations and Arbitration</strong>
    <p>The Parties agree that the following Disputes are not subject to the above provisions concerning informal
      negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity
      of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations
      of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this
      provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling
      within that portion of this provision found to be illegal or unenforceable, and such Dispute shall be decided by a
      court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit
      to the personal jurisdiction of that court.</p>
  </section>

  <section>
    <h2>18. CORRECTIONS</h2>
    <p>The Services may contain information that includes typographical errors, inaccuracies, or omissions, such as
      descriptions, pricing, availability, and other details. We retain the right to rectify any such errors,
      inaccuracies, or omissions, and to modify or update the information on the Services without prior notice.</p>
  </section>

  <section>
    <h2>19. DISCLAIMER</h2>
    <p>The Services are provided on an "as-is" and "as-available" basis. You agree that your use of the Services is at
      your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, regarding
      the Services and your use thereof, including, but not limited to, the implied warranties of merchantability,
      fitness for a particular purpose, and non-infringement.</p>
    <p>We make no warranties or representations regarding the accuracy or completeness of the Services' content or the
      content of any websites or mobile applications linked to the Services. We assume no liability for:</p>
    <ul>
      <li>Errors, mistakes, or inaccuracies of content and materials</li>
      <li>Personal injury or property damage resulting from your access to and use of the Services</li>
      <li>Any unauthorized access to or use of our secure servers and/or any personal or financial information stored
        therein</li>
      <li>Any interruption or cessation of transmission to or from the Services</li>
      <li>Any bugs, viruses, trojan horses, or similar issues transmitted to or through the Services by any third party
      </li>
      <li>Any errors or omissions in any content and materials, or any loss or damage incurred as a result of the use of
        any content posted, transmitted, or otherwise made available via the Services</li>
    </ul>
    <p>We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered
      by a third party through the Services, any hyperlinked website, or any website or mobile application featured in
      any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any
      transaction between you and any third-party providers of products or services. As with the purchase of any product
      or service through any medium or environment, you should use your best judgment and exercise caution where
      appropriate.</p>
  </section>

  <section>
    <h2>20. Limitations of Liability</h2>

    <p>In no event will we or our directors, employees, or agents be liable to you or any third party for any direct,
      indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue,
      loss of data, or other damages arising from your use of the Services, even if we have been advised of the
      possibility of such damages.</p>

    <p>Notwithstanding anything to the contrary contained herein, our liability to you for any cause whatsoever and
      regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to us during
      the one (1) month period prior to any cause of action arising. Certain US state laws and international laws do not
      allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to
      you, some or all of the above disclaimers or limitations may not apply to you, and you may have additional rights.
    </p>
  </section>

  <section>
    <h2>21. INDEMNIFICATION</h2>

    <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
      respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
      demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of: (1)
      your Contributions; (2) your use of the Services; (3) breach of these Legal Terms; (4) any breach of your
      representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party,
      including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of
      the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at
      your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify
      us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts
      to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming
      aware of it.</p>
  </section>

  <section>
    <h2>22. USER DATA</h2>
    <p>We will retain certain data that you submit to the Services to manage the performance of the platform, along with
      data concerning your usage of the Services. While we conduct regular routine backups of data, you bear full
      responsibility for all data you submit or that pertains to any actions you undertake while using the Services. By
      using our platform, you agree that we bear no liability for any loss or corruption of such data. Additionally, you
      hereby waive any right to take legal action against us for any loss or corruption of your data.</p>
  </section>

  <section>
    <h2>23. Electronic Communications, Transactions, and Signatures</h2>

    <p>Visiting the Services, sending us emails, and completing online forms constitute electronic communications. By
      using our Services, you consent to receive electronic communications, and you agree that all agreements, notices,
      disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any
      legal requirement that such communication be in writing. You hereby agree to the use of electronic signatures,
      contracts, orders, and other records, and to electronic delivery of notices, policies, and records of transactions
      initiated or completed by us or via the Services. You hereby waive any rights or requirements under any statutes,
      regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery
      or retention of non-electronic records, or to payments or the granting of credits by any means other than
      electronic means.</p>

  </section>

  <section>
    <h2>24. Miscellaneous</h2>

    <p>These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services
      constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right
      or provision of these Legal Terms shall not operate as a waiver of such right or provision.</p>

    <p>These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and
      obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure
      to act caused by any cause beyond our reasonable control.</p>

    <p>If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or
      unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not
      affect the validity and enforceability of any remaining provisions.</p>

    <p>There is no joint venture, partnership, employment, or agency relationship created between you and us as a result
      of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by
      virtue of having drafted them.</p>

    <p>You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack
      of signing by the parties hereto to execute these Legal Terms.</p>
  </section>

  <section id="contact">
    <h2>25. Contact Us</h2>

    <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the
      Services, please contact us via the <a href="https://pixheap.com/#/feedback-page">Feedback page</a> or at
      pixheap(at)hotmail[dot]com.</p>
  </section>

  <div class="buttons">
    <sl-button variant="danger" click.trigger="denyClick()">Deny TOU</sl-button>
    <sl-button variant="primary" class="pull-right" click.trigger="acceptClick()">Accept Terms of Use</sl-button>
  </div>
</dialog>