<!-- The row below works if this is NOT used to a registry bundle,
a very smooth way to import a registry bundle from a template otherwise! :D -->
<!-- <import from="./linkAdminModuleRegistry"></import> -->

<!-- <import from "dragula/dist/dragula.css"></import> -->
<!-- <import from "../../../node_modules/dragula/dist/dragula.css"></import> -->
<delete-folder></delete-folder>
<edit-folder></edit-folder>

<div class="header">
  <add-folder type="link"></add-folder>
  <add-link></add-link>
</div>

<div class="scroll-container" ref="linkcontainer">
  <template repeat.for="folder of linkService.folders">
    <folder-component folder.bind="folder" type="link" closefolder.bind="closefolder"></folder-component>

    <div class="folder-content" expanded.class="folder.links.length>0">
      <!-- need to start slicing to reduce elements here -->
      <link-component repeat.for="link of folder.links" link.bind="link"></link-component>
    </div>
  </template>
</div>
