<h1>Send us some Feedback!</h1>

<p>Feel free to report things that you feel could be improved. Things that are missing. Or just some general feedback on what you think about the product.</p>

<form ref="form" submit.trigger="submitFeedback()">
  <sl-input ref="input" type="text" name="subject" label="Subject" placeholder="Optional Subject.."></sl-input>

  <sl-textarea ref="textarea" name="feedback" label="Feedback" placeholder="Feedback here..." resize="auto"></sl-textarea>

  <sl-button type="submit" class="pull-right" loading.bind="loading">Submit</sl-button>
</form>
