import { DI } from "aurelia";

import { AuthService, IAuthService } from "./authService";
import { DataService, IDataService } from "./network/dataService";
import { Folder } from "./interfaces/IFolder";
import { FolderService, IFolderService } from "./folderService";
import { IGallery } from "./interfaces/IGallery";
import { IGalleryPreview } from "./interfaces/IGalleryPreview";
import { ILogService, LogService } from "./logging/logService";
import { IUIFolder } from "./interfaces/IUIFolder";
import { NotificationService, INotificationService } from "./notificationService";
import { IUIGallery } from "../common/interfaces/IUIGallery";


type GalleryPreview = { id: string };

export class GalleryService {

  public folders: Array<Folder & IUIFolder> = [];
  public latestGalleryPreviews: IGalleryPreview[] = [];

  private serviceInitiated = false;
  private readonly defaultFolderId = "00000000-0000-0000-0000-000000000000";

  constructor(
    @IDataService private readonly dataService: DataService,
    @INotificationService private readonly notificationService: NotificationService,
    @ILogService private readonly logService: LogService,
    @IFolderService private readonly folderService: FolderService,
    @IAuthService private readonly authService: AuthService
  ) { }

  public async initGalleryPreviewAsync(): Promise<void> {
    // skip if already loaded
    if (this.latestGalleryPreviews.length > 0) return;

    const result = await this.dataService.getLatestGalleries(35);
    if (result.ok) {
      this.latestGalleryPreviews.push(...(result.data as IGalleryPreview[]));
    } else {
      this.logService.logEvent("Failed to fetch gallery preview");
    }
  }

  public async initAsync(): Promise<void> {
    // if the service already has been initiated, make an early exit
    if (this.serviceInitiated) return;

    this.logService.logEvent("GalleryService.initAsync() called");

    // get all folders
    this.folders = await this.folderService.getFolders("all", "gallery");

    const result = await this.getGalleries(this.defaultFolderId);
    if (!result) {
      this.notificationService.error(
        {
          title: "Galleries",
          message: "Failed to load galleries 😢",
          type: "error",
          date: new Date(),
          url: ""
        });
      return;
    }

    const basefolder = this.folders.find((f) => f.id === this.defaultFolderId);
    basefolder.galleries.push(...result);

    this.folderService.sortActiveFolderWithActiveFilter();

    this.serviceInitiated = true;
  }

  public async createGalleryFromURL(url: string): Promise<void> {
    const result = await this.dataService.downloadInCloudFromURL(url);
    if (result.ok) {
      this.notificationService.info(
        {
          title: "Gallery Download",
          message: "Gallery download started",
          type: "info",
          date: new Date(),
          url: ""
        });
    } else {
      if (result.status === 402) {
        // subscription required for saving galleries
        this.notificationService.modal(
          {
            title: "Subscription required",
            message: "You need to have a valid subscription to save galleries",
            type: "error",
            date: new Date(),
            url: "price-page"
          }
        );
        this.logService.logEvent("User tried to save gallery without valid subscription", { user: this.authService.user.id });
      } else {
        this.notificationService.error({
          title: "Galleries",
          message: "Failed to start gallery creation 😢",
          type: "error",
          date: new Date(),
          url: ""
        });
      }
    }
  }

  // delete gallery
  public async deleteGallery(galleryId: string): Promise<boolean> {
    const result = await this.dataService.deleteGallery(galleryId);
    if (result.ok) {
      this.removeGalleryFromCollection(galleryId);
    }
    return result.ok;
  }

  // download gallery to browser
  public async downloadGallery(galleryId: string) {
    return await this.dataService.downloadGallery(galleryId);
  }

  // get one gallery
  public async getGallery(galleryId: string): Promise<IGallery> {
    if (!this.serviceInitiated) {
      await this.initAsync();
    }

    for (let index = 0; index < this.folders.length; index++) {
      const pos = this.folders[index].galleries?.findIndex((g) => g.id == galleryId);
      if (pos > -1) {
        return this.folders[index].galleries[pos];
      }
    }

    // go check the server for the gallery if it's not cached
    const result = await this.dataService.getGallery(galleryId);
    if (result.ok) {
      return result.data as IGallery;
    }
    // no galleries/folders loaded
  }

  /// update gallery
  public async updateGallery(gallery: IGallery): Promise<IGallery> {
    const result = await this.dataService.updateGallery(gallery);
    if (result.ok) {
      return result.data as IGallery;
    }
  }

  /// create gallery
  public async createGallery(title: string, folderId: string, files: FileList) {
    const formData = new FormData();
    formData.set("title", title);
    formData.set("folderId", folderId);

    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      formData.append("file", element);
    }

    const result = await this.dataService.createGallery(formData);
    if (result.ok) {
      if (folderId === "") {
        folderId = this.defaultFolderId;
      }
      this.addGalleryToFolder(folderId, result.data);
    } else if (result.status === 402) {
      // subscription required for saving galleries
      this.notificationService.modal(
        {
          title: "Subscription required",
          message: "You need to have a valid subscription to save galleries",
          type: "error",
          date: new Date(),
          url: "price-page"
        }
      );
      this.logService.logEvent("User tried to save gallery without valid subscription", { user: this.authService.user.id });
    } else {
      throw new Error("Failed to save gallery");
    }
  }

  public async addGallery(gallery: IGallery) {
    if (this.folders.length === 0) {
      // get all folders
      this.folders = await this.folderService.getFolders("all", "gallery");
    }
    const folder = this.folders.find((folder) => folder.id === gallery.folderId);

    const galleryAlreadyExists = folder.galleries.find((g) => g.id === gallery.id);
    if (galleryAlreadyExists) {
      this.updateExistingGallery(galleryAlreadyExists, gallery);
    } else if (folder.expanded) {
      folder.galleries.push(gallery);
      this.folderService.sortActiveFolderWithActiveFilter();
    }
  }

  private updateExistingGallery(galleryAlreadyExists: IGallery, gallery: IGallery) {
    galleryAlreadyExists.title = gallery.title;
    galleryAlreadyExists.views = gallery.views;

    // Update imageFiles
    gallery.imageFiles.forEach((file) => {
      if (!galleryAlreadyExists.imageFiles.includes(file)) {
        galleryAlreadyExists.imageFiles.push(file);
      }
    });
    galleryAlreadyExists.imageFiles = galleryAlreadyExists.imageFiles.filter((file) => gallery.imageFiles.includes(file));

    // Update thumbnails
    gallery.thumbnails.forEach((thumbnail) => {
      if (!galleryAlreadyExists.thumbnails.includes(thumbnail)) {
        galleryAlreadyExists.thumbnails.push(thumbnail);
      }
    });
    galleryAlreadyExists.thumbnails = galleryAlreadyExists.thumbnails.filter((thumbnail) => gallery.thumbnails.includes(thumbnail));

    // check if gallery implements IUIGallery and has selectedThumbnails
    if ((galleryAlreadyExists as IUIGallery).selectedThumbnails) {
      // make sure that thumbnails and selectedThumbnails are in sync
      galleryAlreadyExists.thumbnails.forEach((thumbnail, index) => {
        if (!(galleryAlreadyExists as IUIGallery).selectedThumbnails[index]) {
          (galleryAlreadyExists as IUIGallery).selectedThumbnails.push({ thumb: thumbnail, selected: false });
        }
      });
    }
    return false;
  }

  public async renameImage(galleryId: string, fileName: string, newFileName: string): Promise<boolean> {
    const originalFileName = (await this.getGallery(galleryId)).imageFiles.find((f) => f.startsWith(fileName));
    const fileType = originalFileName.split(".").pop();
    const result = await this.dataService.renameImage(galleryId, originalFileName, newFileName + "." + fileType);
    if (result.ok) {
      // uppdate the gallery in memory
      let gallery = result.data as IGallery;
      const folder = this.folders.find((f) => f.id === gallery.folderId);
      const galleryIndex = folder.galleries.findIndex((g) => g.id === gallery.id);

      // update image with new name
      const imageIndex = folder.galleries[galleryIndex].imageFiles.findIndex((f) => f === originalFileName);
      gallery = folder.galleries[galleryIndex];
      gallery.imageFiles.splice(imageIndex, 1, newFileName + "." + fileType);

      // update thumbnail with new name
      const thumbIndex = gallery.thumbnails.findIndex((t) => t === `thumbs/${originalFileName}`);
      gallery.thumbnails.splice(thumbIndex, 1, `thumbs/${newFileName}.${fileType}`);

      // check if gallery implements IUIGallery and update thumb to new name
      if ((gallery as IUIGallery).selectedThumbnails) {
        (gallery as IUIGallery).selectedThumbnails[imageIndex].thumb = `thumbs/${newFileName}.${fileType}`;
      }
      return true;
    }
    return false;
  }

  private async addGalleryToFolder(folderId: string, gallery) {
    const folder = this.folders.find((folder) => folder.id === folderId);
    if (!folder.galleries) folder.galleries = [];
    folder.galleries.push(gallery);
    this.folderService.sortActiveFolderWithActiveFilter();
  }

  public async updateViewCount(gallery: IGallery): Promise<void> {
    await this.dataService.updateGalleryViewCount(gallery.id);
    gallery.views++;
  }

  // preview clicked
  public async galleryPreviewClicked(galleryPreviewId: string): Promise<void> {
    const status = { time: new Date(), galleryPreviewId: galleryPreviewId };
    this.dataService.sendStatus([status]);
  }

  public async getGalleryPreview(galleryPreviewId: string): Promise<IGalleryPreview> {
    const result = await this.dataService.getGalleryPreview(galleryPreviewId);
    if (result.ok) {
      return result.data as IGalleryPreview;
    }
  }

  // #region images
  public async deleteImages(gallery: IGallery, tobedeleted: string[]): Promise<void> {
    for (let i = 0; i < tobedeleted.length; i++) {
      gallery.thumbnails.splice(
        gallery.thumbnails.findIndex((t) => t === tobedeleted[i]),
        1
      );
      const imgName = tobedeleted[i].replace("thumbs/", "");
      const pos = gallery.imageFiles.findIndex((i) => i === imgName);
      if (pos === -1) return;
      gallery.imageFiles.splice(pos, 1);
    }
    await this.updateGallery(gallery);
    //TODO: delete all files and thumbnails server side
  }

  public async copyImages(fromGalleryId: string, toGalleryId: string, images: string[]): Promise<boolean> {
    const result = await this.dataService.copyFiles(fromGalleryId, toGalleryId, images);
    return result.ok;
  }

  // #endregion

  //#region get all galleries in folder

  public async getGalleriesInFolder(folderId: string): Promise<IGallery[]> {
    // implement caching?
    const result = await this.getGalleries(folderId);
    return result;
  }

  //#endregion


  // ------ private

  private async getGalleries(folderId: string): Promise<IGallery[]> {
    return await this.dataService.getGalleriesInFolder(folderId);
  }

  // remove gallery from memory storage
  private removeGalleryFromCollection(galleryId: string): void {
    for (let index = 0; index < this.folders.length; index++) {
      const pos = this.folders[index].galleries?.findIndex((g) => g.id == galleryId);
      if (pos > -1) {
        this.folders[index].galleries.splice(pos, 1);
        break;
      }
    }
  }
}

export const IGalleryService = DI.createInterface<GalleryService>("IGalleryService", (x) => x.singleton(GalleryService));
