<sl-dialog ref="dialog" label="Copy Files">
  <form ref="form">
    <p>Select the destination gallery to copy the files too.</p>

    <sl-select ref="slselect" label="Destination Folder" name="folderId" value.bind="gallery.folderId" hoist>
      <sl-icon slot="prefix" name="folder-symlink"></sl-icon>
      <sl-option repeat.for="folder of linkService.folders" value.bind="folder.id">${folder.name} </sl-option>
    </sl-select>

    <br />

    <sl-select ref="destinationSelect" label="Destination Gallery" name="destinationGalleryId"
      placeholder="Select a gallery" hoist>
      <template if.bind="galleriesLoading">
        <sl-spinner slot="prefix"></sl-spinner>
        <sl-option>Loading...</sl-option>
      </template>
      <template else>
        <sl-icon slot="prefix" name="images"></sl-icon>
        <sl-option repeat.for="newGallery of galleries" value.bind="newGallery.id">${newGallery.title} </sl-option>
      </template>
    </sl-select>

    <br>

    <sl-button click.trigger="save()" class="pull-right" loading.bind="loading || galleriesLoading"
      disabled.bind="!canCopy">Copy</sl-button>
  </form>
</sl-dialog>