import { DI, IEventAggregator } from "aurelia";
import { EventType } from "./eventType";
import { UINotification } from "./uiNotification";

export class NotificationService {

  constructor(@IEventAggregator private readonly eventAggregator: IEventAggregator) { }

  public error(notification: UINotification): void {
    this.eventAggregator.publish("uiNotification" as EventType, notification);
  }

  public warning(message: string): void {
    console.warn(message);
  }

  public info(notification: UINotification): void {
    this.eventAggregator.publish("uiNotification" as EventType, notification);
  }

  public modal(notification: UINotification): void {
    this.eventAggregator.publish("uiNotificationModal" as EventType, notification);
  }
}

export const INotificationService = DI.createInterface<NotificationService>("INotificationService", (x) => x.singleton(NotificationService));
