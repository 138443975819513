import { APIResult } from "./network/apiResult";
import { EventType } from "./eventType";
import { DI, EventAggregator, IDisposable, IEventAggregator } from "aurelia";
import { DataService, IDataService } from "./network/dataService";

export class ProfileService {

  private eventSubscriptions: IDisposable[] = new Array<IDisposable>();

  public profile: object = { name: "empty" };

  constructor(@IEventAggregator private readonly eventAggregator: EventAggregator, @IDataService private readonly dataService: DataService) {
    this.eventSubscriptions = this.setupEventHandlers();
  }

  public dispose(): void {
    this.eventSubscriptions.forEach((e) => e.dispose());
  }

  /**
   * set up all event handlers
   *
   * @private
   * @memberof ProfileService
   */
  private setupEventHandlers(): IDisposable[] {
    const subs = new Array<IDisposable>();

    subs.push(
      this.eventAggregator.subscribe("profileFetched" as EventType, (profile: APIResult) => {
        this.profile["name"] = profile.data as string;
      })
    );

    return subs;
  }

  public async loadProfile() {
    return await this.dataService.getFullUserProfile();
  }

  public async sendFeedback(subject: string, feedback: string) {
    const success = await this.dataService.sendFeedback(subject, feedback);
    return success;
  }

}

export const IProfileService = DI.createInterface<ProfileService>("IProfileService", (x) => x.singleton(ProfileService));
