<div class="page">
  <div class="header button-row padding-s">
    <sl-button outline click.trigger="saveLink()">
      <sl-icon slot="prefix" name="link"></sl-icon>
      Save Link</sl-button>
    <sl-button variant="success" click.trigger="()=>saveGallery()">
      <sl-icon slot="prefix" name="images"></sl-icon>
      Save Gallery</sl-button>
  </div>

  <div>
    <a if.bind="preview.url" class="header padding-l" href.bind="preview.url" target="_blank">🔗${preview.title ??
      preview.url}</a>
    <loading-spinner else></loading-spinner>
  </div>

  <!-- info to show if the user is not logged in -->
  <div class="info padding-l link" if.bind="authService.isLoggedIn === false" click.trigger="authService.logIn()">
    <p><span class="link-highlight">Log in</span> to save links and download galleries.</p>
  </div>

  <iframe if.bind="!http && preview.csp===''" src.bind="preview.url" title.bind="preview.title"
    blur.class="!adminService.showFrontPageGalleries"></iframe>
</div>

<dialog ref="noticelogin">
  <h1>Login Required</h1>
  <p>You need to be logged in to download galleries and save links.</p>
  <div class="button-row">
    <sl-button click.trigger="close($this.noticelogin)">Close</sl-button>
    <sl-button variant="success" click.trigger="loadLogin()">Login</sl-button>
  </div>
</dialog>

<dialog ref="noticesubscription">
  <h1>Subscription Required</h1>
  <p>You need a valid subscription to download more galleries or save more links.</p>
  <div class="button-row">
    <sl-button click.trigger="close($this.noticesubscription)">Close</sl-button>
    <sl-button variant="success" click.trigger="loadPricePage()">Go</sl-button>
  </div>
</dialog>