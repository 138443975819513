<add-folder type="gallery"></add-folder>

<sl-icon-button name="link" label="Create from Link" title="Create from Link" click.trigger="createGallery()"></sl-icon-button>

<sl-icon-button name="images" label="Upload Gallery" title="Upload Gallery" click.trigger="uploadGallery()"></sl-icon-button>

<sl-dialog label="Upload Gallery" ref="uploadgallery">
  <form ref="uploadform">
    <sl-input
      ref="nameinput"
      name="galleryName"
      label="Gallery Name"
      autocomplete="off"
      placeholder="What do you want to call this Gallery?"
      required
    ></sl-input>

    <br />

    <sl-select ref="selectelement" name="folderId" placement="bottom" label="Folder" value.bind="folderId" required hoist>
      <sl-option repeat.for="folder of galleryService.folders" value.bind="folder.id">${folder.name}</sl-option>
    </sl-select>
  </form>
  <br />

  <image-uploader if.bind="formValid" gallery-title.bind="galleryName" folder-id.bind="folderId"></image-uploader>
</sl-dialog>

<sl-dialog label="Create Gallery" ref="creategallery">
  <form ref="createform">
    <sl-input ref="urlinput" name="url" label="Gallery Url" autocomplete="off" placeholder="A Link to a Gallery" type="url" required></sl-input>

    <br />

    <sl-button class="pull-right" click.trigger="submitGalleryUrl()">Submit</sl-button>
  </form>
</sl-dialog>

<sl-dialog ref="noticesubscription">
  <h1>Subscription Required</h1>
  <p>You need a valid subscription to download more galleries or save more links.</p>
  <div class="button-row">
    <sl-button click.trigger="$this.noticesubscription.hide()">Close</sl-button>
    <sl-button click.trigger="loadPricePage()">Go</sl-button>
  </div>
</sl-dialog>
