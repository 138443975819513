<div class="header-row">
  <sl-button class="pull-right" click.trigger="()=>createGallery()">Create Gallery</sl-button>
  <h2>${link.givenTitle || link.title || link.url | urlToUI & oneTime}</h2>
</div>

<p class="link-row"><sl-icon name="box-arrow-up-right"></sl-icon><a href.bind="link.url" target="blank" external>
    ${link.url & oneTime}</a></p>

<div class="row">
  <p title="Create Date"><sl-icon name="calendar2-check"></sl-icon> ${link.createDate | displayDate & oneTime}</p>
  <p title="In Folder"><sl-icon name="folder2"></sl-icon> ${folder.name}</p>
</div>

<div class="row">
  <p if.bind="link.processedDate" title="Process Date"><sl-icon name="images"></sl-icon> ${link.processedDate |
    displayDate & oneTime}</p>
  <!-- <p if.bind="link.status" title="Processing Status">Status: ${link.status | linkStatus & oneTime}</p> -->
</div>

<sl-dialog ref="noticesubscription">
  <h1>Subscription Required</h1>
  <p>You need a valid subscription to download more galleries or save more links.</p>
  <div class="button-row">
    <sl-button click.trigger="$this.noticesubscription.hide()">Close</sl-button>
    <sl-button click.trigger="loadPricePage()">Go</sl-button>
  </div>
</sl-dialog>