import { IAuthService, AuthService } from "../../../../common/authService";
import { DataService, IDataService } from "../../../../common/network/dataService";
import { ProfileService, IProfileService } from "../../../../common/profileService";

export class ProfilePage {
  public profile;

  constructor(
    @IAuthService public readonly authService: AuthService,
    @IProfileService private readonly profileService: ProfileService,
    @IDataService private readonly dataService: DataService
  ) { }

  public async loading() {
    this.profile = await this.profileService.loadProfile();
    // force reload of user
    await this.dataService.refreshUser();
  }
}
