<import from="./../../components/week-graph/week-graph.ts"></import>
<import from="./../../components/month-graph/month-graph.ts"></import>

<week-graph title="New Users this week" label="# of Users" data.one-time="weeklyUsers"></week-graph>
<month-graph title="New Users last month" label="# of Users" data.one-time="monthlyUsers"></month-graph>

<week-graph title="Links created this week" label="# of Links" data.one-time="weeklyLinks"></week-graph>
<month-graph title="Links created last month" label="# of Links" data.one-time="monthlyLinks"></month-graph>

<week-graph title="Galleries created this week" label="# of Galleries" data.one-time="weeklyGalleries"></week-graph>
<month-graph title="Galleries created last month" label="# of Galleries" data.one-time="monthlyGalleries"></month-graph>

<week-graph title="New subscriptions this week" label="# of Subscriptions" data.one-time="weeklySubscriptions"></week-graph>
<month-graph title="New subscriptions last month" label="# of Subscriptions" data.one-time="monthlySubscriptions"></month-graph>

<h2>MRR</h2>
<h2>Projected ARR</h2>
