import Aurelia from "aurelia";
import { RouterConfiguration } from '@aurelia/router';


import { MainApp } from "./main-app";
import * as commonRegistry from "./modules/common/common-registry";
import * as linkAdminRegistry from "./modules/links-module/linkAdminModuleRegistry";
import * as profileRegistry from "./modules/users-module/registry";
import * as galleriesModule from "./modules/galleries-module/registry";
import * as logging from "./common/logging/loggingRegistry";
import * as admin from "./modules/admin-module/admin-registry";
import * as shoeLaceRegistry from "./common/shoeLaceRegistry";

// import '@shoelace-style/shoelace/dist/themes/light.css';
// import '@shoelace-style/shoelace/dist/components/button/button.js';
// import '@shoelace-style/shoelace/dist/components/icon/icon.js';

Aurelia.register(logging)
  .register(RouterConfiguration.customize({
    title: "PixHeap",
    useHref: false
  }))
  .register(shoeLaceRegistry)
  .register(linkAdminRegistry)
  .register(profileRegistry)
  .register(galleriesModule)
  .register(commonRegistry)
  .register(admin)
  .app(MainApp)
  .start();


// trying to inject the root container and get access to it at every navigation
// plan was to use it to load and inject modules on beforeNavigation

// const container = DI.createContainer();
// const au = new Aurelia(container)
// .register(
//   RouterConfiguration.customize({
//     hooks: [
//       {
//         hook: (viewportInstructions): boolean => {
//           // Your selection and import code here based on criteria
//           // Aurelia's global container is now in container variable
//           // so you can register whatever you import
//           console.log(container);
//           console.log(viewportInstructions);
//           return true;
//         },
//         options: { exclude: ["welcome", "about", "login"] },
//       },
//     ],
//   })
// )
//   .register(RouterConfiguration)
//   .app(MainApp);
// au.start();

