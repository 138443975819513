import { DI, IEventAggregator, IDisposable, EventAggregator } from "aurelia";

import { APIResult } from "./network/apiResult";
import { DataService, IDataService } from "./network/dataService";
import { EventType } from "./eventType";
import { FolderService, IFolderService } from "./folderService";
import { Folder } from "./interfaces/IFolder";
import { Link } from "./interfaces/ILink";
import { IUIFolder } from "./interfaces/IUIFolder";
import { LogService, ILogService } from "./logging/logService";
import { NotificationService, INotificationService } from "./notificationService";
import { AuthService, IAuthService } from "./authService";

export class LinkService {
  private eventSubscriptions: IDisposable[] = new Array<IDisposable>();

  public folders: Array<Folder & IUIFolder> = [];

  constructor(
    @IDataService private readonly dataService: DataService,
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IFolderService private readonly folderService: FolderService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService,
    @IAuthService private readonly authService: AuthService
  ) {
    this.eventSubscriptions = this.setupEventHandlers();
  }

  public initAsync(): void {
    this.folderService.getFolders("all", "link").then((folders) => {
      this.folders = folders;
      this.getLinksInBaseFolder().then((links) => {
        this.folders[0].links.push(...links);
        this.folderService.sortActiveFolderWithActiveFilter();
      });
    });
  }

  public dispose(): void {
    // remove all event listeners
    this.eventSubscriptions.forEach((sub) => sub.dispose());
  }

  public async getLink(linkId: string): Promise<Link> {
    let link = this.getLinkFromCache(linkId);
    if (link) return link;

    const result = await this.dataService.getLink(linkId);
    if (result.ok) {
      link = result.data as Link;
      return link;
    }

    throw new Error("Failed to get link");
  }


  public async create(url: string): Promise<Link> {
    this.logService.logEvent("LinkService - create");

    const result = await this.dataService.createLink(url);
    if (result.ok) {
      const link = result.data as Link;
      this.folders[0].links.unshift(link);
      this.notificationService.info({
        title: "Link saved",
        message: "Link sucessfully saved.",
        type: "info",
        date: new Date(),
        url: `link-details(${link.id})`,
        isExternalURL: false,
      });
      return result.data as Link;
    } else if (!result.ok) {
      if (result.status == 409) {
        const link = result.data as Link;
        this.notificationService.info({
          title: "Link exists",
          message: "Link already in collection.",
          type: "warning",
          date: new Date(),
          url: `link-details(${link.id})`,
          isExternalURL: false,
        });
        return;
      }
    }
  }

  public downloadInCloud(link: Link): void {
    this.logService.logEvent("LinkService - downloadInCloud");

    // check user stripeSubscriptionStatus and decide if they can download also if stripeSubscriptionStatus is null then check if galleryCount is less than 10
    if (this.authService.user.stripeSubscriptionStatus !== null
      && (this.authService.user.stripeSubscriptionStatus !== "canceled" && this.authService.user.stripeSubscriptionStatus !== "unpaid")
      || (this.authService.user.stripeSubscriptionStatus === null && this.authService.user.galleryCount < 10)) {
      this.eventAggregator.publish("downloadInCloud", link);
    }
    else {
      if (this.authService.user.stripeSubscriptionStatus === null && this.authService.user.galleryCount > 9) {
        this.notificationService.warning("You need to upgrade your subscription to download this link");
      } else {
        this.notificationService.warning("You need a valid subscription to download this link");
      }
    }
  }

  public async update(link: Link): Promise<Link> {
    this.logService.logEvent("LinkService - update link");

    return await this.dataService.updateLink(link);
  }

  public delete(link: Link): void {
    this.logService.logEvent("LinkService - delete");

    this.eventAggregator.publish("deleteLink", link);
  }

  public async addLinkToFolder(linkId: string, folderId: string): Promise<void> {
    this.logService.logEvent("LinkService - addLinkToFolder");

    const link = this.getLinkFromCache(linkId);
    link.folderId = folderId;
    await this.update(link);
  }

  public moveLinkToNewFolder(link: Link, originFolderId: string): void {
    this.logService.logEvent("LinkService - moveLinkToNewFolder");

    // remove link from previous folder
    const currentFolder = this.folders.find((folder) => folder.id === originFolderId);
    const linkIndex = currentFolder.links.findIndex((l) => l.id === link.id);
    currentFolder.links.splice(linkIndex, 1);
    // don't add link to new folder as it's closed and needs to be fetched in one go when it's opened
  }

  public async deleteLink(link: Link): Promise<boolean> {
    this.logService.logEvent("LinkService - deleteLink");

    const result = await this.dataService.deleteLink(link);

    if (result.ok) {
      const folder = this.folders.find((folder) => folder.id == link.folderId);
      folder.links.splice(folder.links.findIndex((l) => l.id === link.id), 1);
    }

    return result.ok;
  }

  private getLinkFromCache(linkId: string): Link {
    let link: Link;
    this.folders.forEach((f) => {
      const anyLink = f.links.filter((l) => l.id === linkId);
      if (anyLink.length !== 0) {
        link = anyLink[0];
      }
    });
    return link;
  }

  private async getLinksInBaseFolder(): Promise<Array<Link>> {
    return await this.dataService.getLinksInBaseFolder();
  }

  private setupEventHandlers(): IDisposable[] {
    const subs = new Array<IDisposable>();
    // subs.push(this.eventAggregator.subscribe("linkDeletedResponse" as EventType, (apiResult: APIResult): void => this.deleteLinkCallback(apiResult)));
    // subs.push(this.eventAggregator.subscribe("hydrateApp" as EventType, async (): Promise<void> => await this.initAsync()));
    subs.push(this.eventAggregator.subscribe("hydrateApp" as EventType, () => this.initAsync()));
    return subs;
  }



  private deleteLinkCallback(result: APIResult): void {
    if (result.ok) {
      const link = result.data as Link;
      this.deleteLink(link);
    } else if (!result.ok) {
      alert("Failed to delete link, please try again?");
    }
  }
}

export const ILinkService = DI.createInterface<LinkService>("ILinkService", (x) => x.singleton(LinkService));
