import { EventAggregator, IDisposable, IEventAggregator, bindable } from "aurelia";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";


import { FolderService, IFolderService } from "../../../../common/folderService";
import { ILogService, LogService } from "../../../../common/logging/logService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";
import { EventType } from "../../../../common/eventType";
import { Folder } from "../../../../common/interfaces/IFolder";

export class EditFolder {
  @bindable folder: Folder;
  @bindable public display: boolean;

  public dialog: SlDialog;
  public form: HTMLFormElement;
  public loading: boolean;
  private eventSubscriptions: IDisposable[] = new Array<IDisposable>();

  constructor(
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IFolderService private readonly folderService: FolderService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService
  ) {
    this.eventSubscriptions.push(
      this.eventAggregator.subscribe("editFolder" as EventType, (folder: Folder) => {
        this.folder = folder
        this.dialog.show();
      }));
  }

  public unbinding(): void {
    this.eventSubscriptions.forEach((s) => s.dispose());
  }

  public displayChanged(): void {
    this.dialog.show();
  }

  public async renameFolder(): Promise<void> {
    this.loading = true;
    const formData = serialize(this.form);
    this.folder.name = formData["folderName"] as string;
    const success = await this.folderService.updateFolder(this.folder);
    this.loading = false;

    if (success) {
      this.notificationService.info({
        title: "Folder created",
        message: `Folder renamed to ${this.folder.name}`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Folder renamed: ${this.folder.id}`);
    } else {
      this.notificationService.error({
        title: "Folder creation failed",
        message: `Folder could not be renamed!
        Reason unknown - try again later. If the problem persists, contact support.`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Failed to rename folder: ${this.folder.id}`);
    }

    this.dialog.hide();
  }

}
