<h1>Feedback Admin</h1>

<div if.bind="feedback?.length===0">No feedback for the moment 😆</div>
<table else>
  <thead>
    <tr>
      <th scope="col">user</th>
      <th>subject</th>
      <th>feedback</th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr repeat.for="f of feedback">
      <td click.trigger="viewUser(f.userId)" class="clickable elipsis">${f.userId}</td>
      <td class="elipsis">${f.subject}</td>
      <td class="elipsis">${f.feedback}</td>
      <td>
        <sl-icon-button name="three-dots" click.trigger="Details(f)" title="Expand"></sl-icon-button>
      </td>
      <td>
        <sl-icon-button name="trash" click.trigger="Delete(f)" title="Delete"></sl-icon-button>
      </td>
    </tr>
  </tbody>
</table>
