import {
  menu_default
} from "../../chunks/chunk.OSVBAQAA.js";
import "../../chunks/chunk.J6DI62DI.js";
import "../../chunks/chunk.Z75LPS3C.js";
import "../../chunks/chunk.OWF334BP.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.MAD5PUM2.js";
export {
  menu_default as default
};
