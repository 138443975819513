import { bindable } from "aurelia";

import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";

import { GalleryService, IGalleryService } from '../../../../common/galleryService';
import { ILogService, LogService } from '../../../../common/logging/logService';
import { INotificationService, NotificationService } from '../../../../common/notificationService';
import { IGallery } from '../../../../common/interfaces/IGallery';

export class EditFilename {
  @bindable fileName: string;
  @bindable display: boolean;
  @bindable gallery: IGallery;

  public nameform: HTMLFormElement;
  public nameinput: HTMLInputElement;

  public dialog: SlDialog;
  public loading: boolean;

  public canRename = false;

  private newFileName: string;

  constructor(
    @IGalleryService private readonly galleryService: GalleryService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService,
  ) { }


  public attached() {
    this.nameinput.addEventListener('sl-input', event => {
      this.canRename = true;
    });

    this.dialog.addEventListener('sl-hide', event => {
      this.canRename = false;
    });
  }

  public displayChanged(): void {
    this.nameinput.setAttribute("value", "");
    this.dialog.show();
  }

  public async confirm() {
    const formData = serialize(this.nameform);
    this.newFileName = formData['filename'] as string;

    if (!this.validFileName(this.newFileName)) {
      this.notificationService.modal({
        title: `Invalid file name: "${this.newFileName}"`,
        message: "Can't  use a filename that already exists in this gallery.",
        type: "warning",
        date: new Date(),
        url: "",
        buttonPositiveText: "OK"
      });
      this.canRename = false;
      return;
    }

    this.loading = true;
    const renamed = await this.galleryService.renameImage(this.gallery.id, this.fileName, this.newFileName);
    this.loading = false;

    if (renamed) {
      this.logService.logEvent(`Gallery image renamed: ${this.gallery.id}, ${this.fileName}, ${this.newFileName}`);
    } else {
      this.notificationService.error({
        title: "Image rename failed",
        message: `Image was not renamed!
        Reason unknown - try again later. If the problem persists, contact support.`,
        type: "warning",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Failed to rename image: ${this.gallery.id}, ${this.fileName}, ${this.newFileName}`);
    }
    this.dialog.hide();
  }

  private validFileName(newFileName: string) {
    // check if the new file name already exists in the gallery
    const x = this.gallery.imageFiles.filter(name => {
      // check if newFilename equals the name without file extension
      // if it does, it means the file already exists in the gallery
      return newFileName === name.split('.')[0];
    });
    return x.length === 0;
  }
}