import { IRouteableComponent, Parameters } from "@aurelia/router";
import { IAdminService, AdminService } from "../../../../../common/adminService";
import { IAuthService, AuthService } from "../../../../../common/authService";
import { ILogService, LogService } from "../../../../../common/logging/logService";

export class FeedbackDetails implements IRouteableComponent {
  public feedback;

  constructor(
    @IAuthService private readonly authService: AuthService,
    @ILogService private readonly logService: LogService,
    @IAdminService private readonly adminService: AdminService,
  ) { }

  async canLoad() {
    // only load for admins
    if (!(this.authService.isAdmin)) {
      this.logService.logTrace("not admin - decline loading!");
      return "/profile-page";
    }
    return true;
  }

  async loading(parameters: Parameters) {
    this.feedback = parameters.feedback;
  }

}