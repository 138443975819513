import { IDisposable, IEventAggregator, EventAggregator, bindable, BindingMode } from "aurelia";
import { ICustomElementController } from "@aurelia/runtime-html";
import { watch } from '@aurelia/runtime-html';

import { EventType } from "../../../../common/eventType";
import { APIResult } from "../../../../common/network/apiResult";
import { GalleryService, IGalleryService } from "../../../../common/galleryService";

export class ImageUploader {
  @bindable({ mode: BindingMode.toView }) public galleryTitle: string;
  @bindable({ mode: BindingMode.toView }) public folderId: string;
  public fileCount = 0;
  public fileInput: HTMLInputElement;
  public totalSize = 0;
  public selectedFiles: Array<object> = [];

  public uploadedGallery: object = null;

  public readonly $controller: ICustomElementController<this>;

  private eventSubscriptions: IDisposable[] = new Array<IDisposable>();
  constructor(@IGalleryService private readonly galleryService: GalleryService, @IEventAggregator private eventAggregator: EventAggregator) { }

  public bound(): void {
    this.eventSubscriptions = this.setupEventHandlers();
    // bind to change event on the file input
    this.fileInput.addEventListener("change", () => this.fileSelectionChanged());
  }

  public attached(): void {
    window.addEventListener("keydown", this.keyEventHandler, true);
  }

  private setupEventHandlers(): IDisposable[] {
    const subs = new Array<IDisposable>();

    subs.push(this.eventAggregator.subscribe("fileUploadResponse", (gallery: APIResult) => this.galleryUploaded(gallery)));
    return subs;
  }

  public unbinding(): void {
    this.eventSubscriptions.forEach((e) => e.dispose());
    window.removeEventListener("keydown", this.keyEventHandler, true);
  }

  public openFilePicker(): void {
    this.clearState();

    // file input is hidden because of styling, opening it by triggering click()
    this.fileInput.click();
  }

  public submitEnabled = true;
  @watch("fileInput.files.length")
  public calculateSubmitEnabled(selection: number): void {
    this.submitEnabled = selection > 0;
  }

  public async upload(): Promise<void> {
    await this.galleryService.createGallery(this.galleryTitle, this.folderId, this.fileInput.files);
    this.close();
  }

  public fileSelectionChanged(): void {
    this.populateUI(this.fileInput);
  }

  public close(): void {
    // TODO: clear all component values
    this.clearState();

    const parentContext = this.$controller.scope.parent.bindingContext;
    parentContext["close"]();
  }

  private populateUI(fileInput: HTMLInputElement): void {
    for (let i = 0; i < fileInput.files.length; i++) {
      this.fileCount += 1;
      this.totalSize += fileInput.files[i].size;

      this.selectedFiles.push({
        file: fileInput.files[i],
        name: fileInput.files[i].name,
        size: this.returnFileSize(fileInput.files[i].size),
        url: URL.createObjectURL(fileInput.files[i]),
      });
    }
  }

  public returnFileSize(number): string {
    if (number < 1024) {
      return number + "bytes";
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + "KB";
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + "MB";
    }
  }

  public async newUpload(): Promise<void> {
    this.clearState();
    this.uploadedGallery = undefined;
  }

  private async galleryUploaded(galleryResponse: APIResult): Promise<void> {
    if (galleryResponse.ok) {
      this.uploadedGallery = galleryResponse.data as object;
      this.eventAggregator.publish("notificationStream" as EventType, `Gallery "${this.uploadedGallery["title"]} created"`);
      this.close();
    } else {
      this.eventAggregator.publish("uiNotificationError" as EventType, "Failed to create Gallery 😲");
    }
  }

  private clearState(): void {
    this.fileCount = 0;
    this.totalSize = 0;
    this.selectedFiles = [];
  }

  private fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
  ];

  private validFileType(file): boolean {
    return this.fileTypes.includes(file.type);
  }

  private keyEventHandler = (event): void => {
    if (event.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    switch (event.key) {
      case "Esc": // IE/Edge specific value
      case "Escape":
        this.close();
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }

    // Cancel the default action to avoid it being handled twice
    event.preventDefault();
  };
}
