<sl-dialog label="Edit Link" ref="dialog">
  <form ref="form" submit.trigger="save()">
    <sl-input name="title" label="Title" type="text" value.one-time="link.title" placeholder.one-time="link.titlePlaceholder" autocomplete="off"></sl-input>

    <br />

    <sl-input name="url" label="Url" type="text" value.one-time="link.url" placeholder.one-time="link.url" disabled="disabled" autocomplete="off"></sl-input>

    <br />

    <sl-button type="submit" class="pull-right" loadin.bind="loading">Save</sl-button>
  </form>
</sl-dialog>
