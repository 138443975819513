import { bindable } from "aurelia";

import { SlDialog, SlSelect } from "@shoelace-style/shoelace";
import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';

import { IGallery } from "../../../../common/interfaces/IGallery";
import { Link } from "../../../../common/interfaces/ILink";
import { IFolderService, FolderService } from "../../../../common/folderService";
import { IGalleryService, GalleryService } from "../../../../common/galleryService";
import { ILinkService, LinkService } from "../../../../common/linkService";
import { Folder } from "../../../../common/interfaces/IFolder";
import { INotificationService, NotificationService } from "../../../../common/notificationService";

export class CopyFiles {
  @bindable gallery: IGallery;
  @bindable link: Link;
  @bindable display: boolean;
  @bindable folder: Folder;
  @bindable selectedImages: string[];

  public dialog: SlDialog;
  public form: HTMLFormElement;
  public slselect: SlSelect;

  public galleries: IGallery[] = []
  public loading: boolean;
  galleriesLoading: boolean;

  public destinationSelect: SlSelect;

  public canCopy = false;

  constructor(
    @IFolderService private readonly folderService: FolderService,
    @IGalleryService private readonly galleryService: GalleryService,
    @ILinkService public readonly linkService: LinkService,
    @INotificationService private readonly notificationService: NotificationService,
  ) { }

  public attached() {
    // setup listener to change event, when a source gallery is selected
    // then we can load the galleries in the selected folder
    this.slselect.addEventListener('sl-change', event => {
      const selectedItem = (event.target as HTMLSelectElement).value;
      this.getGalleries(selectedItem);
    });

    // setup listener to change event, when a destination gallery is selected
    // then we can enable the copy button
    this.destinationSelect.addEventListener('sl-change', event => {
      const selectedItem = (event.target as HTMLSelectElement).value;
      if (selectedItem !== '') {
        this.canCopy = !this.loading && !this.galleriesLoading;
      }
    });

  }

  public displayChanged(): void {
    this.getGalleries(this.gallery.folderId);
    this.dialog.show();
  }

  private getGalleries(folderId: string): void {
    this.galleriesLoading = true;
    this.canCopy = false;

    this.galleryService.getGalleriesInFolder(folderId).then(galleries => {
      galleries.sort((a, b) => a.title.localeCompare(b.title));
      this.galleries.splice(0, this.galleries.length);
      this.galleries.push(...galleries);
      this.destinationSelect.value = this.galleries[0].id;
    }).finally(() => {
      this.galleriesLoading = false;
    });
  }

  public async save() {
    const formData = serialize(this.form);
    const destinationGalleryId = formData["destinationGalleryId"] as string;
    console.log("destinationGalleryId", destinationGalleryId);
    this.loading = true;
    const result = await this.galleryService.copyImages(this.gallery.id, destinationGalleryId, this.selectedImages);
    this.loading = false;
    if (result) {
      this.notificationService.info({
        title: "Files copied",
        message: `${this.selectedImages.length} files were copied.`,
        type: "info",
        date: new Date(),
        url: `gallery(${destinationGalleryId})`
      });
    }
    else {
      this.notificationService.warning("Failed to copy images 😢");
      this.notificationService.info({
        title: "File copy failed",
        message: `Failed to copy images, reason unknown. Try again and if the problem persist contact support.`,
        type: "warning",
        date: new Date(),
        url: ""
      });
    }
    this.dialog.hide();
  }

}