<h1>Privacy Policy</h1>
<strong>
  Effective Date: 2024-03-18
</strong>

<h2>1. Introduction</h2>
<p>Welcome to PixHeap! This Privacy Policy explains how we collect, use, and protect your personal information. By using
  our service, you agree to the terms outlined here.</p>

<h2>2. Information We Collect</h2>
<p>We collect the following types of personal information:
  <br>
  User-Provided Data: Information you voluntarily provide during registration, usage, or communication.
  <br>
  Automatically Collected Data: Details gathered through cookies, analytics, and other tracking technologies.
</p>

<h2>3. How We Use Your Information</h2>
<p>We use your data for the following purposes:
  <br>
  Service Delivery: To provide our SaaS services and improve user experience.
  <br>
  Communication: To respond to inquiries and keep you informed.
  <br>
  Analytics: To analyze usage patterns and enhance our offerings.
</p>

<h2>4. Data Sharing</h2>
<p>We do not sell or rent your personal information. However, we may share it with:
  <br>
  Service Providers: Third-party partners who assist us in delivering our services.
  <br>
  Legal Compliance: When required by law or to protect our rights.
</p>

<h2>5. Data Security</h2>
<p>We take security seriously. Your data is safeguarded through encryption, access controls, and regular assessments.
</p>

<h2>6. Your Rights</h2>
<p>You have the right to:
  <br>
  Access: Request details about the data we hold.
  <br>
  Rectify: Correct any inaccuracies in your information.
  <br>
  Delete: Request deletion of your data.
</p>

<h2>7. Changes to this Policy</h2>
<p>We may update this Privacy Policy. Check our website for the latest version.</p>

<h2>8. Contact Us</h2>
<p>If you have any questions or concerns, please reach out to us at pixheap( at)hotmail[dot ]com.</p>