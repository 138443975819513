import { HttpClientConfiguration, json, DI } from "aurelia";
import { OutgoingHttpHeaders } from "http";

import { AuthService, IAuthService } from "../authService";
import { ConfigService, IConfigService } from "../configService";

import { Constants } from "../constants";
import { RouteDescription } from "./routeDescription";
import { ResourceType } from "./resourceType";

export class RequestBuilder {
  private routes: RouteDescription[];

  constructor(@IAuthService private auth: AuthService, @IConfigService private configService: ConfigService) {
    this.routes = configService.getProperty("routes") as RouteDescription[];
  }

  public async getConfiguration(resourceType: ResourceType): Promise<HttpClientConfiguration> {
    const route: RouteDescription = this.getRouteFromConfig(resourceType);

    const jsonHeaders: OutgoingHttpHeaders = {
      Accept: Constants.APPLICATION_JSON,
      "X-Requested-With": Constants.FETCH,
    };

    const zipHeaders: OutgoingHttpHeaders = {
      Accept: Constants.APPLICATION_ZIP,
      "X-Requested-With": Constants.FETCH,
    };

    const streamHeaders: OutgoingHttpHeaders = {
      Accept: Constants.APPLICATION_STREAM,
      "X-Requested-With": Constants.FETCH,
    };

    const headers = (resourceType === "downloadGallery") ? streamHeaders : jsonHeaders;

    if (route.authorize) {
      const token = await this.auth.accessToken;
      // const token = this.auth.idToken;

      if (!token) {
        throw new Error("RequestBuilder - Could not get token!");
      }

      headers.Authorization = `Bearer ${token}`;
    }

    const conf = new HttpClientConfiguration();
    conf.withBaseUrl(this.configService.getProperty(Constants.BASE_API_URL) as string);

    const creds = "same-origin";
    const heads = headers;
    conf.withDefaults({
      //TODO: fix typings hack
      credentials: creds as RequestCredentials,
      headers: heads as unknown as Headers,
    });

    return conf;
  }

  public getRoute(resourceType: ResourceType, data?: object): string {
    const routeDescription: RouteDescription = this.getRouteFromConfig(resourceType);
    let route = routeDescription.route;

    if (route.indexOf("{") === -1) {
      return route;
    }

    const fragments = route.split("/");
    if (fragments.length === 0) {
      return route;
    }

    for (const fragment of fragments) {
      if (fragment.indexOf("{") !== -1) {
        route = this.insertParameterInRoute(fragment.replace("{", "").replace("}", ""), route, data);
      }
    }

    return route;
  }

  public buildRequestInit(resourceType: ResourceType, data?: object): RequestInit {
    const routeDescription: RouteDescription = this.getRouteFromConfig(resourceType);

    const req: RequestInit = {
      method: routeDescription.method,
    };

    if (!routeDescription.payload) {
      return req;
    }

    if (routeDescription.payload.body) {
      if (routeDescription.payload.bodyFormat === "json") {
        req.body = json(data);
      } else if (routeDescription.payload.bodyFormat === "formData") {
        req.body = data as FormData;
      }
    }

    return req;
  }

  /// Get the route configuration from config.
  /// Throw if the route is not defined in config!
  private getRouteFromConfig(resource: ResourceType): RouteDescription {
    const routeDescription: RouteDescription = this.routes.filter((r) => r.id === resource)[0];

    if (!routeDescription) {
      throw new Error(`No Route defined for the ResourceType: ${resource}`);
    }
    return routeDescription;
  }

  private insertParameterInRoute(element: string, routeString: string, data: object): string {
    return routeString.replace(`{${element}}`, data[element]);
  }
}

export const IRequestBuilder = DI.createInterface<RequestBuilder>("IRequestBuilder", (x) => x.singleton(RequestBuilder));
