import Aurelia from "aurelia";
import { IRouter, Router } from "@aurelia/router";

import { AdminService, IAdminService } from "../../../../common/adminService";
import { AuthService, IAuthService } from "../../../../common/authService";
import { ConfigService, IConfigService } from "../../../../common/configService";
import { DataService, IDataService } from "../../../../common/network/dataService";
import { GalleryService, IGalleryService } from "../../../../common/galleryService";
import { NotificationService, INotificationService } from "../../../../common/notificationService";

export class Home {
  public message = "Welcome to PicsHeap";
  public showContent = false;
  public latestGalleryPreviews: any[] = [];
  public imageLoc: string;

  public grid: HTMLElement;
  public preRenderOk = false;

  constructor(
    @IAdminService public readonly adminService: AdminService,
    @IAuthService private readonly authService: AuthService,
    @IConfigService private readonly configService: ConfigService,
    @IDataService private readonly dataservice: DataService,
    @IGalleryService private readonly galleryService: GalleryService,
    @INotificationService private readonly notificationService: NotificationService,
    @IRouter private readonly router: Router,
    // private readonly au = resolve(Aurelia)
  ) {
    this.imageLoc = this.configService.getProperty("imageLocation") as string;
    this.fetchPreRenderedThumbGrid();
  }

  public detaching(): void {
    if (this.getPreGeneratedThumbGrid() !== null) {
      this.getPreGeneratedThumbGrid().remove();
    }
  }

  public async thumbClicked(event: PointerEvent) {
    if (this.router.isNavigating) return;

    let element = event.target as HTMLElement;
    // element can be img or the div it lives in, one of them has a data-id attribute
    let dataId = element?.getAttribute('data-id');
    if (dataId === null) {
      element = element.parentElement;
      dataId = element?.getAttribute('data-id');
    }

    // defering this cvall 5s so it doens't collide with call from external-link page, which seems to be problematic
    setTimeout(() => { this.galleryService.galleryPreviewClicked(dataId).then(); }, 5000);
    await this.router.load("external-link", { parameters: { previewId: dataId } });
  }

  public handleKeyInput(event: KeyboardEvent): boolean {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    let handled = false;
    if (event.key !== undefined) {
      // Handle the event with KeyboardEvent.key and set handled true.
      if (event.key === "Enter") {
        this.thumbClicked(event as unknown as PointerEvent);
        handled = true;
      }
    }

    if (handled) {
      // Suppress "double action" if event handled
      event.preventDefault();
    }
  }

  private getPreGeneratedThumbGrid(): Element {
    const element = document.getElementById("tmp");
    return element;
  }


  private async fetchPreRenderedThumbGrid(): Promise<void> {
    const au = new Aurelia();
    const fragmentResponse = await fetch('fragment.html');

    if (!fragmentResponse.ok) {
      this.galleryService.initGalleryPreviewAsync();
      this.latestGalleryPreviews = this.galleryService.latestGalleryPreviews;
      return;
    }

    const fragment = await fragmentResponse.text();
    // check if the first element of the fragment is a div with class=t
    if (fragment.startsWith('<div class="t"')) {
      this.preRenderOk = true;

      this.grid.innerHTML = fragment;
      // enhance the grid
      au.enhance({
        component: {
          thumbClicked: ($event) => this.thumbClicked($event),
          handleKeyInput: ($event) => this.handleKeyInput($event),
        },
        host: this.grid,
      }
      );
    }
    else {
      this.galleryService.initGalleryPreviewAsync();
      this.latestGalleryPreviews = this.galleryService.latestGalleryPreviews;
    }
  }



}
