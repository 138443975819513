<!-- sort by created -->
<div class="pulse" click.trigger="createdClicked()" tabindex="0">
  <div if.bind="filters.created" class="on" title="Newest first">
    <sl-icon name="hourglass-top"></sl-icon>
  </div>
  <div if.bind="filters.createdDesc" class="on" title="Oldest first">
    <sl-icon name="hourglass-bottom"></sl-icon>
  </div>
  <div if.bind="!filters.created && !filters.createdDesc" class="dim" title="Order by create date">
    <sl-icon name="hourglass-top"></sl-icon>
  </div>
</div>

<!-- sort by title -->
<div class="pulse" click.trigger="titleClicked()" tabindex="0">
  <div if.bind="filters.title" class="on" title="Ordered by title">
    <sl-icon name="sort-alpha-down"></sl-icon>
  </div>
  <div if.bind="filters.titleDesc" class="on" title="Ordered by title desc">
    <sl-icon name="sort-alpha-up-alt"></sl-icon>
  </div>
  <div if.bind="!filters.title && !filters.titleDesc" class="dim" title="Order by title">
    <sl-icon name="sort-alpha-down"></sl-icon>
  </div>
</div>
