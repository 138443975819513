export class Constants {
  public static AB2C_AUTH_STATE = "mm:a2b2c:state";
  public static AB2C_AUTH_STATUS = "mm:a2b2c:auth_status";
  public static AB2C_AUTH_ERROR = "mm:a2b2c:auth_error";
  public static AB2C_AUTH_ERROR_DESCRIPTION = "mm:a2b2c:auth_error_description";
  public static AB2C_REFRESHING_TOKEN = "mm:a2b2c:refreshing_token";

  public static AB2C_CODE = "mm:a2b2c:code";
  public static AB2C_ACCESS_TOKEN = "mm:a2b2c:access_token";
  public static AB2C_TOKEN_EXPIRATION = "mm:a2b2c:token_expiration";
  public static AB2C_ID_TOKEN = "mm:a2b2c:id_token";

  public static CONFIG_SCOPE_NAME = "b2cClientScope";

  public static APP_NAME = "appName";
  public static APPLICATION_JSON = "application/json";
  public static APPLICATION_ZIP = "application/zip";
  public static APPLICATION_STREAM = "application/octet-stream";
  public static BASE_API_URL = "baseAPIUrl";
  public static CALLBACK_URI = "callbackUri";
  public static FETCH = "Fetch";
  public static LOGOUT_URI = "logoutUri";
  public static ROUTES = "routes";
  public static SAME_ORIGIN = "same-origin";
}
