<h1>DMCA Takedown Request</h1>
<strong>Dear Copyright Holder,</strong>

<p>
  We respect intellectual property rights and take copyright infringement seriously. If you believe that your
  copyrighted
  work has been used without authorization on our website, please follow the steps below to request a takedown:
</p>

<ol>
  <li>
    1. Identification: Provide clear identification of the copyrighted work you believe has been infringed. Include
    relevant details such as the title, URL, and a brief description.
  </li>
  <li>
    2. Contact Information: Send your takedown request via email to pixheap(at)hotmail.com.
  </li>
  <li>
    3. Statement: In your email, include a statement affirming that you are the copyright owner or authorized to
    act on behalf of the copyright owner.
  </li>
  <li>
    4. Proof of Ownership: Attach relevant documentation that proves your ownership of the copyrighted material.
  </li>
  <li>
    5. Good Faith Belief: State that you have a good faith belief that the use of the material is not authorized by the
    copyright owner, its agent, or the law.
  </li>
  <li>
    6. Accuracy: Confirm that the information provided in your request is accurate and complete.
  </li>
</ol>

<p>
  We will promptly review your request and take appropriate action. Thank you for helping us maintain a respectful and
  lawful online environment.
</p>
<p>
  Sincerely,
  PixHeap
</p>