import { IEventAggregator } from "aurelia";
import { IRouter, Router } from "@aurelia/router";

import { AuthService, IAuthService } from "./common/authService";
import { EventType } from "./common/eventType";
import { LogService, ILogService } from "./common/logging/logService";
import { UINotification } from "./common/uiNotification";
import { IConfigService, ConfigService } from "./common/configService";
import { IEventStreamService, EventStreamService } from "./common/eventStreamService";
import { IDataService, DataService } from "./common/network/dataService";

export class MainApp {
  public static title = "My Product";

  public dialog: HTMLElement;
  public dialogcontent: string;
  public notification: UINotification;

  constructor(
    // NOTICE: Do not remove configService, dataServie and eventStreamService 
    // - they are used to boot up parts of the system using DI starting them up!!!
    @IAuthService private readonly authService: AuthService,
    @IConfigService private readonly configService: ConfigService,
    @IDataService private readonly dataService: DataService,
    @IEventAggregator private readonly eventAggregator: IEventAggregator,
    @IEventStreamService private readonly eventStreamService: EventStreamService,
    @ILogService private readonly logService: LogService,
    @IRouter private readonly router: Router
  ) { }

  public async binding(): Promise<void> {
    this.logService.init();

    this.eventAggregator.subscribe("uiNotificationError" as EventType, (message) => {
      alert(message);
    });

    this.eventAggregator.subscribe("uiNotificationModal" as EventType, (notification: UINotification) => {
      this.notification = notification;
      (this.dialog as HTMLDialogElement).showModal();
    });

    await this.authService.logInSilent();
  }

  public attached(): void {
    // check if 18+ and agree to cookie use
    this.checkCookieForAgeAndCookies();
  }

  private checkCookieForAgeAndCookies() {
    const cookieName = "ageAndCookieAgree";
    const cookie = document.cookie.split(";").find((c) => c.includes(cookieName));
    if (!cookie) {
      //blur the background
      document.body.style.filter = "blur(15px)";
      // show cookie dialog
      this.notification = {
        type: "modal",
        date: new Date(),
        url: null,
        title: "Cookies & Age Verification",
        message: "Welcome to PixHeap!<br>To proceed, we need to verify that you are over 18 years old and obtain your consent for using cookies.<br>Please confirm that you are 18 years or older.",
        buttonPositiveText: "18+ & agree",
        buttonNegativeText: "Not 18+ / disagree",
        buttonPositiveCallback: () => {
          this.setCookie(cookieName, "true");
          //unblur the background
          document.body.style.filter = "";
        },
        buttonNegativeCallback: () => {
          window.location.href = "https://www.youtube.com/channel/UC5PYHgAzJ1wLEidB58SK6Xw";
        }
      };
      (this.dialog as HTMLDialogElement).showModal();
    }
  }

  private setCookie(name, value, days?) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  public negativeBtnClick(): void {
    (this.dialog as HTMLDialogElement).close();
    if (this.notification.buttonNegativeCallback) {
      this.notification.buttonNegativeCallback();
    }
  }

  public positiveBtnClick(): void {
    (this.dialog as HTMLDialogElement).close();

    if (this.notification.url) {
      this.router.load(this.notification.url);
      return;
    }

    if (this.notification.buttonPositiveCallback) {
      this.notification.buttonPositiveCallback();
    }
  }

}
