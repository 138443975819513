export * from '@shoelace-style/shoelace/dist/themes/light.css';

export * from '@shoelace-style/shoelace/dist/components/badge/badge';
export * from '@shoelace-style/shoelace/dist/components/button/button';
export * from '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
export * from '@shoelace-style/shoelace/dist/components/dropdown/dropdown';
export * from '@shoelace-style/shoelace/dist/components/dialog/dialog';
export * from '@shoelace-style/shoelace/dist/components/divider/divider';
export * from '@shoelace-style/shoelace/dist/components/icon/icon';
export * from '@shoelace-style/shoelace/dist/components/icon-button/icon-button';
export * from '@shoelace-style/shoelace/dist/components/input/input';
export * from '@shoelace-style/shoelace/dist/components/menu-item/menu-item';
export * from '@shoelace-style/shoelace/dist/components/menu/menu';
export * from '@shoelace-style/shoelace/dist/components/option/option';
export * from '@shoelace-style/shoelace/dist/components/rating/rating';
export * from '@shoelace-style/shoelace/dist/components/select/select';
export * from '@shoelace-style/shoelace/dist/components/spinner/spinner';
export * from '@shoelace-style/shoelace/dist/components/textarea/textarea';
export * from '@shoelace-style/shoelace/dist/components/tooltip/tooltip';

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

// Set the base path to the folder you copied Shoelace's assets to
setBasePath('../../resources/shoelace/');