export class MemoryUnitValueConverter {
  public toView(size: number, conversion: string) {
    //if the conversion is not specified, make a algorithm that converts the size to the biggest unit possible with a value greater than 1 and using two decimals
    if (!conversion) {
      if (size > 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} TB`;
      } else if (size > 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} GB`;
      } else if (size > 1024) {
        return `${(size / 1024).toFixed(2)} MB`;
      } else {
        return `${size} KB`;
      }
    }

    if (conversion === 'kb-to-mb') {
      return `${(Math.ceil(size / (1024)))} MB`;
    } else if (conversion === 'kb-to-gb') {
      return `${(Math.ceil(size / (1024 * 1024)))} GB`;
    } else if (conversion === 'b-to-gb') {
      return `${(Math.ceil(size / (1024 * 1024 * 1024)))} GB`;
    } else if (conversion === 'b-to-mb') {
      return `${(Math.ceil(size / (1024 * 1024)))} MB`;
    }

    return '???';
  }
}