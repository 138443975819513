import { DI, EventAggregator, IEventAggregator } from "aurelia";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { AuthService, IAuthService } from "./authService";
import { ConfigService, IConfigService } from "./configService";
import { EventType } from "./eventType";
import { GalleryService, IGalleryService } from "./galleryService";
import { IGallery } from "./interfaces/IGallery";
import { Link } from "./interfaces/ILink";
import { LinkService, ILinkService } from "./linkService";
import { LogService, ILogService } from "./logging/logService";
import { NotificationService, INotificationService } from "./notificationService";
import { SignalRMessageData } from "./interfaces/SignalRMessageData";

export class EventStreamService {
  private connection: HubConnection;
  private SignalRLinkEventsChannel = "linkStatus";
  private SignalRGalleryEventsChannel = "galleryStatus";
  private SignalRSystemEventChannel = "system-event";

  constructor(
    @IAuthService private readonly authService: AuthService,
    @IConfigService private readonly configService: ConfigService,
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @ILinkService private readonly linkService: LinkService,
    @IGalleryService private readonly galleryService: GalleryService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService
  ) {
    this.eventAggregator.subscribe("hydrateApp" as EventType, () => this.initRealTimeService());

  }

  public async initRealTimeService(): Promise<void> {
    this.logService.logTrace("RealTimeServices - Starting");
    const options = {
      accessTokenFactory: async (): Promise<string> => await this.authService.accessToken,
    };

    this.connection = new HubConnectionBuilder()
      .withUrl(this.configService.getProperty("signalREndpoint") as string, options)
      .withAutomaticReconnect()
      // .configureLogging(LogLevel.Debug)
      .configureLogging(LogLevel.Error)
      .build();

    this.connection.on(this.SignalRLinkEventsChannel, (message: SignalRMessageData) => {
      this.logService.logEvent("RealTimeServices - Received linkStatus event");
      if (!message) return;

      let linkToBeUpdated: Link;
      for (const folder of this.linkService.folders) {
        linkToBeUpdated = folder.links?.find((l) => l.id === (message.payload as Link).id);
        if (linkToBeUpdated) {
          break;
        }
      }

      if (!linkToBeUpdated) return;
      if (!linkToBeUpdated.givenTitle && !linkToBeUpdated.title) {
        linkToBeUpdated.title = (message.payload as Link).title;
      }
      linkToBeUpdated.status = (message.payload as Link).status;
    });

    this.connection.on(this.SignalRGalleryEventsChannel, (message: SignalRMessageData) => {
      this.logService.logTrace("RealTimeServices - Received galleryStatus event");
      if (!message) return;

      const gallery = (message.payload as IGallery)
      this.galleryService.addGallery(gallery);

      if (message.type === "gallery-updated") {
        this.notificationService.info(
          {
            title: "Gallery updated",
            message: "Gallery was updated: <em>" + gallery.title + "</em>",
            type: "info",
            date: new Date(),
            url: `gallery(${gallery.id})`
          });
      }
      else if (message.type === "gallery-download-finished") {
        this.notificationService.info(
          {
            title: "Gallery created",
            message: "Gallery creation finished: <em>" + gallery.title + "</em>",
            type: "info",
            date: new Date(),
            url: `gallery(${gallery.id})`
          });
      }
    });

    this.connection.on(this.SignalRSystemEventChannel, (messageData: SignalRMessageData) => {
      if (!messageData) return;
      alert("System event received");
      this.logService.logEvent("RealTimeServices - Received system event");

      if (messageData.type.includes("info")) {
        this.notificationService.error({
          title: "Info",
          message: messageData.message,
          type: "info",
          date: new Date(),
          url: ""
        });
      }

      if (messageData.type.includes("error")) {
        this.notificationService.error({
          title: "Error",
          message: messageData.message,
          type: "error",
          date: new Date(),
          url: ""
        });
      }
    });

    this.connection.onclose(() => this.logService.logTrace("Event stream disconnected!"));

    this.connection.start().then(() => {
      this.logService.logTrace("RealTimeServices - Started");
    });
  }
}

export const IEventStreamService = DI.createInterface<EventStreamService>("IEventStreamService", (x) => x.singleton(EventStreamService));
