import { EventAggregator, IEventAggregator } from "aurelia";

import { AuthService, IAuthService } from "../../../../../common/authService";
import { EventType } from "../../../../../common/eventType";
import { IConfigService, ConfigService } from "../../../../../common/configService";

export class PricePage {

  public stripe: HTMLElement;

  constructor(@IAuthService public readonly authService: AuthService, @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IConfigService private readonly configService: ConfigService) {
    this.eventAggregator.subscribe("hydrateApp" as EventType, () => this.setAttributesOnPricingTable());
  }

  public bound() {
    this.setAttributesOnPricingTable();
  }

  private setAttributesOnPricingTable() {
    if (this.authService.isLoggedIn) {
      this.stripe.setAttribute("client-reference-id", this.authService.user.id);
      this.stripe.setAttribute("customer-email", this.authService.user.email);
    }

    this.stripe.setAttribute("pricing-table-id", this.configService.getProperty('stripe.pricingTableId') as string);
    this.stripe.setAttribute("publishable-key", this.configService.getProperty('stripe.publishableKey') as string);
  }
}