<sl-dialog label="Delete Folder" ref="confirmDialog">
  <form ref="confirmForm">
    <h2>Really sure?</h2>
    <br />

    <p>Are you sure you want to delete the folder <strong>${folder.name}</strong> and all it's content?</p>
    <br />

    <sl-button class="pull-right" click.trigger="deleteConfirmed()" loading.bind="loading">Yes</sl-button>
  </form>
</sl-dialog>
