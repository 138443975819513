import { ApplicationInsights } from "@microsoft/applicationinsights-web";
const isDebug = window.location.hostname.includes("localhost");

// decorator factory, taking the event name as argument
export const LogEvent = (eventName: string): Function => {
  // the decorator function
  return function (target: Record<string, unknown>, methodName: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]): Promise<void> {
      // apply logging logic
      (window.AppInsights as ApplicationInsights).trackEvent({ name: eventName });
      if (isDebug) console.log(`Event logged: ${eventName}`);

      // call original method
      await originalMethod.apply(this, args);
    };
    return descriptor;
  };
};
