export class Link {
  id: string;
  createDate: string;
  folderId: string;
  url: string;
  status: number;
  processedDate: string;
  title: string;
  givenTitle: string;
  // images: number;
  // foundImages: number;
  // imageFiles: string[];
  // videos: number;
  // views: number;

  // name: string;
  // performers: string[];
  // categories: string[];
}
