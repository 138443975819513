import { AdminService, IAdminService } from "../../../../common/adminService";
import { AuthService, IAuthService } from "../../../../common/authService";
import { ILogService, LogService } from "../../../../common/logging/logService";

export class AdminStatistics {
  public statistics: SystemStatistics;
  public monthlyGalleries: DateCount[] = [];
  public monthlyLinks: DateCount[] = [];
  public monthlySubscriptions: DateCount[] = [];
  public monthlyUsers: DateCount[] = [];
  public weeklyGalleries: DateCount[] = [];
  public weeklyLinks: DateCount[] = [];
  public weeklySubscriptions: DateCount[] = [];
  public weeklyUsers: DateCount[] = [];

  constructor(@IAuthService private readonly authService: AuthService,
    @ILogService private readonly logService: LogService,
    @IAdminService private readonly adminService: AdminService
  ) { }

  async canLoad() {
    if (!(this.authService.isAdmin)) {
      this.logService.logTrace("not admin - decline loading!");
      return "/profile-page";
    }

    this.statistics = await this.adminService.adminStatisticsData();
    if (JSON.stringify(this.statistics) !== "{}")
      this.setupGraphData();

    return true;
  }

  async setupGraphData() {
    this.pushStatisticsDataToGraphData(this.statistics.newUsersLastTwoMonths, this.weeklyUsers, this.monthlyUsers);
    this.pushStatisticsDataToGraphData(this.statistics.newLinksLastTwoMonths, this.weeklyLinks, this.monthlyLinks);
    this.pushStatisticsDataToGraphData(this.statistics.newGalleriesLastTwoMonths, this.weeklyGalleries, this.monthlyGalleries);
    this.pushStatisticsDataToGraphData(this.statistics.payingUsersAddedLastTwoMonths, this.weeklySubscriptions, this.monthlySubscriptions);
  }

  private pushStatisticsDataToGraphData(rawData: DateCount[], weekData: DateCount[], monthData: DateCount[]) {
    const dateRangeLastMonth: Date[] = this.createArrayOfDaysDuringPrevoiusMonth();
    const dateRangePrevoiusWeek: Date[] = this.createArrayOfDaysDuringWeek(1);

    dateRangePrevoiusWeek.forEach((date) => {
      const hit = rawData.find((a) => new Date(a.date).toISOString().substring(0, 10) === date.toISOString().substring(0, 10));
      const count = hit ? hit.count : 0;
      weekData.push({ date: date, count: count });
    });

    dateRangeLastMonth.forEach((date) => {
      const hit = rawData.find((a) => new Date(a.date).toISOString().substring(0, 10) === date.toISOString().substring(0, 10));
      const count = hit ? hit.count : 0;
      monthData.push({ date: date, count: count });
    });
  }

  private createArrayOfDaysDuringWeek(weeksBack: number): Date[] {
    const today = new Date();
    const prevWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 * weeksBack + 1);
    const prevWeekEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1 * weeksBack + 1);

    const prevWeekDates = [];

    for (let d = new Date(prevWeekStartDate); d <= prevWeekEndDate; d.setDate(d.getDate() + 1)) {
      prevWeekDates.push(new Date(d));
    }
    return prevWeekDates;
  }

  private createArrayOfDaysDuringPrevoiusMonth(): Date[] {
    // Get current date
    const currentDate = new Date();

    // Calculate first day of previous month
    const firstDayPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    // Calculate last day of previous month
    const lastDayPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    // Create empty array for dates
    const datesArray: Date[] = [];

    // Loop through each date and add it to the array
    for (let i = firstDayPrevMonth.getDate(); i <= lastDayPrevMonth.getDate(); i++) {
      const date = new Date(firstDayPrevMonth.getFullYear(), firstDayPrevMonth.getMonth(), i);
      datesArray.push(date);
    }

    return datesArray;
  }

  private dateToShortDateString(date: Date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

}