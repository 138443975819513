import { IRouter, Router } from "@aurelia/router";
import { AdminService, IAdminService } from "../../../../../common/adminService";

export class AdminFeedback {

  public feedback = [];

  constructor(@IAdminService private readonly adminService: AdminService, @IRouter private readonly router: Router) {
  }

  async loading() {
    const res = await this.adminService.getFeedback();
    if (Array.isArray(res)) {
      this.feedback.push(...res);
    }
  }

  public async viewUser(userId: number) {
    await this.router.load('/user-profile', {
      title: 'Admin - User Profile',
      parameters: {
        userId: userId
      }
    });
  }

  public async Delete(feedback) {
    const result = await this.adminService.deleteFeedback(feedback);
    if (result) {
      const index = this.feedback.indexOf(feedback, 0);
      if (index > -1) {
        this.feedback.splice(index, 1);
      }
    }
  }

  public async Details(feedback) {
    await this.router.load('/feedback-details', {
      title: 'Admin - Feedback Details',
      parameters: {
        feedback: feedback
      }
    });
  }

}