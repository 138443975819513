import { CustomElement } from "aurelia";
import { watch } from '@aurelia/runtime-html';
import dragula from "dragula";

import { FolderService } from "./../../common/folderService";
import { ILinkService, LinkService } from "../../common/linkService";
import { IFolderService } from "../../common/folderService";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { ISubscriptionService, SubscriptionService } from "../../common/subscriptionService";

import { EventType } from "../../common/eventType";

export class LinkPage {
  public linkcontainer: Element;
  public latestFolder: Element;
  public urlinput: HTMLInputElement;
  public addlink: HTMLElement;

  public filter: string;

  constructor(@ILinkService public readonly linkService: LinkService,
    @IFolderService public readonly folderService: FolderService,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService) { }

  @watch("filter")
  public filterFunc(newVal: string, oldVal: string): void {
    this.folderService.sortActiveFolder(this.filter, "link");
  }

  public addLink() {
    this.urlinput.setAttribute("value", "");
    (this.addlink as SlDialog).show();
  }

  private initDragula(root: Element): void {
    const containers = root.querySelectorAll("*");
    const folders: Array<Element> = [];
    // TODO: folders are the ul's but shouldn't they be the child, the folder element instead?
    containers.forEach((x) => {
      if (x.nodeName === "UL") folders.push(x);
    });

    dragula([...folders], {
      // accepts: function (el) {
      //   return el.id !== "root";
      // },
      accepts: (el, target, source, sibling) => {
        //target can not be the root element
        const isNotRoot = target.id !== "root";

        //should not be dropped above a folder
        const isAboveFolder = sibling?.nodeName === "FOLDER-COMPONENT";

        return isNotRoot && !isAboveFolder;
      },
    })
      .on("drag", (el) => {
        console.log("drag");
        console.log(el);
      })
      .on("drop", async (el) => {
        console.log("dropping in:");
        // the scope here is window
        const linkId = CustomElement.for(el.firstElementChild).scope.bindingContext["link"].id;
        const folderId = CustomElement.for(this.latestFolder.firstElementChild).scope.bindingContext["folder"].id;
        const x = CustomElement.for(this.latestFolder.firstElementChild).scope;
        // get the parent of the parent of the folder component, ie this page, and get the linkService (what is the parent?)
        const linkService = x.parent.parent.bindingContext["linkService"];
        await linkService.addLinkToFolder(linkId, folderId);
      })
      .on("over", (el, container) => {
        console.log("over");
        this.latestFolder = container;
      })
      .on("out", (el, container) => {
        console.log("out");
        console.log(el);
        console.log(container);
      });
  }
}
