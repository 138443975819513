import { processingStatus } from "./processingStatus";

export class IGallery {
  id: string;
  creatorId: string;
  createDate: string;
  editDate: string;
  folderId: string;
  info: string;
  title: string;
  thumbnails: string[];
  imageFiles: string[];
  views: number;
  status: processingStatus;
}

