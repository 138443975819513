import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";

import { IRouter, Router } from "@aurelia/router";

import { IAuthService, AuthService } from "../../../common/authService";
import { IFolderService, FolderService } from "../../../common/folderService";
import { IGalleryService, GalleryService } from "../../../common/galleryService";
import { ILinkService, LinkService } from "../../../common/linkService";
import { INotificationService, NotificationService } from "../../../common/notificationService";
import { ISubscriptionService, SubscriptionService } from "../../../common/subscriptionService";
import { Link } from "../../../common/interfaces/ILink";

export class LinkDetails {
  public noticesubscription: HTMLElement;

  public link: Link;
  public folder;

  constructor(
    @IAuthService private readonly authService: AuthService,
    @IFolderService private readonly folderService: FolderService,
    @IGalleryService private readonly galleryService: GalleryService,
    @ILinkService private readonly linkService: LinkService,
    @INotificationService private readonly notificationService: NotificationService,
    @IRouter private readonly router: Router,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService,
  ) { }

  public async canLoad(params): Promise<boolean | string> {
    // if (await !this.subscriptionService.canViewGalleries()) {
    //   const notification: UINotification = {
    //     title: "Subscription required",
    //     message: "You need a valid subscription to view galleries.",
    //     type: "error",
    //     date: new Date(),
    //     url: "price-page",
    //   };
    //   this.notificationService.modal(notification);

    //   return "link-page";
    // }

    this.link = await this.linkService.getLink(params[0]);
    this.folder = await this.folderService.getFolder(this.link.folderId, "link");
    return true;
  }

  public async createGallery() {
    if (this.authService.isLoggedIn === false) {
      this.authService.logIn();
      return;
    }

    if (!this.subscriptionService.canCreateGalleries) {
      (this.noticesubscription as SlDialog).show();
      return;
    }

    // save button clicked
    this.galleryService.createGalleryFromURL(this.link.url);
  }

  public async loadPricePage() {
    await this.router.load("/price-page");
  }

}