{
  "environmentDefinitions": [
    {
      "environmentName": "development",
      "hosts": [
        "127.0.0.1",
        "localhost"
      ]
    },
    {
      "environmentName": "staging",
      "hosts": [
        "webstorageweustag.z6.web.core.windows.net",
        "stg.pixheap.com"
      ]
    },
    {
      "environmentName": "prod",
      "hosts": [
        "webstorageweuprod.z6.web.core.windows.net",
        "pixheap.com"
      ]
    }
  ],
  "environments": [
    {
      "name": "development",
      "properties": {
        "imageLocation": "https://filestorageweustag.blob.core.windows.net",
        "signalREndpoint": "http://localhost:7297/api",
        "baseAPIUrl": "http://localhost:7297/api/v1/",
        "azureAD": {
          "authority": "https://pixheap.b2clogin.com/pixheap.onmicrosoft.com/b2c_1_susi",
          "callbackUri": "https://localhost:9000/",
          "clientId": "99cec94a-9ed6-4615-ae19-d1ab713f70ff",
          "knownAuthorities": [
            "pixheap.b2clogin.com"
          ],
          "scopes": [
            "openid",
            "email",
            "https://pixheap.onmicrosoft.com/99cec94a-9ed6-4615-ae19-d1ab713f70ff/web.read"
          ]
        },
        "appInsights": {
          "instrumentationKey": "662ea7f0-ff72-45d5-b390-f504b3099038",
          "autoRouteTracking": true,
          "logToConsole": false
        },
        "stripe": {
          "pricingTableId": "prctbl_1PeMzTLF3poMMSQ4Q3vi89An",
          "publishableKey": "pk_test_51N8WhgLF3poMMSQ4IJzxYonDXMqTdQ61uIrl6Y9OURfswfGgsY5vGDkuHjSVcnyFZ39QktYM9z9mDOeBvlug7yOr00Ib55R1wF",
          "managementLink": "https://billing.stripe.com/p/login/test_3csg29b0Qfbl33q144"
        }
      }
    },
    {
      "name": "staging",
      "properties": {
        "imageLocation": "https://f-stg.pixheap.com",
        "signalREndpoint": "https://publicapiweustag.azurewebsites.net/api",
        "baseAPIUrl": "https://publicapiweustag.azurewebsites.net/api/v1/",
        "azureAD": {
          "authority": "https://pixheap.b2clogin.com/pixheap.onmicrosoft.com/b2c_1_susi",
          "callbackUri": "https://stg.pixheap.com/",
          "clientId": "99cec94a-9ed6-4615-ae19-d1ab713f70ff",
          "knownAuthorities": [
            "pixheap.b2clogin.com"
          ],
          "scopes": [
            "openid",
            "email",
            "https://pixheap.onmicrosoft.com/99cec94a-9ed6-4615-ae19-d1ab713f70ff/web.read"
          ]
        },
        "appInsights": {
          "instrumentationKey": "662ea7f0-ff72-45d5-b390-f504b3099038",
          "autoRouteTracking": true,
          "logToConsole": true
        },
        "stripe": {
          "pricingTableId": "prctbl_1PeMzTLF3poMMSQ4Q3vi89An",
          "publishableKey": "pk_test_51N8WhgLF3poMMSQ4IJzxYonDXMqTdQ61uIrl6Y9OURfswfGgsY5vGDkuHjSVcnyFZ39QktYM9z9mDOeBvlug7yOr00Ib55R1wF",
          "managementLink": "https://billing.stripe.com/p/login/test_3csg29b0Qfbl33q144"
        }
      }
    },
    {
      "name": "prod",
      "properties": {
        "imageLocation": "https://f.pixheap.com",
        "signalREndpoint": "https://publicapiweuprod.azurewebsites.net/api",
        "baseAPIUrl": "https://publicapiweuprod.azurewebsites.net/api/v1/",
        "azureAD": {
          "authority": "https://pixheap.b2clogin.com/pixheap.onmicrosoft.com/b2c_1_susi",
          "callbackUri": "https://pixheap.com/",
          "clientId": "5116098a-7b69-4432-991a-23354b248e8c",
          "knownAuthorities": [
            "pixheap.b2clogin.com"
          ],
          "scopes": [
            "openid",
            "email",
            "https://pixheap.onmicrosoft.com/5116098a-7b69-4432-991a-23354b248e8c/web.read"
          ]
        },
        "appInsights": {
          "instrumentationKey": "662ea7f0-ff72-45d5-b390-f504b3099038",
          "autoRouteTracking": true,
          "logToConsole": true
        },
        "stripe": {
          "pricingTableId": "prctbl_1OxpwqLF3poMMSQ4VjIsiIic",
          "publishableKey": "pk_live_51N8WhgLF3poMMSQ4YxHiM9nxe03R1YWXF3UprpuyoTlzOFVtZC5I49aWQA9bcYpjcvzI3lqEhg7UFC1uVqkxmPTa00PVmzvoOn",
          "managementLink": "https://billing.stripe.com/p/login/cN22b12GC2wN65O000"
        }
      }
    }
  ],
  "properties": {
    "routes": [
      {
        "id": "linkCreate",
        "route": "link",
        "method": "POST",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "linkUpdate",
        "route": "link",
        "method": "PUT",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "linkGet",
        "route": "link/{linkId}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "linkGetAll",
        "route": "links",
        "method": "GET",
        "authorize": true,
        "payload": {
          "body": false,
          "bodyFormat": null
        }
      },
      {
        "id": "foldersGetAll",
        "route": "folders",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "getLinksInFolder",
        "route": "links/folder/{folderId}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "downloadInCloud",
        "route": "link/persistcontent/{id}",
        "method": "POST",
        "authorize": true
      },
      {
        "id": "downloadInCloudFromURL",
        "route": "galleries",
        "method": "POST",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "linkDelete",
        "route": "link/{id}",
        "method": "DELETE",
        "authorize": true
      },
      {
        "id": "createFolder",
        "route": "folders/{folderName}",
        "method": "POST",
        "authorize": true
      },
      {
        "id": "updateFolder",
        "route": "folders/{id}",
        "method": "PUT",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "foldersGetAll",
        "route": "folders",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "folderDelete",
        "route": "folders/{folderId}",
        "method": "DELETE",
        "authorize": true
      },
      {
        "id": "userGet",
        "route": "user",
        "method": "GET",
        "authorize": true,
        "payload": null
      },
      {
        "id": "getTOUPublishDate",
        "route": "lasttoupublishdate",
        "method": "GET",
        "authorize": false
      },
      {
        "id": "acceptTOU",
        "route": "accepttou",
        "method": "POST",
        "authorize": true
      },
      {
        "id": "rejectTOU",
        "route": "rejecttou",
        "method": "POST",
        "authorize": true
      },
      {
        "id": "profileGet",
        "route": "profile",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "uploadFiles",
        "route": "files",
        "method": "POST",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "formData"
        }
      },
      {
        "id": "getGalleriesInFolder",
        "route": "galleries/folder/{folderId}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "getGallery",
        "route": "galleries/{galleryId}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "getGalleryPreview",
        "route": "galleries/preview/{id}",
        "method": "GET",
        "authorize": false
      },
      {
        "id": "deleteGallery",
        "route": "galleries/{galleryId}",
        "method": "DELETE",
        "authorize": true
      },
      {
        "id": "updateGallery",
        "route": "galleries",
        "method": "PUT",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "downloadGallery",
        "route": "galleries/download/{galleryId}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "getLatestGalleries",
        "route": "landingpage/latestgalleries/{count}",
        "method": "GET",
        "authorize": false
      },
      {
        "id": "galleryOpened",
        "route": "status/gallery/{galleryId}",
        "method": "POST",
        "authorize": true
      },
      {
        "id": "renameImage",
        "route": "galleries/image",
        "method": "PUT",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "copyFiles",
        "route": "galleries/copyimages",
        "method": "POST",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "sendStatus",
        "route": "status",
        "method": "POST",
        "authorize": false,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "search",
        "route": "search/{fragment}",
        "method": "GET",
        "authorize": false
      },
      {
        "id": "adminGetSystemStatistics",
        "route": "admin/statistics",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "getFrontPage",
        "route": "admin/frontpage/{count}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "deleteFromFrontPage",
        "route": "admin/frontpage/{galleryId}/{allowed}",
        "method": "PUT",
        "authorize": true
      },
      {
        "id": "adminGetAllUsers",
        "route": "admin/users",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "adminGetUserProfile",
        "route": "admin/users/{userId}",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "adminRecalculateSystemUsageForUser",
        "route": "admin/users/recalculateusage/{userId}",
        "method": "POST",
        "authorize": true
      },
      {
        "id": "feedbackPost",
        "route": "feedback",
        "method": "POST",
        "authorize": true,
        "payload": {
          "body": true,
          "bodyFormat": "json"
        }
      },
      {
        "id": "feedbackGet",
        "route": "feedback",
        "method": "GET",
        "authorize": true
      },
      {
        "id": "feedbackDelete",
        "route": "feedback/{feedbackId}",
        "method": "DELETE",
        "authorize": true
      },
      {
        "id": "hasValidSubscription",
        "route": "subscriptions",
        "method": "GET",
        "authorize": true
      }
    ]
  }
}