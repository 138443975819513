import { AdminService, IAdminService } from "../../../../../common/adminService";
import { AuthService, IAuthService } from "../../../../../common/authService";
import { ConfigService, IConfigService } from "../../../../../common/configService";
import { ILogService, LogService } from "../../../../../common/logging/logService";

export class AdminFrontpage {
  public imageLoc: string;
  constructor(
    @ILogService private readonly logService: LogService,
    @IAuthService private readonly authService: AuthService,
    @IAdminService private readonly adminService: AdminService,
    @IConfigService private readonly configService: ConfigService
  ) {
    this.imageLoc = this.configService.getProperty("imageLocation") as string;
  }

  async canLoad() {
    if (!this.authService.isLoggedIn) {
      return "";
    }

    if (!this.authService.isAdmin) {
      this.logService.logTrace("not admin - decline loading!");
      return "/profile-page";
    }

    return true;
  }

  public gotoLink(gallery): void {
    // link clicked - open it in new browser tab
    window.open(gallery.url, "_blank").focus();
  }

  public async toggleOnFrontPage($index: number) {
    await this.adminService.toggleVivibilityOnFrontPage($index);
  }

  public blurThumbnails = true;
  public toggleBlurOnThumbnailsForLocalDev() {
    this.blurThumbnails = !this.blurThumbnails;
  }

  public toggleFrontPageForLocalDev() {
    this.adminService.frontpageGalleriesHide = !this.adminService.frontpageGalleriesHide;
  }

}