<div ref="menucontainer" class="user-status">
  <sl-tooltip content="Log in">
    <sl-icon-button if.bind="!authService.isLoggedIn" name="box-arrow-in-right" label="Log in"
      click.trigger="logIn()"></sl-icon-button>
  </sl-tooltip>

  <sl-dropdown if.bind="authService.isLoggedIn">
    <sl-icon-button slot="trigger" name="gear" label="Settings"></sl-icon-button>

    <sl-menu sl-select.trigger="handleMenuSelect">
      <sl-menu-item value="profile">Profile</sl-menu-item>
      <sl-menu-item value="feedback">Feedback</sl-menu-item>

      <sl-divider if.bind="authService.isAdmin"></sl-divider>
      <sl-menu-item if.bind="authService.isAdmin" value="admin">
        Admin
        <sl-menu slot="submenu">
          <sl-menu-item value="admin-statistics">Usage Statistics</sl-menu-item>
          <sl-menu-item value="admin-frontpage">Frontpage</sl-menu-item>
          <sl-menu-item value="admin-users">Users</sl-menu-item>
          <sl-menu-item value="admin-feedback">Feedback</sl-menu-item>
        </sl-menu>
      </sl-menu-item>

      <sl-divider></sl-divider>
      <sl-menu-item value="tou">Terms of Usage</sl-menu-item>
      <sl-menu-item value="privacy">Privacy Policy</sl-menu-item>
      <sl-menu-item value="takedown">Takedown Request</sl-menu-item>

      <sl-divider></sl-divider>
      <sl-menu-item value="logout">Log out</sl-menu-item>
    </sl-menu>
  </sl-dropdown>
</div>