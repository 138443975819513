<sl-icon-button name="folder-plus" label="Create Folder" title="Create new Folder" click.trigger="open()"></sl-icon-button>

<sl-dialog label="Create Folder" ref="dialog">
  <form ref="form" submit.trigger="createFolder()">
    <sl-input ref="input" type="text" name="folderName" label="Create New Folder" placeholder="Folder Name" autocomplete="off" required></sl-input>

    <br />

    <sl-button type="submit" class="pull-right" disabled.bind="folderName==''" loading.bind="loading">Save</sl-button>
  </form>
</sl-dialog>
