<h1>Gallery Details</h1>

<table>
  <thead>
    <tr>
      <th colspan="2">Gallery Info</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Title</td>
      <td>${gallery.title}</td>
    </tr>
    <tr>
      <td>URL</td>
      <td><a href.bind="gallery.url" target="_blank">${gallery.url}</a></td>
    </tr>
    <tr>
      <td>AllowedOnFrontPage</td>
      <td>${gallery.allowedOnFrontPage}</td>
    </tr>
    <tr>
      <td>Create Date</td>
      <td>${gallery.createDate}</td>
    </tr>
    <tr>
      <td>Clicks</td>
      <td>${gallery.clicks}</td>
    </tr>
    <tr>
      <td>Images</td>
      <td>${gallery.images}</td>
    </tr>
    <tr>
      <td>User Id</td>
      <td>${gallery.userId}</td>
    </tr>
    <tr>
      <td>Views</td>
      <td>${gallery.views}</td>
    </tr>
    <tr>
      <td>CSP</td>
      <td>${gallery.csp}</td>
    </tr>
  </tbody>
</table>
