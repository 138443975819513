import { IRouter } from "@aurelia/router";
import { SlSelectEvent } from "@shoelace-style/shoelace/dist/events/events";

import { IAuthService, AuthService } from "../../../../common/authService";

export class UserStatus {

  constructor(@IAuthService private readonly authService: AuthService, @IRouter private readonly router: IRouter) { }

  public handleMenuSelect = async (ev: SlSelectEvent) => {
    // the sub menus trigger double events, if navigation already was started by the prior event, 
    // or another navigation is ongoaing, just ignore the nav
    if (this.router.isNavigating) return;

    switch (ev.detail.item.value) {
      case "profile":
        this.router.load("/profile-page");
        break;
      case "feedback":
        this.router.load("/feedback-page");
        break;
      //--- admin
      case "admin-statistics":
        this.router.load("/admin-statistics");
        break;
      case "admin-frontpage":
        this.router.load("/admin-frontpage");
        break;
      case "admin-users":
        this.router.load("/admin-users");
        break;
      case "admin-feedback":
        this.router.load("/admin-feedback");
        break;
      //--- other
      case "tou":
        await this.router.load("/tou-page");
        break;
      case "privacy":
        await this.router.load("/privacy-policy");
        break;
      case "takedown":
        await this.router.load("/takedown-request");
        break;

      //--- logout
      case "logout":
        this.authService.logOut();
        break;
    }
  }

  public logIn(): void {
    this.authService.logIn();
  }
}
