import { DI } from "aurelia";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ConfigService, IConfigService } from "../configService";

declare global {
  interface Window {
    AppInsights: ApplicationInsights;
  }
}

export class LogService {
  constructor(@IConfigService private readonly configService: ConfigService) {}

  public init(): void {
    window.AppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.configService.getProperty("appInsights.instrumentationKey") as string,
        enableAutoRouteTracking: this.configService.getProperty("appInsights.autoRouteTracking") as boolean, // option to log all route changes
        disableFetchTracking: false,
        isBeaconApiDisabled: true,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ["myapp.azurewebsites.net", "*.queue.core.windows.net"],
      },
    });
    window.AppInsights.loadAppInsights();
  }

  public logPageView(name?: string, url?: string): void {
    this.logToConsole("PageView", { name: name, url: url });
    // option to call manually
    window.AppInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }): void {
    this.logToConsole("Event", { name: name, properties: properties });
    window.AppInsights.trackEvent({ name: name }, properties);
  }

  public logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.logToConsole("Metric", { name: name, average: average, properties: properties });
    window.AppInsights.trackMetric({ name: name, average: average }, properties);
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.logToConsole("Exception", { exception: exception, severityLevel: severityLevel });
    window.AppInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  public logTrace(message: string, properties?: { [key: string]: any }): void {
    this.logToConsole("Trace", { message: message, properties: properties });
    window.AppInsights.trackTrace({ message: message }, properties);
  }

  private logToConsole(logType: string, args: LogToConsoleArgs): void {
    if (!this.configService.getProperty("appInsights.logToConsole") as boolean) return;

    console.group(`LogType: ${logType}`);
    if (args.url) console.log(`Url: ${args.url}`);
    if (args.message) console.log(`Message: ${args.message}`);
    if (args.name) console.log(`Name: ${args.name}`);
    if (args.average) console.log(`Average: ${args.average}`);
    if (args.exception) console.log(`Exception: ${args.exception}`);
    if (args.average) console.log(`Average: ${args.average}`);
    if (args.properties) {
      console.log("Properties");
      console.table(args.properties);
    }
    console.groupEnd();
  }
}

export const ILogService = DI.createInterface<LogService>("ILoggingService", (x) => x.singleton(LogService));

interface LogToConsoleArgs {
  url?: string;
  properties?: { [key: string]: any };
  name?: string;
  average?: number;
  exception?: Error;
  severityLevel?: number;
  message?: string;
}
