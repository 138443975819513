import { watch } from '@aurelia/runtime-html';

import { FolderService, IFolderService } from "../../../common/folderService";
import { GalleryService, IGalleryService } from "./../../../common/galleryService";
import { IGallery } from "../../../common/interfaces/IGallery";

export class GalleriesPage {
  public galleries: IGallery[] = [];
  public filter: string;

  constructor(@IGalleryService private readonly galleryService: GalleryService, @IFolderService public readonly folderService: FolderService) { }

  public async binding(): Promise<void> {
    this.galleryService.initAsync();
  }

  public attached(): void {
    const content = document.querySelector(".scroll-container");
    const scrollTo = sessionStorage.getItem("galleryScrollPosition");
    if (content && !!scrollTo) {
      content.scrollTop = parseInt(scrollTo, 10);
    }
  }

  public detaching(): void {
    const content = document.querySelector(".scroll-container");
    sessionStorage.setItem("galleryScrollPosition", content.scrollTop.toString());
  }

  @watch("filter")
  public filterFunc(): void {
    this.folderService.sortActiveFolder(this.filter, "gallery");
  }
}
