<div ref="foldercomponent" class="folder-component">
  <sl-icon if.bind="folder.expanded" name="folder2-open" class="icon pulse" click.trigger="toggle()"></sl-icon>
  <sl-icon else name="folder2" class="icon pulse" click.trigger="toggle()"></sl-icon>
  <h2 click.trigger="toggle()" class="pulse-small">${folder.name}</h2>

  <div class="actions">
    <gallery-filter if.bind="folder.expanded"></gallery-filter>

    <sl-dropdown if.bind="folder.id!='00000000-0000-0000-0000-000000000000' && folder.expanded" placement="bottom-end" class="dropdown">
      <sl-icon-button slot="trigger" name="three-dots-vertical" label="Menu"></sl-icon-button>
      <sl-menu sl-select.trigger="handleMenuSelect">
        <sl-menu-item value="edit"
          >Edit
          <sl-icon slot="prefix" name="pencil"></sl-icon>
        </sl-menu-item>
        <sl-divider></sl-divider>
        <sl-menu-item value="delete"
          >Delete
          <sl-icon slot="prefix" name="trash"></sl-icon>
        </sl-menu-item>
      </sl-menu>
    </sl-dropdown>
  </div>
</div>
