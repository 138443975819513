import { IRouteableComponent, IRouter, Parameters, Router } from "@aurelia/router";

import { IAdminService, AdminService } from "../../../../common/adminService";
import { IAuthService, AuthService } from "../../../../common/authService";
import { IGalleryService, GalleryService } from "../../../../common/galleryService";
import { ILinkService, LinkService } from "../../../../common/linkService";
import { ISubscriptionService, SubscriptionService } from "../../../../common/subscriptionService";

export class ExternalLink implements IRouteableComponent {
  public noticelogin: HTMLDialogElement
  public noticesubscription: HTMLDialogElement

  public preview: any;
  public http: boolean;

  constructor(
    @IAdminService public readonly adminService: AdminService,
    @IAuthService private readonly authService: AuthService,
    @IGalleryService private readonly galleryService: GalleryService,
    @ILinkService private readonly linkService: LinkService,
    @IRouter private readonly router: Router,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService,
  ) { }

  async loading(params: Parameters) {
    this.galleryService.getGalleryPreview(params.previewId as string).then((preview) => {
      this.preview = preview;
      this.http = this.preview.url?.startsWith('http://');
    });
  }

  public saveGallery() {
    if (this.authService.isLoggedIn === false) {
      this.noticelogin.showModal();
      return;
    }

    if (!this.subscriptionService.canCreateGalleries) {
      this.noticesubscription.showModal();
      return;
    }

    // save button clicked
    this.galleryService.createGalleryFromURL(this.preview.url);
  }

  public saveLink() {
    if (this.authService.isLoggedIn === false) {
      this.noticelogin.showModal();
      return;
    }

    if (!this.subscriptionService.canCreateLinks) {
      this.noticesubscription.showModal();
      return;
    }

    this.linkService.create(this.preview.url);
  }

  public close(dialog: HTMLDialogElement) {
    dialog.close();
  }

  public loadLogin() {
    this.router.load('login');
  }

  public loadPricePage() {
    this.router.load('price-page');
  }
}