<require from="@shoelace-style/shoelace/dist/themes/light.css"></require>

<top-bar></top-bar>

<div id="wrapper">
  <div id="sidebarleft"></div>

  <au-viewport default="home" fallback="missing"></au-viewport>

  <div id="sidebarright"></div>
</div>

<dialog ref="dialog">
  <h3>${notification.title}</h3>
  <div innerhtml.bind="notification.message"></div>
  <div
    if.bind="notification.url || notification.nuttonNegativeCallback() !== null || notification.buttonPositiveCallback() !== null"
    class="button-row">
    <sl-button ref="negativebtn" click.trigger="negativeBtnClick()">${notification.buttonNegativeText ??
      "Cancel"}</sl-button>
    <sl-button ref="positivebtn" variant="primary" click.trigger="positiveBtnClick()">${notification.buttonPositiveText
      ?? "Go ➡️"}</sl-button>
  </div>
</dialog>