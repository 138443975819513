<div role="link" click.trigger="navigate()" class="gallery-component">
  <div class="title-row">
    <h3 title.one-time="gallery.title">${gallery.title!=='' ? gallery.title : 'Not named...'}</h3>
  </div>

  <!-- thumbnails -->
  <div if.bind="gallery.imageFiles.length===0">
    <p>Empty...</p>
  </div>

  <div if.bind="gallery.thumbnails.length > 0" class="thumb-row">
    <div repeat.for="thumb of gallery.thumbnails.slice(0,thumbcolumns)">
      <img class="component-thumb" src.one-time=`${imageLocation}/${container}/${gallery.id}/${thumb}` loading="lazy" role="presentation">
    </div>
  </div>
  <div else>
    <!-- no thumbnails generated, use real images -->
    <div if.bind="gallery.imageFiles.length > 0">
      <div class="thumb-row">
        <div repeat.for="image of gallery.imageFiles.slice(0,thumbcolumns)">
          <img class="component-thumb" src.one-time=`${imageLocation}/${container}/${gallery.id}/${image}` loading="lazy" role="presentation">
        </div>
      </div>
    </div>
  </div>
</div>
