import { IRouteableComponent, Parameters } from "@aurelia/router";
import { IAdminService, AdminService } from "../../../../../common/adminService";
import { IAuthService, AuthService } from "../../../../../common/authService";
import { IGallery } from "../../../../../common/interfaces/IGallery";
import { ILogService, LogService } from "../../../../../common/logging/logService";

export class GalleryDetails implements IRouteableComponent {
  public gallery: IGallery;

  constructor(@ILogService private readonly logService: LogService,
    @IAuthService private readonly authService: AuthService,
    @IAdminService private readonly adminService: AdminService) { }

  async canLoad() {
    if (!this.authService.isLoggedIn) {
      return "about";
    }

    if (!(this.authService.isAdmin)) {
      this.logService.logTrace("not admin - decline loading!");
      return "/profile-page";
    }

    return true;
  }

  async loading(params: Parameters) {
    this.gallery = await this.adminService.getGallery(params[0] as string);
  }
}