import { bindable } from "aurelia";
import { watch } from '@aurelia/runtime-html';

import { ILinkService, LinkService } from "./../../../../common/linkService";
import { Link } from "../../../../common/interfaces/ILink";
import { LinkStatusValueConverter } from "../../../common/value-converters/linkStatusValueConverter";
import { ISubscriptionService, SubscriptionService } from "../../../../common/subscriptionService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";
import { SlSelectEvent } from "@shoelace-style/shoelace/dist/events/events";
import { UrlToUIValueConverter } from "../../../common/common-registry";

export class LinkComponent {
  @bindable link: Link;
  public linkStatus = "";
  public computedTitle = "";
  public loading: boolean;
  public showMoveFolder: boolean;
  public showEditLink = false;

  constructor(@ILinkService private readonly linkService: LinkService,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService,
    @INotificationService private readonly notificationServive: NotificationService) { }

  binding(): void {
    this.linkStatus = new LinkStatusValueConverter().toView(this.link.status);
    this.computeTitle();
  }

  @watch("link.givenTitle")
  private computeTitle() {
    if (this.link.givenTitle?.length > 0) {
      this.computedTitle = this.link.givenTitle;
    } else if (this.link.title?.length > 0) {
      this.computedTitle = this.link.title;
    } else {
      this.computedTitle = new UrlToUIValueConverter().toView(this.link.url);
    }
  }

  public statusConv(status: number): string {
    // return new LinkStatusValueConverter().toView(status);
    // <i class="fas fa-cloud-download-alt"></i>

    if (typeof status !== "number") {
      return;
    }
    switch (status) {
      case 0:
        // Saved
        return '<span class="signal-ok" title="Saved"><i class="fas fa-check-circle"></i></span>';
      case 2:
        // processing
        return '<span class="signal-working" title="Processing"><i class="fas fa-cogs"></i></span>';
      case 3:
        // downloading
        return '<span class="signal-working" title="Downloading"><i class="fas fa-cloud-download-alt"></i></span>';
      case 6:
        // no images found
        return '<span class="signal-error" title="No images found"><i class="fas fa-exclamation-triangle"></i></span>';
      case 7:
        // return "Finished Processing";
        return 'ok';
        return '<span class="signal-ok" title="Gallery created"><i class="far fa-images"></i></span>';
      default:
        break;
    }
  }

  public handleMenuSelect = (event: SlSelectEvent) => {
    switch (event.detail.item.value) {
      case "downloadGallery":
        this.createGallery();
        break;
      case "changeFolder":
        this.showMoveFolder = !this.showMoveFolder;
        break;
      case "edit":
        this.showEditLink = !this.showEditLink;
        break;
      case "delete":
        this.deleteLink();
        break;
    }
  }

  public deleteLink = async (): Promise<void> => {
    // delete icon clicked
    this.loading = true;
    const success = await this.linkService.deleteLink(this.link);
    this.loading = false;

    if (success) {
      this.notificationServive.info({
        title: "Link deleted",
        message: "Link sucessfully deleted.",
        type: "info",
        date: new Date(),
        url: "",
      });
    } else {
      this.notificationServive.error({
        title: "Link delete failed",
        message: "Failed to delete link.",
        type: "error",
        date: new Date(),
        url: "",
      });
    }
  };

  public createGallery = (): void => {
    if (!this.subscriptionService.canCreateGalleries) {
      this.notificationServive.info({
        title: "Subscription required",
        message: "You need a valid subscription to download more galleries.",
        type: "error",
        date: new Date(),
        url: "price-page",
      });
      return;
    }

    this.linkService.downloadInCloud(this.link);

    this.notificationServive.info({
      title: "Gallery Creation Started",
      message: "Started creating gallery.",
      type: "info",
      date: new Date(),
      url: "",
    });
  };

  public gotoLink(): void {
    // link clicked - open it in new browser tab
    window.open(this.link.url, "_blank").focus();
  }

  // private shallowCopy(link: Link): Link {
  //   return { ...link };
  // }

}
