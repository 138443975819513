import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";

import { LinkService, ILinkService } from "../../../../common/linkService";
import { ISubscriptionService, SubscriptionService } from "../../../../common/subscriptionService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";

export class AddLink {
  public dialog: SlDialog;
  public urlinput: SlInput;
  public form: HTMLFormElement;
  public loading: boolean;

  constructor(@ILinkService private readonly linkService: LinkService,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService,
    @INotificationService private readonly notificationServive: NotificationService) { }


  public open(): void {
    this.urlinput.setAttribute("value", "");
    this.dialog.show();
  }
  public async submit(): Promise<boolean> {
    if (!this.subscriptionService.canCreateLinks) {
      this.notificationServive.modal({
        title: "Subscription required",
        message: "You need a valid subscription to add more links.",
        type: "error",
        date: new Date(),
        url: "price-page"
      });
      this.dialog.hide();
      return false;
    }
    // If the form is invalid, quit.
    const formIsValid = this.form.reportValidity();
    if (!formIsValid) return;

    // Serialize the form data and extract the URL.
    const formData = serialize(this.form);
    const url = formData["url"] as string;

    this.loading = true;
    await this.linkService.create(url);
    this.loading = false;

    this.dialog.hide();

    // return false to avoid form postback
    return false;
  }
}
