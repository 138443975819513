<delete-folder></delete-folder>
<edit-folder></edit-folder>

<div class="header">
  <create-gallery></create-gallery>
</div>

<div class="scroll-container">
  <template repeat.for="folder of galleryService.folders">
    <folder-component folder.bind="folder" type="gallery"></folder-component>
    <gallery-component if.bind="folder.expanded" repeat.for="gallery of folder.galleries" gallery.bind="gallery"></gallery-component>
    <div class="empty-message" if.bind="folder.expanded && !folder.loading && folder.galleries.length===0">This folder is empty</div>
  </template>
</div>
