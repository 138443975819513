import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";

import { FolderService, IFolderService } from "./../../../../common/folderService";
import { ILogService, LogService } from "../../../../common/logging/logService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import { bindable } from "aurelia";

export class AddFolder {
  @bindable public type: "link" | "gallery";
  public dialog: SlDialog;
  public form: HTMLFormElement;
  public input: SlInput;
  public loading: boolean;

  constructor(@IFolderService private readonly folderService: FolderService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService,) { }

  public open(): void {
    this.input.setAttribute("value", "");
    this.dialog.show();
  }

  public async createFolder(): Promise<void> {
    this.loading = true;
    const formData = serialize(this.form);
    const folderName = formData.folderName as string;
    const result = await this.folderService.createFolder(folderName, this.type);
    this.loading = false;

    if (result) {
      this.notificationService.info({
        title: "Folder created",
        message: `Folder ${folderName} was successfully created`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Folder created: ${folderName}`);
    } else {
      this.notificationService.error({
        title: "Folder creation failed",
        message: `Folder ${folderName} was not created!
        Reason unknown - try again later. If the problem persists, contact support.`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Failed to create folder: ${folderName}`);
    }

    this.dialog.hide();
  }
}
