import { serialize } from "@shoelace-style/shoelace/dist/utilities/form";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import SlTextArea from "@shoelace-style/shoelace/dist/components/textarea/textarea";

import { IProfileService, ProfileService } from "../../../../common/profileService";

export class FeedbackPage {
  public subject = "";
  public feedback = "";
  public loading: boolean;
  public form: HTMLFormElement;
  public input: SlInput;
  public textarea: SlTextArea;

  constructor(@IProfileService private readonly profileService: ProfileService) { }

  public async submitFeedback(): Promise<void> {
    this.loading = true;
    const formData = serialize(this.form);
    const success = await this.profileService.sendFeedback(formData["subject"] as string, formData["feedback"] as string);
    this.loading = false;
    if (success) {
      alert("Thank you for sending feedback! 🥰");
      this.input.value = "";
      this.textarea.value = "";
    } else {
      alert("Something malfunctioned when sending feedback, please try sending again? 😟")
    }
  }
}