import { SlDialog } from "@shoelace-style/shoelace";
import { bindable } from "aurelia";
import { IRouter, Router } from "@aurelia/router";

import { IGallery } from "../../../../common/interfaces/IGallery";
import { IGalleryService, GalleryService } from "../../../../common/galleryService";
import { ILogService, LogService } from "../../../../common/logging/logService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";

export class GalleryDelete {
  @bindable gallery: IGallery;
  @bindable display: boolean;

  public dialog: SlDialog;

  public loading: boolean;

  constructor(
    @IGalleryService private readonly galleryService: GalleryService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService,
    @IRouter private readonly router: Router
  ) { }

  public displayChanged(): void {
    this.dialog.show();
  }

  public async delete() {
    await this.deleteGallery();
  }

  private async deleteGallery(): Promise<void> {
    this.loading = true;
    const deleted = await this.galleryService.deleteGallery(this.gallery.id);
    this.loading = false;

    if (deleted) {
      this.notificationService.info({
        title: "Gallery deleted",
        message: `Gallery was successfully deleted`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Gallery deleted: ${this.gallery.title}`);
      this.dialog.hide();
      this.router.load("galleries-page");
    } else {
      this.notificationService.error({
        title: "Gallery delete failed",
        message: `Gallery was not deleted!
        Reason unknown - try again later. If the problem persists, contact support.`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Failed to delete gallery: ${this.gallery.id}`);
      this.dialog.hide();
    }
  }

}