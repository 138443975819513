import { IRouter, Router } from "@aurelia/router";
import { IAdminService, AdminService } from "../../../../common/adminService";
import { IAuthService, AuthService } from "../../../../common/authService";
import { ILogService, LogService } from "../../../../common/logging/logService";
import { User } from "../../../../common/interfaces/User";

export class AdminUsers {
  users: Array<User> = [];

  constructor(
    @IAuthService private readonly authService: AuthService,
    @ILogService private readonly logService: LogService,
    @IAdminService private readonly adminService: AdminService,
    @IRouter private readonly router: Router
  ) { }

  async canLoad() {
    if (!(this.authService.isAdmin)) {
      this.logService.logTrace("not admin - decline loading!");
      return "/profile-page";
    }
    return true;
  }

  async loading() {
    this.users = await this.adminService.getAllUsers();
  }

  public async viewUser(index: number) {
    await this.router.load('/user-profile', {
      title: 'Admin - User Profile',
      parameters: {
        userId: this.users[index].id
      }
    });
  }

  public async recalculateSystemUsage($index: number) {
    const usr = await this.adminService.recalculateSystemUsage(this.users[$index]);
    if (usr) this.users.splice($index, 1, usr);
  }

}