<h1>Administer Frontpage</h1>

<div class="section">
  <sl-checkbox checked.bind="adminService.frontpageGalleriesHide" sl-change.trigger="toggleFrontPageForLocalDev()">Hide frontpage for local dev?</sl-checkbox>
  <br />
  <sl-checkbox checked.bind="blurThumbnails" sl-change.trigger="toggleBlurOnThumbnailsForLocalDev()">Blur thumbnails on this page for local dev?</sl-checkbox>

  <sl-input label="Set the amount of thumbnails to be visible on this page" type="number" value.bind="amountOfThumbnailsForAdmin"></sl-input>

  <sl-input label="Set the amount of thumbnails to be visible on the front page" type="number" value.bind="amountOfThumbnailsForFrontpage"></sl-input>
</div>

<h2>Allowlist Frontpage</h2>
<div class="grid" promise.bind="adminService.frontPageData()">
  <template pending>Loading...</template>

  <div then.from-view="data" class="grid-cell" repeat.for="gallery of data">
    <a load="/gallery-details(${gallery.id})"> <img blur.class="blurThumbnails" src.bind=`${imageLoc}/gpw/${gallery.thumbnail}` /> </a>
    <label click.trigger="toggleOnFrontPage($index)">
      <input type="checkbox" checked.bind="gallery.allowedOnFrontPage" />
      Allowed
    </label>
  </div>

  <template catch.from-view="err">This promise is rejected with ${err.message}.</template>
</div>
