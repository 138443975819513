import { IRouteableComponent, IRouter, Router, Parameters } from "@aurelia/router";

import Hammer from "hammerjs";
import { SlSelectEvent } from "@shoelace-style/shoelace/dist/events/sl-select";

import { AuthService, IAuthService } from "../../../common/authService";
import { ConfigService, IConfigService } from "../../../common/configService";
import { FolderService, IFolderService } from "../../../common/folderService";
import { GalleryService, IGalleryService } from "../../../common/galleryService";
import { IGallery } from "../../../common/interfaces/IGallery";
import { LogEvent } from "../../../common/logging/logDecorators";
import { LogService, ILogService } from "./../../../common/logging/logService";
import { NotificationService, INotificationService } from "./../../../common/notificationService";
import { SubscriptionService, ISubscriptionService } from "../../../common/subscriptionService";
import { UINotification } from "../../../common/uiNotification";
import { EventAggregator, IDisposable, IEventAggregator, bindable } from "aurelia";
import { EventType } from "../../../common/eventType";
import { IUIGallery } from "../../../common/interfaces/IUIGallery";

export class Gallery implements IRouteableComponent {
  public static parameters: string[] = ["id"];

  public gallery: IUIGallery;
  public imageLocation: string;
  public displayImageIndex = 0;
  public path = "";

  public modal: HTMLElement;

  // used as a semaphore to prevent multiple navigations
  private navigating = true;

  private imageIndex = 0;
  private imageContainer: Element;

  private imagesFromLeftToRight: HTMLImageElement[];

  private movingLeft = true;
  private isZoomedIn = false;
  private leftImageContainer: HTMLElement;
  private centerImageContainer: HTMLElement;
  private rightImageContainer: HTMLElement;
  private transitionListenerSet = false;

  private mcHammer: HammerManager = void 0;

  public showMoveFolder = false;
  public showDeleteGallery: boolean;
  public showEditFileName: boolean;
  public showCopyFiles: boolean;
  public showDeleteImages: boolean;
  public imagesToBeDeleted: string[] = [];

  public previousGallery: IGallery;
  public nextGallery: IGallery;

  public editMode = false;

  public selectedThumbs: boolean[] = [];


  eventSubscriptions: IDisposable[] = [];

  constructor(
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IRouter private readonly router: Router,
    @IAuthService private readonly authService: AuthService,
    @IConfigService private readonly config: ConfigService,
    @IFolderService private readonly folderService: FolderService,
    @IGalleryService private readonly galleryService: GalleryService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService,
    @ISubscriptionService private readonly subscriptionService: SubscriptionService
  ) {
    this.imageLocation = this.config.getProperty("imageLocation") as string;
  }

  public async canLoad(params): Promise<boolean | string> {
    if (await !this.subscriptionService.canViewGalleries()) {
      const notification: UINotification = {
        title: "Subscription required",
        message: "You need a valid subscription to view galleries.",
        type: "error",
        date: new Date(),
        url: "price-page",
      };
      this.notificationService.modal(notification);

      return "galleries-page";
    }

    this.gallery = await this.galleryService.getGallery(params.id);
    if (!this.gallery) {
      return "galleries-page";
    }

    return true;
  }

  async loading(params: Parameters) {
    // upp the viewcount
    await this.galleryService.updateViewCount(this.gallery);

    // listen to gallery moved folder event and update the siblings if so
    this.eventSubscriptions.push(this.eventAggregator.subscribe("galleryMovedFolder" as EventType, async (gallery: IGallery) => {
      await this.updateSiblings();
    }));

    this.eventSubscriptions.push(this.eventAggregator.subscribe("deleteImagesConfirm" as EventType, async (images: string[]) => {
      await this.deleteImagesConfirmed(images);
    }));

    // used for paths to images
    this.path = params.id as string;

    // set the selected thumbnails array
    this.gallery.selectedThumbnails = [];
    for (let i = 0; i < this.gallery.thumbnails.length; i++) {
      this.gallery.selectedThumbnails.push({ thumb: this.gallery.thumbnails[i], selected: false });
    }

    // get any sibling galleries for navigation
    await this.updateSiblings();

    this.navigating = false;
  }

  public attached(): void {
    // scroll the viewport to the top when a new gallery is opened
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    window.addEventListener("keydown", this.keyEventHandler, true);
    this.setupHammer();
  }

  public detaching(): void {
    window.removeEventListener("keydown", this.keyEventHandler, true);
    this.mcHammer.destroy();
    this.eventSubscriptions.forEach(s => s.dispose());
  }

  public handleMenuSelect = (event: SlSelectEvent) => {
    switch (event.detail.item.value) {
      case "changeFolder":
        this.displayMoveFolder();
        break;
      case "downloadGallery":
        this.downloadGallery(this.gallery);
        break;
      case "editGallery":
        this.toggleEditMode();
        break;
      case "deleteGallery":
        this.displayDeleteGallery();
        break;
    }
  }

  public displayDeleteGallery() {
    this.showDeleteGallery = !this.showDeleteGallery;
  }

  private async updateSiblings() {
    const siblings = await this.folderService.getSiblings(this.gallery);
    if (siblings[0]) {
      this.previousGallery = siblings[0];
    }
    if (siblings[1]) {
      this.nextGallery = siblings[1];
    }
  }

  //--------------------------------------------------------------------------------


  public downloadGallery = async (gallery: IGallery) => {
    // Check if the user has a valid subscription
    const hasValidSubscriptionAndLevel = await this.subscriptionService.hasValidSubscription && this.subscriptionService.subscriptionLevel === 'Premium';

    // If the user does not have a valid subscription, return without downloading the gallery
    if (!hasValidSubscriptionAndLevel) {
      this.notificationService.modal({
        title: "Subscription required",
        message: "You need to upgrade your subscription level to Premium to download galleries!<br>Please visit the subscription page to upgrade your subscription.",
        type: "error",
        date: new Date(),
        url: "price-page"
      });
      return;
    }

    const result = await this.galleryService.downloadGallery(gallery.id);
    if (!result.ok && result.data) return;

    const url = URL.createObjectURL(<Blob>(result.data));

    // Create a new anchor element with the download attribute set to the desired filename
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${gallery.title}.zip`);

    // Append the anchor element to the document body and simulate a click to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the anchor element and revoking the blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  public displayMoveFolder(): void {
    this.showMoveFolder = !this.showMoveFolder;
  }

  public toggleEditMode = (): void => {
    this.editMode = !this.editMode;
  };


  @LogEvent("Navigate to previous gallery")
  public async navigateToPreviousGallery(): Promise<void> {
    if (this.navigating) return;
    this.navigating = true;
    await this.router.load(`gallery(${this.previousGallery.id})`);
  }

  @LogEvent("Navigate to next gallery")
  public async navigateToNextGallery(): Promise<void> {
    if (this.navigating) return;
    this.navigating = true;
    await this.router.load(`gallery(${this.nextGallery.id})`);
  }

  @LogEvent("Image clicked")
  public thumbnailClicked(indx: number): void {
    if (!this.subscriptionService.canViewGalleries) {
      this.logService.logEvent(`Gallery reached for user with invalid subscription, gallery: ${this.gallery.id}, user: ${this.authService.user.id}`);
      return;
    }

    if (!this.editMode) {
      this.transitionListenerSet = false;
      this.imageIndex = indx;
      this.leftImageContainer = document.querySelector(".image-left");
      this.centerImageContainer = document.querySelector(".image-center");
      this.rightImageContainer = document.querySelector(".image-right");
      this.imagesFromLeftToRight = [
        this.leftImageContainer.firstElementChild as HTMLImageElement,
        this.centerImageContainer.firstElementChild as HTMLImageElement,
        this.rightImageContainer.firstElementChild as HTMLImageElement,
      ];

      this.setImagesWrapper();
      this.displayImageContainer();
    } else {
      this.gallery.selectedThumbnails[indx].selected = !this.gallery.selectedThumbnails[indx].selected;
      if (this.gallery.selectedThumbnails[indx].selected) {
        this.selectedImages++;
      } else {
        this.selectedImages--;
      }
    }
  }

  //#region Copy files
  selectedFiles = [];
  public copyFiles() {
    this.selectedFiles = this.getSelectedImages();
    this.showCopyFiles = !this.showCopyFiles;
  }
  //#endregion

  public selectedImages = 0;
  public async deleteImages(): Promise<void> {
    // if no image is selected -> exit
    if (this.selectedImages === 0) return;
    console.log("delete images");

    // gather all marked images
    this.imagesToBeDeleted = this.getSelectedImages();
    this.showDeleteImages = !this.showDeleteImages;
    return;
  }

  //#region Rename
  public rename = false;
  @bindable fileName: string;
  public async renameImage() {
    const idx = this.gallery.selectedThumbnails.findIndex((t) => t.selected);
    this.fileName = this.gallery.selectedThumbnails[idx].thumb.replace("thumbs/", "").replace(".jpg", "");
    this.showEditFileName = !this.showEditFileName;
    return;
  }
  //#endregion

  public getRefName(index: number): string {
    return `ref${index}`;
  }

  //#region Get selected Images
  private getSelectedImages(): string[] {
    const toBeDeleted: string[] = [];
    this.gallery.selectedThumbnails.forEach((image) => {
      if (!image.selected) return;
      toBeDeleted.push(image.thumb.replace("thumbs/", ""));
    });
    return toBeDeleted;
  }
  //#endregion Selected Images  

  private deleteImagesConfirmed(images: string[]) {
    // gather all marked images
    const tobedeleted: string[] = [];
    this.gallery.selectedThumbnails.forEach((thumb) => {
      if (!thumb.selected) return;
      tobedeleted.push(thumb.thumb);
    });

    // remove selected images from the gallery
    const gal = this.galleryService.deleteImages(this.gallery, tobedeleted);
    if (!gal) {
      this.notificationService.modal({
        title: "Error",
        message: "Error deleting images from gallery",
        type: "error",
        date: new Date(),
        url: "",
      });

      return;
    }

    // remove selected images from selected images 🤯
    for (let i = 0; i < tobedeleted.length; i++) {
      this.gallery.selectedThumbnails.splice(
        this.gallery.selectedThumbnails.findIndex((t) => t.thumb === tobedeleted[i]),
        1
      );
    }
    this.selectedImages = 0;
  }

  private setupHammer(): void {
    if (this.mcHammer) return; // early exit if already instantiated

    this.mcHammer = new Hammer(this.imageContainer as HTMLElement)

    // let the pan gesture support all directions.
    // this will block the vertical scrolling on a touch-device while on the element
    this.mcHammer.get("swipe").set({ direction: Hammer.DIRECTION_ALL });

    // listen to events...
    // mc.on("panleft panright panup pandown tap press", function (ev) {});
    this.mcHammer.on("swipeleft", () => {
      this.moveToNextImage();
    });
    this.mcHammer.on("swiperight", () => {
      this.moveToPreviousImage();
    });
    this.mcHammer.on("swipeup", () => {
      this.hideImageContainer();
    });

    this.mcHammer.on("tap", (ev) => {
      if ((ev as any).tapCount > 1) {
        this.zoomImage(ev.center.x, ev.center.y);
      }
    });
  }

  private keyEventHandler = (event): void => {
    if (event.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    switch (event.key) {
      case "Down": // IE/Edge specific value
      case "ArrowDown":
        if (!this.imageContainerShowing) break;
        // Do something for "down arrow" key press.
        this.zoomImage();
        break;
      case "Up": // IE/Edge specific value
      case "ArrowUp":
        if (!this.imageContainerShowing) break;
        // Do something for "up arrow" key press.
        this.hideImageContainer();
        // event.stopPropagation();
        break;
      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        if (!this.imageContainerShowing) break;
        // Do something for "left arrow" key press.
        this.moveToPreviousImage();
        break;
      case "Right": // IE/Edge specific value
      case "ArrowRight":
        if (!this.imageContainerShowing) break;
        // Do something for "right arrow" key press.
        this.moveToNextImage();
        break;
      case "Enter":
        // Do something for "enter" or "return" key press.
        if (this.imageContainerShowing) break;
        if (isNaN(+(document.activeElement as HTMLElement).dataset.index)) break;
        this.thumbnailClicked(+(document.activeElement as HTMLElement).dataset.index);
        break;
      case "Esc": // IE/Edge specific value
      case "Escape":
        if (!this.imageContainerShowing) break;
        // Do something for "esc" key press.
        this.hideImageContainer();
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }

    // Cancel the default action to avoid it being handled twice
    event.preventDefault();
  };

  @LogEvent("Move to previous image")
  private moveToPreviousImage(): void {
    if (this.isZoomedIn) this.zoomImage(); // just make sure we're zoomed out
    this.movingLeft = true;
    this.imageIndex -= 1;
    this.swapImages();
  }

  @LogEvent("Move to next image")
  private moveToNextImage(): void {
    if (this.isZoomedIn) this.zoomImage(); // just make sure we're zoomed out
    this.movingLeft = false;
    this.imageIndex += 1;
    this.swapImages();
  }

  private swapImages(): void {
    this.leftImageContainer = document.querySelector(".image-left");
    this.centerImageContainer = document.querySelector(".image-center");
    this.rightImageContainer = document.querySelector(".image-right");

    if (!this.transitionListenerSet) {
      this.centerImageContainer.ontransitionend = (ev): void => {
        // only react to transform done
        if (ev.propertyName === "transform") {
          this.setImages();
          this.removeTransitionClassesAfterTransitionFinished();
        }
      };
      this.transitionListenerSet = true;
    }

    // set class on images to translate them
    if (!this.movingLeft) {
      this.leftImageContainer.classList.add("move-right");
      this.centerImageContainer.classList.add("move-left");
      this.rightImageContainer.classList.add("move-center");
      this.imagesFromLeftToRight = [
        this.centerImageContainer.firstElementChild as HTMLImageElement,
        this.rightImageContainer.firstElementChild as HTMLImageElement,
        this.leftImageContainer.firstElementChild as HTMLImageElement,
      ];
    } else {
      this.leftImageContainer.classList.add("move-center");
      this.centerImageContainer.classList.add("move-right");
      this.rightImageContainer.classList.add("move-left");
      this.imagesFromLeftToRight = [
        this.rightImageContainer.firstElementChild as HTMLImageElement,
        this.leftImageContainer.firstElementChild as HTMLImageElement,
        this.centerImageContainer.firstElementChild as HTMLImageElement,
      ];
    }
  }

  private removeTransitionClassesAfterTransitionFinished(): void {
    if (!this.movingLeft) {
      this.rightImageContainer.classList.add("image-center");
      this.rightImageContainer.classList.remove("image-right");
      this.centerImageContainer.classList.add("image-left");
      this.centerImageContainer.classList.remove("image-center");
      this.leftImageContainer.classList.add("image-right");
      this.leftImageContainer.classList.remove("image-left");
    } else {
      this.leftImageContainer.classList.add("image-center");
      this.leftImageContainer.classList.remove("image-left");
      this.centerImageContainer.classList.add("image-right");
      this.centerImageContainer.classList.remove("image-center");
      this.rightImageContainer.classList.add("image-left");
      this.rightImageContainer.classList.remove("image-right");
    }
    this.leftImageContainer.classList.remove(...["move-left", "move-center", "move-right"]);
    this.centerImageContainer.classList.remove(...["move-left", "move-center", "move-right"]);
    this.rightImageContainer.classList.remove(...["move-left", "move-center", "move-right"]);
  }

  public setImagesWrapper(): void {
    const imageCount = this.gallery.imageFiles.length;
    let indx = this.imageIndex % imageCount;
    if (indx < 0) indx += imageCount;
    this.displayImageIndex = indx + 1; // set the display index
    let prevIdx = (indx - 1) % imageCount;
    if (prevIdx < 0) prevIdx += imageCount;
    let nxtIdx = (indx + 1) % imageCount;
    if (nxtIdx < 0) nxtIdx += imageCount;

    this.imagesFromLeftToRight[0].src = `${this.imageLocation}/${this.authService.user.id}/${this.path}/${this.gallery.imageFiles[prevIdx]}`;
    this.imagesFromLeftToRight[1].src = `${this.imageLocation}/${this.authService.user.id}/${this.path}/${this.gallery.imageFiles[indx]}`;
    this.imagesFromLeftToRight[2].src = `${this.imageLocation}/${this.authService.user.id}/${this.path}/${this.gallery.imageFiles[nxtIdx]}`;
  }

  private setImages = (): void => {
    this.setImagesWrapper();
  };

  private imageContainerShowing = false;
  private displayImageContainer(): void {
    this.imageContainerShowing = true;
    (this.imageContainer as HTMLElement).style.display = "grid";
  }

  @LogEvent("Close image")
  private hideImageContainer(): void {
    if (this.isZoomedIn) this.zoomImage(); // just make sure we're zoomed out
    this.imageContainerShowing = false;
    (this.imageContainer as HTMLElement).style.display = "none";
  }

  @LogEvent("Zoom used on image")
  private zoomImage(xCoord?: number, yCoord?: number): void {
    // this.imagesFromLeftToRight[1].style;
    if (this.isZoomedIn) {
      this.imagesFromLeftToRight[1].style.transform = `translate3D(0px, 0px, 0px) scale(${1})`;
      this.isZoomedIn = false;
    } else {
      const scale = 1.5; //TODO: var(--zoom-scale);
      const image = this.imagesFromLeftToRight[1];

      if (!xCoord && !yCoord) {
        // if gven no coords, simulate perfect center
        xCoord = image.clientWidth / 2;
        yCoord = image.clientHeight / 2;
      }

      // don't forget the parent offset when calculating where in image click was made
      const clickInImageX = xCoord - (image.offsetParent as HTMLElement).offsetLeft;
      const distX = clickInImageX - image.clientWidth / 2;
      let scaledDistX = scale * distX;

      // check if width translation creates black borders, if so clamp to edge
      const blackVerticalBorder = Math.abs(scaledDistX) > (image.width * scale - window.innerWidth) / 2;
      if (image.width * scale < window.innerWidth) {
        scaledDistX = 0; // the zoomed image fiths inside window width, don't move it left or right
      } else if (blackVerticalBorder) {
        if (scaledDistX > 0) {
          scaledDistX = (image.width * scale - window.innerWidth) / 2;
        } else scaledDistX = -(image.width * scale - window.innerWidth) / 2;
      }

      // don't forget the parent offset when calculating where in image click was made
      const clickInImageY = yCoord - (image.offsetParent as HTMLElement).offsetTop;
      const distY = clickInImageY - image.clientHeight / 2;
      let scaledDistY = scale * distY;

      // check if width translation creates black borders, if so clamp to edge
      const blackHorizontalBorder = Math.abs(scaledDistY) > (image.height * scale - window.innerHeight) / 2;
      if (image.height * scale < window.innerHeight) {
        scaledDistY = 0; // the zoomed image fiths inside window height, don't move it up or down
        // } else if (Math.abs(scaledDistY) + image.height * scale > window.innerHeight) {
      } else if (blackHorizontalBorder) {
        if (scaledDistY > 0) {
          scaledDistY = (image.height * scale - window.innerHeight) / 2;
        } else scaledDistY = -(image.height * scale - window.innerHeight) / 2;
      }

      this.imagesFromLeftToRight[1].style.transform = `translate3D(${-scaledDistX}px, ${-scaledDistY}px, 0px) scale(${scale})`;

      this.isZoomedIn = true;
    }
  }
}
