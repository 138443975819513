import { AuthService, IAuthService } from "../../../../common/authService";
import { ISubscriptionService, SubscriptionService } from "../../../../common/subscriptionService";

export class UserCard {

  public get hasNonTrialSubscription() {
    return this.subscriptionService.hasValidSubscription;
  }

  public get billingPortalLink() {
    return this.subscriptionService.stripeSubscriptionManagementLink;
  }

  public get subscriptionLevel() {
    return this.subscriptionService.subscriptionLevel;
  }

  constructor(
    @IAuthService public readonly authService: AuthService, // obs! public!
    @ISubscriptionService private readonly subscriptionService: SubscriptionService
  ) { }
}