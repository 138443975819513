import * as msal from "@azure/msal-browser";
import { AccountInfo, AuthError } from "@azure/msal-browser";
import jwt_decode from "jwt-decode";

import { DI, IEventAggregator, EventAggregator } from "aurelia";

import { APIResult } from "./network/apiResult";
import { ConfigService, IConfigService } from "./configService";
import { EventType } from "./eventType";
import { User } from "./interfaces/User";
import { LogService, ILogService } from "./logging/logService";

export class AuthService {

  private _msalInstance: msal.PublicClientApplication;
  public get msalInstance(): msal.PublicClientApplication {
    return this._msalInstance;
  }
  public set msalInstance(v: msal.PublicClientApplication) {
    this._msalInstance = v;
  }

  private _isLoggedIn = false;
  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  private _user = {} as User;
  public get user(): User {
    return this._user;
  }

  public set user(usr) {
    this._user = usr;
    this._isLoggedIn = true;
  }

  public get userName(): string {
    return this._user.name;
  }

  public get accessToken(): Promise<string> {
    return this.getAccessToken();
  }

  private async getAccessToken(): Promise<string> {
    const myAccounts: AccountInfo[] = this.msalInstance.getAllAccounts();
    if (!myAccounts || myAccounts.length == 0) return;
    this.msalInstance.setActiveAccount(myAccounts[0]);

    const tokenRequest = {
      scopes: this.configService.getProperty("azureAD.scopes") as string[],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    };
    const tokenResponse = await this.msalInstance
      .acquireTokenSilent(tokenRequest)
      .then((tokenResponse) => tokenResponse)
      .catch((error) => {
        // TODO: handle this?
        return this.handleLoginError(error);
      });

    if (tokenResponse) {
      if (tokenResponse?.accessToken) {
        const decoded = jwt_decode(tokenResponse?.accessToken);
        this._isAdmin = decoded['extension_WebAdmin'] === "true";
      }
      return tokenResponse?.accessToken;
    }
    throw new Error("Could not get valid token :(");
  }

  public get idToken(): string {
    throw new Error("Error, no id token!");
  }

  private _isAdmin = false;
  public get isAdmin(): boolean {
    return this._isAdmin;
  }


  constructor(
    @IConfigService private readonly configService: ConfigService,
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @ILogService private readonly logService: LogService
  ) {
    this.eventAggregator.subscribe("userFetched", (response: APIResult) => this.userLoggedIn(response));
    this.initAuth();
  }

  /**
   * Set up Auth instance
   *
   * @memberof AuthService
   */
  initAuth(): void {
    const msalConfig = {
      auth: {
        authority: this.configService.getProperty("azureAD.authority") as string,
        clientId: this.configService.getProperty("azureAD.clientId") as string,
        knownAuthorities: this.configService.getProperty("azureAD.knownAuthorities") as string[], // array of URIs that are known to be valid
        redirectUri: this.configService.getProperty("azureAD.callbackUri") as string,
      },
      cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
      },
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);

    this.msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        // Check if the tokenResponse is null
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
        // If the tokenResponse === null, you are not coming back from an auth redirect.

        if (!tokenResponse) return;

        const accounts = this.msalInstance.getAllAccounts();
        if (!accounts || accounts.length == 0) {
          //TODO: need to login again
          this._isLoggedIn = false;
        } else {
          this.msalInstance.setActiveAccount(accounts[0]);
          this.eventAggregator.publish("userLoggedIn" as EventType);
          this.logService.logEvent("User logged in");
        }
      })
      .catch((error) => {
        // handle error, either in the library or coming back from the server
        this.logService.logEvent("User failed to log in");
        this.logService.logException(error, 2);

        const accounts = this.msalInstance.getAllAccounts();
        if (!accounts || accounts.length == 0) {
          //TODO: need to login again
          this._isLoggedIn = false;
        } else {
          this.msalInstance.setActiveAccount(accounts[0]);
          this.eventAggregator.publish("userLoggedIn" as EventType);
          this.logService.logEvent("User logged in");
        }
      });
  }

  /**
   * Set up Auth instance
   *
   * @memberof AuthService
   */
  public async logIn(): Promise<void> {
    const loginRequest = {
      scopes: this.configService.getProperty("azureAD.scopes") as string[],
    };

    try {
      this.msalInstance.loginRedirect(loginRequest);
    } catch (err) {
      // handle error
    }
  }

  /**
   * Log the user out, then redirect to base href
   *
   * @memberof AuthService
   */
  public async logOut() {
    this.msalInstance.logoutRedirect();
  }

  /**
   * Used on startup, if the user has been logged on before they won't have to press "log in"
   *
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public async logInSilent(): Promise<void> {
    const loginRequest = {
      scopes: this.configService.getProperty("azureAD.scopes") as string[],
    };

    try {
      const accounts = this.msalInstance.getAllAccounts();
      if (!accounts || accounts.length == 0) {
        this._isLoggedIn = false;
      } else {
        this.msalInstance.setActiveAccount(accounts[0]);
        this.msalInstance.acquireTokenSilent(loginRequest);
        this.eventAggregator.publish("userLoggedIn" as EventType);
      }
    } catch (err) {
      // ignore any errors
    }
  }

  // TODO: Testmethod - Remove
  public async getToken(): Promise<void> {
    const myAccounts: AccountInfo[] = this.msalInstance.getAllAccounts();
    if (!myAccounts || myAccounts.length == 0) return;
    this.msalInstance.setActiveAccount(myAccounts[0]);

    const tokenRequest = {
      scopes: this.configService.getProperty("azureAD.scopes") as string[],
      forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    };

    this.msalInstance
      .acquireTokenSilent(tokenRequest)
      .then((tokenResponse) => tokenResponse.accessToken)
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Handles userLoggedIn event
   *
   * Notify if failed login, or set up the user
   * @private
   * @param {APIResult} response
   * @memberof AuthService
   */
  private userLoggedIn(response: APIResult): void {
    if (!response.ok) {
      this.eventAggregator.publish("uiNotificationError" as EventType, "Failed to log in 😲");
    } else {
      this.user = response.data as User;

      // log in and user fetch ok, signal full hydration
      this.eventAggregator.publish("hydrateApp" as EventType, "👍");
    }
  }

  /**
   * Decodes a JWT token
   *
   * @private
   * @param {string} token
   * @returns {object}
   * @memberof AuthService
   */
  private decodeJWTToken(token: string): object {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const result = JSON.parse(jsonPayload);
    return result;
  }

  private handleLoginError(error: AuthError): void {
    if (error.errorCode === "consent_required" || error.errorCode === "interaction_required" || error.errorCode === "login_required") {
      const tokenRequest = {
        scopes: this.configService.getProperty("azureAD.scopes") as string[],
        forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
      };

      this.msalInstance.loginRedirect(tokenRequest);
    }
  }
}

export const IAuthService = DI.createInterface<AuthService>("IAuthService", (x) => x.singleton(AuthService));
