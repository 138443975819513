import { bindable } from 'aurelia';

import Chart from 'chart.js/auto';

export class WeekGraph {
  @bindable public title = "";
  @bindable public label = "";
  @bindable public data = [];
  public chart: HTMLElement;
  public dates = [];
  public counts = [];

  bound() {
    if (this.data.length > 0) {
      this.dataChanged();
    }
    this.init();
  }

  private dataChanged() {
    this.dates = this.data.map(({ date }) => this.formatDateLabel(date));
    this.counts = this.data.map(({ count }) => count);
  }

  private init() {
    new Chart(this.chart as HTMLCanvasElement, {
      type: 'line',
      data: {
        labels: this.dates,
        datasets: [{
          label: `${this.label}`,
          data: this.counts,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  private formatDateLabel(date: Date): string {
    return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
  }
}