import { IRouteableComponent, Parameters } from "@aurelia/router";
import { IAuthService, AuthService } from "../../../../../common/authService";
import { ILogService, LogService } from "../../../../../common/logging/logService";
import { IAdminService, AdminService } from "../../../../../common/adminService";

export class UserProfile implements IRouteableComponent {
  user: User;

  constructor(
    @IAuthService private readonly authService: AuthService,
    @ILogService private readonly logService: LogService,
    @IAdminService private readonly adminService: AdminService,
  ) { }

  async canLoad() {
    // only load for admins
    if (!this.authService.isAdmin) {
      this.logService.logTrace("not admin - decline loading!");
      return "/profile-page";
    }
    return true;
  }

  async loading(parameters: Parameters) {
    const userId = parameters.userId;
    this.user = await this.adminService.getUserProfile(userId as string);
  }
}