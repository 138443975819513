import { bindable } from "aurelia";
import { IRouter, Router } from "@aurelia/router";

import { AuthService, IAuthService } from "./../../../../common/authService";
import { ConfigService, IConfigService } from "../../../../common/configService";
import { IGallery } from "../../../../common/interfaces/IGallery";

export class GalleryComponent {
  @bindable gallery: IGallery;
  public thumbcolumns = 3;
  public imageLocation: string;
  public container = "";

  constructor(
    @IConfigService private readonly config: ConfigService,
    @IAuthService private readonly authService: AuthService,
    @IRouter private readonly router: Router
  ) {
    this.imageLocation = this.config.getProperty("imageLocation") as string;
    this.container = this.authService.user.id;
  }

  bound() {
    this.thumbcolumns = +getComputedStyle(document.documentElement).getPropertyValue("--thumb-columns").trim();
  }

  private loading = false;
  public async navigate() {
    if (this.loading) return;
    this.loading = true;
    await this.router.load(`/gallery(${this.gallery.id})`);
    this.loading = false;
    return;
  }
}
