import { IContainer } from "aurelia";
import { AuthService, IAuthService } from "../../../../common/authService";
import { IRouter, Router } from "@aurelia/router";

export class TopBar {
  constructor(
    @IContainer private readonly container: IContainer,
    @IRouter private readonly router: IRouter,
    @IAuthService private readonly authService: AuthService
  ) { }


  // async navToModuleTest(param: string): Promise<void> {
  //   if (param === "Test") {
  //     const mod = await import("../../../test-page/test-page");
  //     this.container.register(mod);
  //     this.router.load("test-page");
  //   }

  //   // if (param === "links") {
  //   //   const linksModule = await import("./modules/top-level/links");
  //   //   this.container.register(linksModule);
  //   //   this.router.load("link-page");
  //   // }
  // }
}
