<h1>Feedback Details</h1>

<div if.bind="feedback">
  <p>
    <span class="label"> Id </span>
    <span class="value"> ${feedback.id} </span>
  </p>
  <p>
    <span class="label"> Created </span>
    <span class="value"> ${feedback.createDate | displayDate} </span>
  </p>
  <p>
    <span class="label"> User Id </span>
    <span class="value"> ${feedback.userId} </span>
  </p>
  <p>
    <span class="label"> Subject </span>
    <span class="value"> ${feedback.subject} </span>
  </p>
  <p>
    <span class="label"> Feedback </span>
    <textarea class="value" rows="8"> ${feedback.feedback} </textarea>
  </p>
</div>
