<!-- profile img -->
<div class="profile-pic">
  <img src="" alt="" />
</div>

<div class="info-box">
  <div class="name">${authService.userName}</div>
</div>

<div class="billing-link">
  <div if.bind="subscriptionLevel !== 'Invalid'">Subscription: ${subscriptionLevel}</div>
  <a if.bind="hasNonTrialSubscription" href.bind="billingPortalLink" external target="_blank">Billing Portal</a>

  <div if.bind="subscriptionLevel === 'Invalid'"><em>You have no valid subscription.</em></div>
  <a if.bind="subscriptionLevel === 'Invalid'" load="/price-page"><strong>Subscribe!</strong></a>
</div>