<sl-dialog ref="dialog" label="Change Folder">
  <form ref="changeFolderForm">
    <h3>${gallery.title}</h3>

    <sl-select label="Destination Folder" name="folderId" value.bind="gallery.folderId" hoist>
      <sl-icon slot="prefix" name="folder-symlink"></sl-icon>
      <sl-option repeat.for="folder of linkService.folders" value.bind="folder.id">${folder.name} </sl-option>
    </sl-select>

    <br />

    <sl-button click.trigger="save()" class="pull-right" loading.bind="loading">Save</sl-button>
  </form>
</sl-dialog>
