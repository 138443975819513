import SLDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";
import { IEventAggregator, EventAggregator, bindable, IDisposable } from "aurelia";

import { IFolderService, FolderService } from "../../../../common/folderService";
import { ILogService, LogService } from "../../../../common/logging/logService";
import { INotificationService, NotificationService } from "../../../../common/notificationService";
import { Folder } from "../../../../common/interfaces/IFolder";
import { IUIFolder } from "../../../../common/interfaces/IUIFolder";
import { EventType } from "../../../../common/eventType";

export class DeleteFolder {
  @bindable folder: Folder & IUIFolder;

  public confirmDialog: SLDialog;
  public confirmForm: HTMLFormElement;
  loading: boolean;
  eventSubscriptions: IDisposable[] = new Array<IDisposable>();

  constructor(
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IFolderService private readonly folderService: FolderService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService
  ) {
    this.eventSubscriptions.push(
      this.eventAggregator.subscribe("deleteFolder" as EventType, (folder: Folder) => {
        this.folder = folder;
        this.confirmDialog.show();
      }));
  }

  public unbinding(): void {
    this.eventSubscriptions.forEach((s) => s.dispose());
  }

  public async deleteConfirmed(): Promise<void> {
    this.loading = true;
    const deleted = await this.folderService.deleteFolder(this.folder, "link");
    this.loading = false;
    if (deleted) {
      this.notificationService.info({
        title: "Folder deleted",
        message: `Folder ${this.folder.name} was successfully deleted`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Fodler deleted: ${this.folder.name}`);
    } else {
      this.notificationService.error({
        title: "Folder delete failed",
        message: `Folder ${this.folder.name} was not deleted!
        Reason unknown - try again later. If the problem persists, contact support.`,
        type: "info",
        date: new Date(),
        url: ""
      });
      this.logService.logEvent(`Failed to delete folder: ${this.folder.name}`);
    }

    this.confirmDialog.hide();
  }

}