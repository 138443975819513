<div id="content">

  <h3>Latest saved galleries</h3>

  <div if.bind="latestGalleryPreviews.length===0 && !preRenderOk">
    <loading-spinner></loading-spinner>
  </div>
  <!-- fragment slot -->
  <div else>
    <div ref="grid" class="g" blur.class="!adminService.showFrontPageGalleries"
      keypress.trigger="handleKeyInput($event)">
      <template if.bind="!preRenderOk" repeat.for="prev of latestGalleryPreviews">
        <div class="t" click.trigger="thumbClicked($event)" data-id.bind="prev.id" tabindex="0">
          <img src.bind=`${imageLoc}/gpw/${prev.thumbnail}` alt.bind="prev.title" title.bind="prev.title"
            loading="lazy">
        </div>
      </template>
    </div>
  </div>

</div>