<h1>${user.name}</h1>

<div if.bind="user">
  <p>
    <span class="label"> Email </span>
    <span class="value"> ${user.email} </span>
  </p>
  <p>
    <span class="label"> Last Loggedin </span>
    <span class="value"> ${user.lastLoggedIn | displayDate} </span>
  </p>
  <p>
    <span class="label"> Id </span>
    <span class="value"> ${user.id} </span>
  </p>
  <p>
    <span class="label"> Create Date </span>
    <span> ${user.createDate | displayDate} </span>
  </p>
  <p>
    <span class="label"> Link Count </span>
    <span class="value"> ${user.linkCount} </span>
  </p>
  <p>
    <span class="label"> Gallery Count </span>
    <span class="value"> ${user.galleryCount} </span>
  </p>
  <p>
    <span class="label"> Image Count </span>
    <span class="value"> ${user.imageCount} </span>
  </p>
  <p>
    <span class="label"> Used Space </span>
    <span class="value" innerhtml.bind="user.usedKB | memoryUnit:'kb-to-mb'"></span>
  </p>

  <h2>Subscription Status</h2>
  <p>
    <span class="label"> stripeCustomerId </span>
    <span class="value">${user.stripeCustomerId}</span>
  </p>
  <p>
    <span class="label"> stripeSubscriptionStatus </span>
    <span class="value">${user.stripeSubscriptionStatus}</span>
  </p>

  <p>
    <span class="label"> stripeDelinquent </span>
    <span class="value">${user.stripeDelinquent}</span>
  </p>
  <p>
    <span class="label"> stripeInvoiceAmount </span>
    <span class="value">${user.stripeInvoiceAmount}</span>
  </p>
  <p>
    <span class="label"> stripeSubscriptionDescription </span>
    <span class="value">${user.stripeSubscriptionDescription}</span>
  </p>
  <p>
    <span class="label"> stripeSubscriptionEndedAt </span>
    <span class="value">${user.stripeSubscriptionEndedAt}</span>
  </p>
  <p>
    <span class="label"> stripeSubscriptionId </span>
    <span class="value">${user.stripeSubscriptionId}</span>
  </p>

  <p>
    <span class="label"> stripeSubscriptionItem </span>
    <span class="value">${user.stripeSubscriptionItem}</span>
  </p>

  <p>
    <span class="label"> timestamp </span>
    <span class="value">${user.timestamp}</span>
  </p>
</div>
