import { DI } from "aurelia";
import { IGalleryPreview } from "./interfaces/IGalleryPreview";

import { DataService, IDataService } from "./network/dataService";
import { ILogService, LogService } from "./logging/logService";
import { error } from "console";
import { User } from "./interfaces/User";

export class AdminService {

  public statistics: SystemStatistics = undefined;

  public frontpageGalleriesHide = true;
  public get showFrontPageGalleries() {
    const isLocalHost = window.location.href.includes("localhost");
    return !(isLocalHost && this.frontpageGalleriesHide);
  }

  private _frontPageGalleries: Array<IGalleryPreview> = undefined;

  constructor(
    @IDataService private readonly dataService: DataService,
    @ILogService private readonly logService: LogService
  ) { }

  public async frontPageData() {
    const frontPage = await this.dataService.getFrontPage(150);
    if (frontPage.ok) {
      this._frontPageGalleries = frontPage.data as Array<IGalleryPreview>;
      return this._frontPageGalleries as Array<IGalleryPreview>;
    }
  }

  public async adminStatisticsData() {
    const statisticsResult = await this.dataService.getAdminStatistics();
    if (statisticsResult.ok) {
      this.statistics = statisticsResult.data as SystemStatistics;
      return this.statistics;
    }

    this.logService.logException(new error(`AdminService - Failed to get statistics data!`));
  }

  public async toggleVivibilityOnFrontPage($index: number) {
    const gallery = this._frontPageGalleries[$index];

    const result = await this.dataService.toggleVivibilityOnFrontPage(gallery.id, !gallery.allowedOnFrontPage);
    if (result.ok) {
      gallery.allowedOnFrontPage = result.data as boolean;
      return result.data;
    }

    this.logService.logException(new error(`AdminService - Failed to toggle visibility of gallery with id ${$index}`));
  }

  public async getGallery(id: string): Promise<IGalleryPreview> {
    const gallery = this._frontPageGalleries.find(g => g.id === id);
    if (gallery) {
      return gallery;
    }

    this.logService.logException(new error(`AdminService - Failed to get gallery from server with id ${id}`));
  }

  public async getAllUsers(): Promise<Array<User>> {
    const result = await this.dataService.getAdminUserProfiles();
    if (result.ok) {
      return result.data as Array<User>;
    }

    this.logService.logException(new error(`AdminService - Failed to get users from server`));
  }

  public async getUserProfile(userId: string) {
    const userResult = await this.dataService.getAdminUserProfile(userId);
    if (userResult.ok) {
      return userResult.data;
    }

    this.logService.logException(new error(`AdminService - Failed to get user profile for user: ${userId}`));
  }

  public async recalculateSystemUsage(user): Promise<User> {
    const userResult = await this.dataService.postRecalculateSystemUsageForUser(user.id);
    if (userResult.ok) {
      return userResult.data as User;
    }

    this.logService.logException(new error(`AdminService - Failed to get recalculate ystem resource data for user: ${user.id}`));
  }

  public async getFeedback() {
    const res = await this.dataService.getFeedback();
    if (res.ok) {
      return res.data;
    }
    throw "Something went wrong when trying to get feedback 😟";
  }

  public async deleteFeedback(feedback: any) {
    const res = await this.dataService.deleteFeedback(feedback);
    return res.ok;
  }

}

export const IAdminService = DI.createInterface<AdminService>("IAdminService", (x) => x.singleton(AdminService));
