import { IDisposable, HttpClient, IEventAggregator, EventAggregator, DI } from "aurelia";

import { APIResult } from "./apiResult";
import { AuthService, IAuthService } from "../authService";
import { EventType } from "../eventType";
import { Folder } from "../interfaces/IFolder";
import { Link } from "../interfaces/ILink";
import { RequestBuilder, IRequestBuilder } from "./requestBuilder";
import { ResourceType } from "./resourceType";
import { IGallery } from "../interfaces/IGallery";
import { ILogService, LogService } from "../logging/logService";
import { Constants } from "../constants";
import { User } from "../interfaces/User";

export class DataService {
  private eventSubscriptions: IDisposable[] = new Array<IDisposable>();

  constructor(
    private httpClient: HttpClient,
    @IEventAggregator private eventAggregator: EventAggregator,
    @IAuthService private authService: AuthService,
    @IRequestBuilder private reqBuilder: RequestBuilder,
    @ILogService private logService: LogService
  ) {
    this.eventSubscriptions = this.setupEventHandlers();
  }

  /**
   * set up all event handlers
   *
   * @private
   * @memberof DataService
   */
  private setupEventHandlers(): IDisposable[] {
    const subs = new Array<IDisposable>();
    subs.push(this.eventAggregator.subscribe("userLoggedIn", () => this.getUser()));
    subs.push(this.eventAggregator.subscribe("getProfile", (profile: object) => this.getProfile(profile)));
    subs.push(this.eventAggregator.subscribe("downloadInCloud", (link: Link) => this.downloadInCloud(link)));
    subs.push(this.eventAggregator.subscribe("deleteLink", (link: Link) => this.deleteLink(link)));
    return subs;
  }

  // #region links

  public async getLink(linkId: string): Promise<APIResult> {
    return await this.requestResource("linkGet", { linkId: linkId });
  }

  public async createLink(link: string): Promise<APIResult> {
    return await this.requestResource("linkCreate", { link: link });
  }

  public async updateLink(link: Link): Promise<Link> {
    const result = await this.requestResource("linkUpdate", link);
    if (result.ok) {
      return result.data as Link;
    }
    return null;
  }

  public async createFolder(folderName: string): Promise<Folder> {
    const result = await this.requestResource("createFolder", { folderName: folderName });
    if (result.ok) {
      return result.data as Folder;
    }
    return null;
  }

  public async updateFolder(folder: Folder): Promise<Folder> {
    const result = await this.requestResource("updateFolder", folder);
    if (result.ok) {
      return result.data as Folder;
    }
    return null;
  }

  public async getLinksInBaseFolder(): Promise<Link[]> {
    const result = await this.requestResource("linkGetAll", null);
    if (result.ok) {
      return result.data as Link[];
    }
    return null;
  }

  public async getLinksInFolder(folderId: string): Promise<Link[]> {
    const result = await this.requestResource("getLinksInFolder", { folderId: folderId });
    if (result.ok) {
      return result.data as Link[];
    }
    return null;
  }

  public async getAllFolders(): Promise<Array<Folder>> {
    const result = await this.requestResource("foldersGetAll", null);
    if (result.ok) {
      return result.data as Array<Folder>;
    }
    return null;
  }

  public async deleteFolder(folder: Folder): Promise<boolean> {
    const result = await this.requestResource("folderDelete", { folderId: folder.id });
    if (result.ok) {
      return !result.data as boolean;
    }
    return false;
  }

  private async downloadInCloud(link: Link): Promise<void> {
    this.requestResourceDeffered("downloadInCloud", { id: link.id }, "downloadInCloudResponse");
  }

  public async deleteLink(link: Link): Promise<APIResult> {
    // this.requestResourceDeffered("linkDelete", { id: link.id }, "linkDeletedResponse");
    return await this.requestResource("linkDelete", { id: link.id });
  }
  // #endregion

  // #region galleries

  public async getGalleriesInFolder(folderId: string): Promise<IGallery[]> {
    const result = await this.requestResource("getGalleriesInFolder", { folderId: folderId });
    if (result.ok) {
      return result.data as IGallery[];
    }
    return null;
  }

  public async deleteGallery(galleryId: string): Promise<APIResult> {
    return await this.requestResource("deleteGallery", { galleryId: galleryId });
  }

  public async downloadGallery(galleryId: string) {
    return await this.requestResource("downloadGallery", { galleryId: galleryId });
  }

  public async getGallery(galleryId: string): Promise<APIResult> {
    return await this.requestResource("getGallery", { galleryId: galleryId });
  }

  public async updateGallery(gallery: IGallery): Promise<APIResult> {
    return await this.requestResource("updateGallery", gallery);
  }

  public async updateGalleryViewCount(galleryId: string): Promise<APIResult> {
    return await this.requestResource("galleryOpened", { galleryId: galleryId });
  }

  public async createGallery(data: object): Promise<APIResult> {
    return await this.requestResource("uploadFiles", data);
  }

  public async downloadInCloudFromURL(url: string): Promise<APIResult> {
    return await this.requestResource("downloadInCloudFromURL", { link: url });
  }

  public async renameImage(galleryId: string, fileName: string, newFileName: string) {
    return await this.requestResource("renameImage", { galleryId: galleryId, originalFileName: fileName, newFileName: newFileName });
  }

  public async copyFiles(fromGalleryId: string, toGalleryId: string, files: string[]): Promise<APIResult> {
    return await this.requestResource("copyFiles", { fromGalleryId: fromGalleryId, toGalleryId: toGalleryId, files: files });
  }
  // #endregion

  // #region landing page

  public async getLatestGalleries(count: number): Promise<APIResult> {
    return this.requestResource("getLatestGalleries", { count: count });
  }

  // #endregion

  // #region users 

  public async refreshUser(): Promise<User> {
    return this.requestResource("userGet").then((result) => {
      if (result.ok) {
        this.authService.user = result.data as User;
      }
      return this.authService.user;
    });
  }

  private async getUser(): Promise<void> {
    this.requestResourceDeffered("userGet", null, "userFetched");
  }

  // #endregion

  // #region terms of usage (tou)

  public async getLatestTOUPublishDate() {
    return this.requestResource("getTOUPublishDate");
  }

  public async acceptTOU() {
    return this.requestResource("acceptTOU");
  }

  public async rejectTOU() {
    return this.requestResource("rejectTOU");
  }

  // #endregion

  // #region profiles

  private async getProfile(args: object): Promise<void> {
    this.requestResourceDeffered("profileGet", args, "profileFetched");
  }

  // #endregion

  // #region gallery preview clicked
  public sendStatus(status: { time: Date; galleryPreviewId: string }[]): void {
    this.requestResource("sendStatus", status).then();
  }

  public async getGalleryPreview(galleryPreviewId: string) {
    return await this.requestResource("getGalleryPreview", { id: galleryPreviewId });
  }
  // #endregion

  // #region Search
  public async search(fragment: string): Promise<APIResult> {
    return await this.requestResource("search", { fragment: fragment });
  }
  //#endregion

  // #region Admin
  public async getAdminStatistics(): Promise<APIResult> {
    return await this.requestResource("adminGetSystemStatistics");
  }

  public async getFrontPage(count: number): Promise<APIResult> {
    return await this.requestResource("getFrontPage", { "count": count });
  }

  public async toggleVivibilityOnFrontPage(galleryId, allowed): Promise<APIResult> {
    return await this.requestResource("deleteFromFrontPage", { "galleryId": galleryId, allowed: allowed });
  }

  public async getAdminUserProfiles() {
    return await this.requestResource("adminGetAllUsers");
  }

  public async getAdminUserProfile(userId: string) {
    return await this.requestResource("adminGetUserProfile", { "userId": userId });
  }

  public async postRecalculateSystemUsageForUser(userId: string) {
    return await this.requestResource("adminRecalculateSystemUsageForUser", { "userId": userId });
  }

  public async getFeedback() {
    return await this.requestResource("feedbackGet");
  }

  public async deleteFeedback(feedback) {
    return await this.requestResource("feedbackDelete", { feedbackId: feedback.id });
  }

  //#endregion

  // #region User Profiles
  public async getFullUserProfile() {
    const res = await this.requestResource("profileGet");
    if (res.ok) return res.data;
    throw ("Failed to get profile info");
  }

  public async sendFeedback(subject: string, feedback: string) {
    const res = await this.requestResource("feedbackPost", { subject: subject, feedback: feedback });
    return res.ok;
  }
  // #endregion

  // #region Subscription

  public async hasValidSubscription() {
    const res = await this.requestResource("hasValidSubscription");
    return res.ok;
  }

  //#endregion 


  /**
 * used to fetch api resources
 *
 * @param {ResourceType} resource
 * @param {object} [data]
 * @returns {Promise<IAPIResult>}
 * @memberof DataService
 */
  private async requestResource(resource: ResourceType, data?: object): Promise<APIResult> {
    const config = await this.reqBuilder.getConfiguration(resource);
    this.httpClient.configure(() => config);

    const requestInit: RequestInit = this.reqBuilder.buildRequestInit(resource, data);
    const route = this.reqBuilder.getRoute(resource, data);
    try {
      const response = await this.httpClient.fetch(route, requestInit);
      const apiResult = await this.createApiResult(response);
      return apiResult;
    } catch (error) {
      this.logService.logException(error, 4);
      return { ok: false, message: "Communication failed" } as APIResult;
    }
  }

  /**
   * Fire off an async request that, if it succeedes, will fire off a given event type with the data paylod
   * @param resourceType 
   * @param data 
   * @param fireEvent 
   * @returns 
   */
  private async requestResourceDeffered(resourceType: ResourceType, data: object, fireEvent: EventType): Promise<void> {
    const result = await this.requestResource(resourceType, data);
    if (result.ok) {
      this.eventAggregator.publish(fireEvent as EventType, result);
      return;
    } else {
      this.logService.logEvent(`Failed request for ResourceType: ${resourceType}`, result);
    }
  }

  // ------------------------------------

  /**
   * creates the API Result object from any given server response
   * @param response 
   * @returns 
   */
  private async createApiResult(response: Response): Promise<APIResult> {
    let apiResult: APIResult = {
      message: response.statusText,
      ok: response.ok,
      status: response.status,
    };

    if (response.status === 401) {
      // unathorized
      this.authService.logIn();
    }

    if (apiResult.status === 204) {
      // HTTPNoContent
      return apiResult;
    }

    apiResult = await this.getResponseData(response, apiResult);
    apiResult.message = response.statusText || apiResult?.data || "Something went wrong, please try again.😢";
    return apiResult;
  }

  /**
   * get the given response data from a response, check it's type and return the correct type
   * @param response 
   * @param apiResult 
   * @returns 
   */
  private async getResponseData(response: Response, apiResult: APIResult): Promise<APIResult> {
    const contentType = response.headers.get("content-type");
    if (!contentType) {
      // Exit if no content
      return apiResult;
    }

    if (contentType.includes("application/json")) {
      apiResult.type = "json";
      apiResult.data = await response.json();
    } else if (contentType.includes("text/plain") || contentType.includes("text/html")) {
      apiResult.type = "text";
      apiResult.data = await response.text();
    } else if (contentType.includes(Constants.APPLICATION_ZIP)) {
      apiResult.type = "blob";
      apiResult.data = await response.blob();
    } else if (contentType.includes(Constants.APPLICATION_STREAM)) {
      apiResult.type = "blob";
      apiResult.data = await response.blob();
    }

    else {
      throw new Error("Need to define type for APIResult - missing content type parse?");
    }

    return apiResult;
  }
}

export const IDataService = DI.createInterface<DataService>("IDataService", (x) => x.singleton(DataService));
