import { EventAggregator, IEventAggregator, bindable } from "aurelia";
import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { SlDialog } from "@shoelace-style/shoelace";

import { GalleryService, IGalleryService } from "../../../../common/galleryService";
import { IFolderService, FolderService } from "../../../../common/folderService";
import { IGallery } from "../../../../common/interfaces/IGallery";
import { Link } from "../../../../common/interfaces/ILink"; // Import the missing type 'xLink' from the appropriate module
import { LinkService, ILinkService } from "../../../../common/linkService"; // Import the missing 'LinkService' class from the appropriate module
import { NotificationService, INotificationService } from "../../../../common/notificationService"; // Import the missing 'NotificationService' class from the appropriate module
import { EventType } from "../../../../common/eventType";

export class MoveFolder {
  @bindable gallery: IGallery;
  @bindable link: Link;
  @bindable display: boolean;

  public dialog: SlDialog;
  public changeFolderForm: HTMLFormElement;

  public loading: boolean;

  constructor(
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IFolderService private readonly folderService: FolderService,
    @IGalleryService private readonly galleryService: GalleryService,
    @ILinkService public readonly linkService: LinkService,
    @INotificationService private readonly notificationService: NotificationService,
  ) { }

  public displayChanged(): void {
    this.dialog.show();
  }

  public save(): void {
    const formData = serialize(this.changeFolderForm);
    const folderId = formData["folderId"] as string;
    if (this.link) this.moveLink(folderId);
    if (this.gallery) this.moveGallery(folderId);
    this.dialog.hide();
  }

  public async moveGallery(destinationFolder: string): Promise<void> {
    if (!(this.gallery.folderId !== destinationFolder)) return;
    const originFolder = this.gallery.folderId;

    this.gallery.folderId = destinationFolder;
    this.loading = true;
    const result = await this.galleryService.updateGallery(this.gallery);
    this.loading = false;
    if (!result) {
      this.notificationService.warning("Failed to update gallery 😢");
      return;
    }

    // move the gallery from it's previous folder
    this.folderService.moveGallery(originFolder, this.gallery);
    this.notificationService.info({
      title: "Gallery updated",
      message: `Gallery ${this.gallery.title} was moved to a new folder.`,
      type: "info",
      date: new Date(),
      url: `gallery(${this.gallery.id})`
    });

    // publish an event that the gallery moved
    this.eventAggregator.publish("galleryMovedFolder" as EventType, this.gallery);
  }

  public async moveLink(destinationFolder: string): Promise<void> {
    if (!(this.link.folderId !== destinationFolder)) return;

    const originFolder = this.link.folderId;

    this.link.folderId = destinationFolder;
    this.loading = true;
    const result = await this.linkService.update(this.link);
    this.loading = false;
    if (!result) {
      this.notificationService.warning("Failed to update link 😢");
      return;
    }

    // move the link from it's previous folder
    this.folderService.moveLink(originFolder, this.link);

    this.notificationService.info({
      title: "Link updated",
      message: `Link ${this.link.title} was moved to a new folder.`,
      type: "info",
      date: new Date(),
      url: `${this.link.url}`
    });
  }
}
