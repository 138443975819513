<delete-images images.bind="selectedImages"></delete-images>

<div id="content" class="gallery">
  <div class="header">
    <div class="title-row">
      <span if.bind="previousGallery" class="clickable" click.trigger="navigateToPreviousGallery()">⬅️</span>
      <h3 title.bind="gallery.title">${gallery.title}</h3>
      <span if.bind="nextGallery" class="right clickable" click.trigger="navigateToNextGallery()">➡️</span>
    </div>

    <div class="info-row">
      <div title="Create Date" class="date">📅 ${gallery.createDate | displayDate}</div>
      <div title="Views" class="views">👀 ${gallery.views}</div>
      <div title="Image Count" class="count">🖼️ ${gallery.imageFiles.length}</div>
      <sl-dropdown class="actions">
        <sl-icon-button slot="trigger" name="three-dots-vertical" label="Menu"></sl-icon-button>

        <sl-menu sl-select.trigger="handleMenuSelect">
          <sl-menu-item value="changeFolder">Change Folder
            <sl-icon slot="prefix" name="folder-symlink"></sl-icon>
          </sl-menu-item>
          <sl-menu-item value="downloadGallery" disabled.bind="gallery.imageFiles.length===0">Download
            <sl-icon slot="prefix" name="cloud-download"></sl-icon>
          </sl-menu-item>
          <sl-menu-item value="editGallery" disabled.bind="gallery.imageFiles.length===0">Edit
            <sl-icon slot="prefix" name="pencil"></sl-icon>
          </sl-menu-item>

          <sl-divider></sl-divider>

          <sl-menu-item value="deleteGallery">Delete
            <sl-icon slot="prefix" name="trash"></sl-icon>
          </sl-menu-item>
        </sl-menu>
      </sl-dropdown>
    </div>

    <div class="edit-row" expanded.class="editMode" show.bind="editMode">
      <!-- The edit row -->
      <div>Selected: ${selectedImages}</div>

      <sl-button disabled.bind="selectedImages===0" click.trigger="deleteImages()">Delete</sl-button>

      <sl-button disabled.bind="selectedImages===0" click.trigger="copyFiles()">Copy</sl-button>

      <sl-button disabled.bind="selectedImages!==1" click.trigger="renameImage()">Rename</sl-button>

      <sl-icon name="x-lg" title="Close " class="clickable" click.trigger="toggleEditMode()"></sl-icon>
    </div>
  </div>

  <!-- <div class="editing-controls" if.bind="selectMode"></div> -->

  <!-- gallerycontainer not needed any more? -->
  <div ref="galleryContainer" class="thumb-grid">
    <template if.bind="gallery.thumbnails.length > 0 && !editMode">
      <!-- normal gallery -->
      <div repeat.for="thumb of gallery.thumbnails" class="thumbnail-container" click.trigger="thumbnailClicked($index)"
        tabindex="0" data-index.bind="$index">
        <img class="thumb" src.bind=`${imageLocation}/${authService.user.id}/${path}/${thumb}` loading="lazy"
          role="presentation">
      </div>
    </template>

    <template if.bind="gallery.selectedThumbnails.length > 0 && editMode">
      <!-- gallery in edit mode -->
      <div repeat.for="thumb of gallery.selectedThumbnails" class="thumbnail-container"
        click.trigger="thumbnailClicked($index)" tabindex="0" data-index.bind="$index">
        <img class="thumb" selected.class="thumb.selected"
          src.bind=`${imageLocation}/${authService.user.id}/${path}/${thumb.thumb}` loading="lazy" role="presentation">
        <span ref="getRefName($index)" class="filename"
          title.bind="gallery.imageFiles[$index]">${gallery.imageFiles[$index]}</span>
      </div>
    </template>
  </div>

  <div ref="imageContainer" class="image-container">
    <!-- image viewer -->
    <div class="full-page-bg"></div>

    <div class="image image-left">
      <img draggable="false" />
    </div>

    <div class="image image-center">
      <img draggable="false" />
    </div>

    <div class="image image-right">
      <img draggable="false" />
    </div>

    <div class="index-counter">
      <span>${displayImageIndex} / ${gallery.imageFiles.length}</span>
    </div>
  </div>
</div>

<move-folder gallery.bind="gallery" display.bind="showMoveFolder"></move-folder>

<gallery-delete gallery.bind="gallery" display.bind="showDeleteGallery"></gallery-delete>

<edit-filename file-name.two-way="fileName" display.bind="showEditFileName" gallery.bind="gallery"></edit-filename>

<copy-files gallery.bind="gallery" selected-images.bind="selectedFiles" display.bind="showCopyFiles"></copy-files>

<delete-images images.bind="imagesToBeDeleted" display.bind="showDeleteImages"></delete-images>
