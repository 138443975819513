<h1 class="center-headline">PixHeap - Safeguard Your Image Gems</h1>

<div class="two-column">
  <section>
    <h2>Creating image galleries has never been easier!</h2>
    <p>
      With our intuitive interface, you can effortlessly
      create galleries in two simple ways:
    </p>

    <ul>
      <li>
        <strong>From the Web</strong> - Download images directly from other websites and seamlessly add them to your
        PixHeap
        galleries. No more tedious manual uploads—just a few clicks, and your collection comes to life.
      </li>
      <li>
        <strong>Upload Your Own</strong> - Got a treasure trove of photos on your computer? Upload them to PixHeap, and
        watch as they transform into beautifully organized galleries.
      </li>
    </ul>

    <p>
      PixHeap: Your digital scrapbook for the web. Effortlessly save, organize, and access your favorite web pages and
      images. Anytime. Anywhere. Even when the original is gone. Welcome to a world of seamless web archiving.
    </p>
  </section>

  <div><img src="../../../../../resources/mobile_gallery_small.webp" alt="Awesome mobile gallery" class="right-column">
  </div>

</div>



<p>
  Our Platform: A user-friendly haven for web enthusiasts. Effortlessly save, categorize, and navigate through your
  favorite web pages and images. Experience world-class image surfing on your mobile device. Find what you need in a
  flash. Welcome to the future of web organization.
</p>

<div class="create-gallery">
  <img src="../../../../../resources/create_gallery.webp" alt="Create Gallery from a link" class="right-column">
  <p>Creating a gallery is a easy as pasting a link!</p>
</div>

<p>
  Our Commitment: Your privacy is our priority. We uphold stringent security protocols to ensure your information
  remains safe and confidential. Trust us to use your data solely for delivering a reliable and convenient service.
</p>