<nav class="wide-screen-menu">
  <a load="home" tabindex="0"><sl-icon name="house"></sl-icon></a>
  <!-- <a click.trigger="navToModuleTest('Test')">Test</a> -->
  <a class="hover" if.bind="authService.isLoggedIn" load="galleries-page" tabindex="0">Galleries</a>
  <a class="hover" if.bind="authService.isLoggedIn" load="link-page" tabindex="0">Links</a>
  <a class="hover" if.bind="authService.isLoggedIn" load="price-page">Pricing</a>
  <a class="hover" load="about" tabindex="0">About</a>
</nav>

<nav class="narrow-screen-menu">
  <a load="home" tabindex="0"><sl-icon name="house"></sl-icon></a>
  <sl-dropdown>
    <sl-button slot="trigger"><sl-icon name="list"></sl-icon></sl-button>
    <sl-menu>
      <sl-menu-item if.bind="authService.isLoggedIn"><a load="galleries-page">Galleries</a></sl-menu-item>
      <sl-menu-item if.bind="authService.isLoggedIn"><a load="link-page">Links</a></sl-menu-item>
      <sl-menu-item if.bind="authService.isLoggedIn"><a load="price-page">Pricing</a></sl-menu-item>
      <sl-menu-item><a load="about">About</a></sl-menu-item>
    </sl-menu>
  </sl-dropdown>
</nav>
<!-- <search-component type="gallery"></search-component> -->

<div class="user-status">
  <ui-notifications if.bind="authService.isLoggedIn"></ui-notifications>
  <user-status></user-status>
</div>
