import { bindable } from "aurelia";
import { watch } from '@aurelia/runtime-html';
import { FolderService, IFolderService } from "../../../../common/folderService";

export class GalleryFilter {
  @bindable filter = "created";
  public filters = {
    created: false,
    createdDesc: false,
    title: false,
    titleDesc: false,
    status: false,
    statusDesc: false,
  };

  constructor(@IFolderService public readonly folderService: FolderService) { }

  attached(): void {
    const filter = sessionStorage.getItem("galleryFilter");
    if (filter) this.filter = filter;
  }

  // @watch("filter")
  // public filterFunc(newVal: string, oldVal: string): void {
  //   this.folderService.sortActiveFolder(this.filter, "gallery");
  // }

  public createdClicked(): void {
    if (this.filters.created) {
      this.clearFilters();
      this.filters.createdDesc = true;
      this.filter = "createdDesc";
    } else {
      this.clearFilters();
      this.filters.created = true;
      this.filter = "created";
    }
  }

  public titleClicked(): void {
    if (this.filters.title) {
      this.clearFilters();
      this.filters.titleDesc = true;
      this.filter = "titleDesc";
    } else {
      this.clearFilters();
      this.filters.title = true;
      this.filter = "title";
    }
  }

  public statusClicked(): void {
    if (this.filters.status) {
      this.clearFilters();
      this.filters.statusDesc = true;
      this.filter = "statusDesc";
    } else {
      this.clearFilters();
      this.filters.status = true;
      this.filter = "status";
    }
  }

  @watch("filter")
  private setupFilters(): void {
    sessionStorage.setItem("galleryFilter", this.filter);

    this.clearFilters();
    this.filters[this.filter] = true;

    this.folderService.sortActiveFolder(this.filter, "gallery");
  }

  private clearFilters(): void {
    this.filters = {
      created: false,
      createdDesc: false,
      title: false,
      titleDesc: false,
      status: false,
      statusDesc: false,
    };
  }
}
