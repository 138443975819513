<sl-dialog ref="dialog" label="Rename File">
  <form ref="nameform">
    <h3>Rename</h3>
    <p>Old filename: ${fileName}</p>
    <sl-input ref="nameinput" label="New filename" name="filename" placeholder.bind="fileName" required
      autocomplete="off"></sl-input>

    <br>

    <sl-button disabled.bind="!canRename" click.trigger="confirm()" class="pull-right" loading.bind="loading"
      warning>Confirm</sl-button>
  </form>
</sl-dialog>