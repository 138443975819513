import { bindable, EventAggregator, IDisposable, IEventAggregator, observable } from "aurelia";
import { SlSelectEvent } from "@shoelace-style/shoelace/dist/events/events";

import { EventType } from "../../../../common/eventType";
import { FolderService, IFolderService } from "../../../../common/folderService";
import { Folder } from "../../../../common/interfaces/IFolder";
import { IUIFolder } from "../../../../common/interfaces/IUIFolder";

export class FolderComponent {
  @bindable folder: Folder & IUIFolder;
  @bindable type: "gallery" | "link";

  public foldercomponent: HTMLElement;
  public loading: boolean;

  constructor(
    @IEventAggregator private readonly eventAggregator: EventAggregator,
    @IFolderService private readonly folderService: FolderService
  ) { }

  public handleMenuSelect = (event: SlSelectEvent) => {
    switch (event.detail.item.value) {
      case "edit":
        this.eventAggregator.publish("editFolder" as EventType, this.folder);
        break;
      case "delete":
        this.eventAggregator.publish("deleteFolder" as EventType, this.folder);
        break;
    }
  }

  public async toggle(): Promise<void> {
    this.folder.expanded = !this.folder.expanded;

    if (this.folder.expanded) {
      await this.folderService.expandFolder(this.folder);
      // this.foldercomponent.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
    } else {
      this.folderService?.closeFolder(this.folder);
    }
  }


}
