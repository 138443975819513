import { DI } from "aurelia";

import { DataService, IDataService } from "./network/dataService";
import { Folder } from "./interfaces/IFolder";
import { IGallery } from "./interfaces/IGallery";
import { Link } from "./interfaces/ILink";
import { IUIFolder } from "./interfaces/IUIFolder";
import { LogService, ILogService } from "./logging/logService";
import { NotificationService, INotificationService } from "./notificationService";

export type FolderType = "link" | "gallery";

export class FolderService {
  public activeLinkFolder: Folder;
  public activeGalleryFolder: Folder;
  public activeLinkFilter = "created";
  public activeGalleryFilter = "created";
  public linkFolders: Array<Folder & IUIFolder>;
  public galleryFolders: Array<Folder & IUIFolder>;

  private DefaultFolderId = "00000000-0000-0000-0000-000000000000";

  constructor(
    @IDataService private readonly dataService: DataService,
    @ILogService private readonly logService: LogService,
    @INotificationService private readonly notificationService: NotificationService
  ) { }

  public async getFolders(count: "all" | number, type: FolderType): Promise<Array<Folder & IUIFolder>> {
    if (count === "all") {
      const folders = (await this.dataService.getAllFolders()) as Array<Folder & IUIFolder>;

      folders.forEach((folder) => {
        folder.links = [];
        folder.galleries = [];
        folder.expanded = false;
        folder.type = type;
      });

      this.sortFoldersByName(folders);

      if (!folders.find((f) => f.id === this.DefaultFolderId)) {
        // create the "default" folder
        const defaultFolder: Folder & IUIFolder = {
          id: this.DefaultFolderId,
          name: type === "link" ? "My Links" : "My Galleries",
          links: [],
          galleries: [],
          createDate: new Date(),
          editDate: new Date(),
          expanded: true,
          type: type,
        };
        folders.unshift(defaultFolder);

        if (type === "link") {
          this.activeLinkFolder = defaultFolder;
          this.linkFolders = folders;
        }

        if (type === "gallery") {
          this.activeGalleryFolder = defaultFolder;
          this.galleryFolders = folders;
        }
      }

      return folders;
    }

    this.logService.logException(new Error("FolderService - tried to call not implemented code"));
    throw new Error("FolderService - Not implemented");
  }

  public async getFolder(folderId: string, type: FolderType): Promise<Folder & IUIFolder> {
    if (type === "link") {
      if (!this.linkFolders) {
        await this.getFolders("all", "link");
      }
      return this.linkFolders.find((f) => f.id === folderId);
    }

    if (type === "gallery") {
      if (!this.galleryFolders) {
        await this.getFolders("all", "gallery");
      }
      return this.galleryFolders.find((f) => f.id === folderId);
    }
  }

  public closeFolder(folder: Folder & IUIFolder): void {
    this.logService.logEvent("FolderService - closeFolder");

    if (folder.type === "link") {
      folder.links?.splice(0, folder.links.length);
    } else if (folder.type === "gallery") {
      folder?.galleries?.splice(0, folder.galleries.length);
    }
  }

  // Expand folder clicked
  // get all links for it and put them in the folder
  public async expandFolder(folder: Folder & IUIFolder): Promise<void> {
    this.logService.logEvent("FolderService - expandFolder");

    if (folder.type === "link") {
      this.activeLinkFolder = folder;
      const links = await this.dataService.getLinksInFolder(folder.id);
      if (!folder.links) {
        folder.links = [];
      }
      folder.links.splice(0, folder.links.length);
      folder.links.push(...links);
      this.sortActiveFolder(this.activeLinkFilter, folder.type);
    } else if (folder.type === "gallery") {
      this.activeGalleryFolder = folder;
      folder.loading = true;
      const galleries = await this.dataService.getGalleriesInFolder(folder.id);
      if (!folder.galleries) {
        folder.galleries = [];
      }
      // if folder galleries is not empty, compare all galleries and add the ones that are not in the list
      if (folder.galleries.length > 0) {
        galleries.forEach((gallery) => {
          const existingGallery = folder.galleries.find((g) => g.id === gallery.id);
          if (existingGallery) {
            // compare the gallery with existingGallery
            const areEqual = JSON.stringify(existingGallery) === JSON.stringify(gallery);
            if (!areEqual) {
              // replace the existing gallery with the new one
              const pos = folder.galleries.findIndex((g) => g.id === gallery.id);
              folder.galleries.splice(pos, 1, gallery);
            }
          }
        });

      } else {
        // folder.galleries.splice(0, folder.galleries.length);
        folder.galleries.push(...galleries);
      }
      this.sortActiveFolder(this.activeGalleryFilter, folder.type);
      folder.loading = false;
    }
  }

  public async updateFolder(folder: Folder & IUIFolder): Promise<boolean> {
    this.logService.logEvent("FolderService - updateFolder");

    const result = await this.dataService.updateFolder(folder);
    if (result) {
      const folders = folder.type === "link" ? this.linkFolders : this.galleryFolders;

      let existingFolder = folders.find((folder) => folder.id === result.id); //  .findIndex((folder) => folder.id === result.id);
      // folders.splice(folderIndex, 1, result);
      existingFolder = result;
      return true;
    } else if (!result) {
      return false;
    }
  }

  public async createFolder(name: string, type: FolderType): Promise<boolean> {
    this.logService.logEvent("FolderService - createFolder");
    const folder = await this.dataService.createFolder(name) as Folder & IUIFolder;
    if (!folder) {
      return false;
    }

    folder.links = [];
    folder.galleries = [];
    folder.type = type;
    if (this.linkFolders) {
      this.linkFolders.push(folder);
      this.sortFoldersByName(this.linkFolders);
    }

    if (this.galleryFolders) {
      this.galleryFolders.push(folder);
      this.sortFoldersByName(this.galleryFolders);
    }

    return true;
  }

  public sortActiveFolder(filter: string, type: FolderType): void {
    if (type === "link") {
      this.activeLinkFilter = filter;
      this.sort(this.activeLinkFolder?.links, filter);
    }

    if (type === "gallery") {
      this.activeGalleryFilter = filter;
      this.sort(this.activeGalleryFolder?.galleries, filter);
    }
  }

  public sortActiveFolderWithActiveFilter(): void {
    this.sort(this.activeLinkFolder?.links, this.activeLinkFilter);
    this.sort(this.activeGalleryFolder?.galleries, this.activeGalleryFilter);
  }

  public sortFolderWithActiveFilter(folder: Folder): void {
    this.sort(folder?.links, this.activeLinkFilter);
    this.sort(folder?.galleries, this.activeGalleryFilter);
  }

  public async deleteFolder(folder: Folder, type: FolderType): Promise<boolean> {
    this.logService.logEvent("FolderService - deleteFolder");

    const deleteSuccess = await this.dataService.deleteFolder(folder);

    if (deleteSuccess) {
      const linkFolderIndex = this.linkFolders?.findIndex((f) => f.id === folder.id);
      if (linkFolderIndex > -1) this.linkFolders.splice(linkFolderIndex, 1);

      const galleryFolderIndex = this.galleryFolders?.findIndex((f) => f.id === folder.id);
      if (galleryFolderIndex > -1) this.galleryFolders.splice(galleryFolderIndex, 1);
    }
    return deleteSuccess;
  }

  public async moveGallery(orgFolderId: string, gallery: IGallery) {
    // remove it from the old folder
    const originFolder = await this.getFolder(orgFolderId, "gallery");
    const pos = originFolder.galleries.findIndex((g) => g.id === gallery.id);
    if (pos > -1) originFolder.galleries.splice(pos, 1);

    const destinationFolder = await this.getFolder(gallery.folderId, "gallery");
    if (destinationFolder.expanded) {
      destinationFolder.galleries.unshift(gallery);
    }
  }

  public moveLink(orgFolderId: string, link: Link): void {
    const pos = this.activeLinkFolder.links.findIndex((l) => l.id === link.id);
    if (pos > -1) this.activeLinkFolder.links.splice(pos, 1);
  }

  public async getSiblings(gallery: IGallery) {
    const folder = await this.getFolder(gallery.folderId, "gallery");

    // if folder has no galleries, get the galleries
    if (folder.galleries.length === 0) {
      folder.galleries = await this.dataService.getGalleriesInFolder(folder.id);
      this.sortFolderWithActiveFilter(folder);
    }

    const idx = folder.galleries.findIndex((g) => g.id === gallery.id);
    if (idx === -1) return [];

    if (idx === 0) {
      return [folder.galleries[folder.galleries.length - 1], folder.galleries[idx + 1]];
    }

    if (idx === folder.galleries.length - 1) {
      return [folder.galleries[idx - 1], folder.galleries[0]];
    }

    return [folder.galleries[idx - 1], folder.galleries[idx + 1]];
  }

  // -- private -------------------------------------

  private sort(items: Link[] | IGallery[], sortType: string): void {
    if (!items) return;
    this.logService.logEvent("Sort links", { order: sortType });
    if (sortType === "created") {
      items.sort((a: Link | IGallery, b: Link | IGallery) => b.createDate.localeCompare(a.createDate));
      return;
    }
    if (sortType === "createdDesc") {
      items.sort((a: Link | IGallery, b: Link | IGallery) => a.createDate.localeCompare(b.createDate));
      return;
    }

    if (sortType === "title") {
      items.sort((a: Link | IGallery, b: Link | IGallery) => a.title.localeCompare(b.title));
      return;
    }
    if (sortType === "titleDesc") {
      items.sort((a: Link | IGallery, b: Link | IGallery) => b.title.localeCompare(a.title));
      return;
    }

    if (this.instanceHasStatus(items)) {
      if (sortType === "status") {
        items.sort((a: Link, b: Link) => a.status - b.status);
        return;
      }
      if (sortType === "statusDesc") {
        items.sort((a: Link, b: Link) => b.status - a.status);
        return;
      }
    }

    console.log("invalid sortType");
  }

  /**
   * Sort Folders by name
   *
   * @private
   * @param {Folder[]} folders
   * @memberof FolderService
   */
  private sortFoldersByName(folders: Folder[]): void {
    folders.sort((a, b) => {
      if (a.id === this.DefaultFolderId) return -1;
      if (b.id === this.DefaultFolderId) return 1;
      return a.name.localeCompare(b.name);
    });
  }

  /**
   * Discern if the types in the array has the status parameter (IList has it, IGallery doesn't)
   *
   * @private
   * @param {(Array<Link> | Array<IGallery>)} arr
   * @returns {arr is Array<Link>}
   * @memberof FolderService
   */
  private instanceHasStatus(arr: Array<Link> | Array<IGallery>): arr is Array<Link> {
    const first = arr[0];
    const second = arr[1];
    return Object.keys(first).includes("status") && Object.keys(second).includes("status");
  }
}

export const IFolderService = DI.createInterface<FolderService>("IFolderService", (x) => x.singleton(FolderService));
